import React from 'react'
import {Card, Col, Row} from 'antd'
import {useSelector} from 'react-redux'
import {selectTheme} from '../../store/theme/selectors'
import {LoginForm} from '../../components/login-form'
import Loading from '../../components/loading/loading'
import {useThemeSwitcher} from 'react-css-theme-switcher'
import {Route, Switch, useRouteMatch} from 'react-router-dom'

// function useQuery() {
//   const { search } = useLocation()
//   return React.useMemo(() => new URLSearchParams(search), [search])
// }

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export enum AuthMode {//TODO перенести
	Login,
	Registration
}

export const AuthView: React.FC = () => {
  //const [autMode, setAuthMode] = useState<AuthMode>(AuthMode.Registration)
  const theme = useSelector(selectTheme)
  const {status} = useThemeSwitcher()
  const match = useRouteMatch()

  // const checkAuthStatus = () =>{
  // 	auth.check().then(() => {
  // 		const redirect = query.get('redirect') || '/'
  // 		history.push(redirect)
  // 	})
  // }

  // useEffect(()=>{
  // 	checkAuthStatus()
  // }, [])

  const toggleAuthMode = () => {
    //setAuthMode(authMode)
  }

  if (status === 'loading') return <Loading cover='page' />

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <div className='text-center'>
                  <img className='img-fluid img-logo' src={`/img/${theme === 'light' ? 'logo-white.svg' : 'logo-white.svg'}`} alt='' />
                  <Switch>
                    {/*<Route path={`${match.path}/login`}>*/}
                    {/*	<p>Don't have an account yet? <a href="/auth/register">Sign Up</a></p>*/}
                    {/*</Route>*/}
                    <Route path={`${match.path}/register`}>
                      <p>Have account? <a href='/auth/login'>Sign In</a></p>
                    </Route>
                  </Switch>
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Switch>
                      <Route path={`${match.path}/login`}>
                        <LoginForm toggleAuthMode={toggleAuthMode}/>
                      </Route>
                      {/*<Route path={`${match.path}/register`}>*/}
                      {/*	<RegisterForm toggleAuthMode={toggleAuthMode}/>*/}
                      {/*</Route>*/}
                    </Switch>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
