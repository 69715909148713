import {AutocompleteItem} from '@mantine/core'
import {SelectItem} from '@mantine/core/lib/components/Select/types'

export const CREATE_ORDER_POPUP_PRECISION = 2

export function mapToSelectItem<ID extends string>(data: TitleWithId<ID>): SelectItem {
  return {value: data.id, label: data.title}
}

export function mapToAutocompleteItem<ID extends string>(data: TitleWithId<ID>): AutocompleteItem & {id: ID} {
  return {id: data.id, value: data.title}
}

export function getCommonFieldClassName() {
  return 'w-full'
}