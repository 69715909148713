import {Checkbox} from '@mantine/core'
import {Body, Cell, Row, TableNode} from '@table-library/react-table-library'
import {Sort as SortData} from '@table-library/react-table-library/types/sort'
import React from 'react'
import {DataTableColumnInfo, DataTableFilterDropdowns, DataTableSelectData} from './data-table'
import {DefaultTableHeader} from './default-header-row'

interface DataTableContentProps<ColumnID extends string, ITEM extends TableNode> {
  rows: Array<ITEM>,
  columns: Array<DataTableColumnInfo<ColumnID, ITEM>>
  select?: DataTableSelectData,
  sort?: SortData,
  filterDropdowns?: DataTableFilterDropdowns<ColumnID>,
  getShortcutActions?: (item: ITEM) => JSX.Element,
}

export function DataTableContent<ColumnID extends string, ITEM extends TableNode>({
  rows,
  select,
  columns,
  sort,
  filterDropdowns,
  getShortcutActions,
}: DataTableContentProps<ColumnID, ITEM>) {
  return (
    <>
      <DefaultTableHeader columnsInfo={columns} filterDropdowns={filterDropdowns} select={select} sort={sort} getShortcutActions={getShortcutActions} />
      <Body>
        {rows.map(row => (
          <Row key={row.id} item={row} className='group font-normal bg-blue-500'>
            {select && (
              <Cell pinLeft={select.pinLeft} className='selectCell'>
                <Checkbox
                  checked={select.state.ids.includes(row.id)}
                  onChange={() => select.fns.onToggleById(row.id)}
                />
              </Cell>
            )}
            {<MemoizedRowsContent columns={columns} row={row} />}
            {!!getShortcutActions && <Cell pinRight className='rowActions'>{getShortcutActions(row)}</Cell>}
          </Row>
        ))}
      </Body>
    </>
  )
}


interface RowsContentProps<ColumnID extends string, ITEM extends TableNode> {
  columns: Array<DataTableColumnInfo<ColumnID, ITEM>>,
  row: ITEM,
}

//TODO:WMLN-ka-table проверить замемоизирована ли каждая Cell?
function RowsContent<ColumnID extends string, ITEM extends TableNode>({
  columns,
  row,
}: RowsContentProps<ColumnID, ITEM>) {
  return (
    <>
      {columns.map(column => (
        <Cell key={column.id} className={column.id}>{column.content(row)}</Cell>
      ))}
    </>
  )
}

const MemoizedRowsContent = React.memo(RowsContent) as typeof RowsContent