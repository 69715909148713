import React, {useEffect} from 'react'
import {useAppDispatch} from '../../../../../hooks/storeHooks'
import {
  resetCreateOrderPopupData,
  updateCreateOrderPopupItemField,
  updateCreateOrderPopupOrderFields,
} from '../../../../../store/procurement/create-order-popup/create-order-popup-reducer'
import {resetCreateOrderPopupErrors} from '../../../../../store/procurement/create-order-popup/errors-reducer'
import {CreateProcurementOrderPopupContext} from './context'
import {CreateOrderPopupItemsSection} from './items-section/create-order-popup-items-section'
import {OrderFieldsSection} from './order-fields-section/order-fields-section'
import {SummarySection} from './items-section/summary-section'

export const CreateProcurementOrderPopupContent = React.memo(() => {
  const dispatch = useAppDispatch()

  useEffect(() => () => {
    dispatch(resetCreateOrderPopupData())
    dispatch(resetCreateOrderPopupErrors())
  }, [dispatch])

  return (
    <CreateProcurementOrderPopupContext.Provider value={{
      updateOrderFiled: (field, value) => dispatch(updateCreateOrderPopupOrderFields({ field, value })),
      updateItemField: (id, field, value) => dispatch(updateCreateOrderPopupItemField({ itemId: id, field, value })),
    }}>
      <div className='flex flex-column font-medium overflow-y-auto max-h-[585px]'>
        <OrderFieldsSection />
        <CreateOrderPopupItemsSection />
        <SummarySection />
      </div>
    </CreateProcurementOrderPopupContext.Provider>
  )
})