import {SearchIcon} from '@heroicons/react/outline'
import {TextInput, useMantineTheme} from '@mantine/core'
import {Button, Modal} from 'antd'
import {CopyOutlined, SplitCellsOutlined} from '@ant-design/icons'
import React, {memo, useState} from 'react'
import {useSelector} from 'react-redux'
import {TransparentActionIcon} from '../../../../components/transparent-action-icon'
import {useAppDispatch} from '../../../../hooks/storeHooks'
import {setProcurementTableSearchValue} from '../../../../store/procurement/reducer'
import {selectProcurementTableState} from '../../../../store/procurement/selectors'
import {mode} from '../../../../utils/theme'
import {SplitProcurementTableItemPopup} from '../popups/split-item-popup'
import {ProcurementTableGlobalFiltersSection} from './global-filters-section'
import {VisibleColumnsButton} from './visible-columns-button'

export const ProcurementActionsSection = memo(() => {
  const selectedRowIds = useSelector(selectProcurementTableState('selectedRowKeys'))
  const [searchValue, setSearchValue] = useState('')
  const showActions = selectedRowIds.length > 0

  return (
    <div className='flex mb-3 items-end'>
      <TextInput
        value={searchValue}
        onChange={event => setSearchValue(event.currentTarget.value)}
        rightSection={<SearchButton value={searchValue} />}
        className='mr-auto min-w-[300px]'
        placeholder='Поиск айтемов...'
      />
      <ProcurementTableGlobalFiltersSection />
      <VisibleColumnsButton />
    </div>
  )
})

interface SearchButtonProps {
  value: string,
}

function SearchButton({
  value,
}: SearchButtonProps) {
  const dispatch = useAppDispatch()
  const {colors, colorScheme} = useMantineTheme()
  const activeValue = useSelector(selectProcurementTableState('searchValue'))
  const getColor = () => (activeValue
    ? mode(colors.blue[4], colors.blue[4])(colorScheme)
    : mode(colors.gray[6], colors.gray[6])(colorScheme))

  return (
    <TransparentActionIcon onClick={() => dispatch(setProcurementTableSearchValue(value))}>
      <SearchIcon color={getColor()} className='hover:opacity-70 w-4 h-4' />
    </TransparentActionIcon>
  )
}


interface ActionButtonsProps {
  isSingleSelect: boolean,
}

export const ActionButtons = React.memo(({ isSingleSelect }: ActionButtonsProps) => {
  const [showPopupInfo, setShowPopupInfo] = useState({
    duplicateItem: false,
    splitItem: false,
  })

  function openPopup(key: keyof typeof showPopupInfo) {
    setShowPopupInfo(prev => ({...prev, [key]: true}))
  }

  function closePopup(key: keyof typeof showPopupInfo) {
    setShowPopupInfo(prev => ({...prev, [key]: false}))
  }

  const className = 'mr-2'
  return (
    <>
      <Button type='default' shape='round' size='small' icon={<CopyOutlined />} className={className}
        onClick={() => openPopup('duplicateItem')}
      >
				Дублировать
      </Button>
      {isSingleSelect &&
        <Button type='default' shape='round' size='small' icon={<SplitCellsOutlined />} className={className}
          onClick={() => openPopup('splitItem')}
        >
          Разделить строку
        </Button>
      }
      <Modal
        modalRender={() => (
          <SplitProcurementTableItemPopup closeFn={() => closePopup('splitItem')} />
        )}
        visible={showPopupInfo.splitItem}
        centered={true}
        destroyOnClose={true}
      />
    </>
  )
})