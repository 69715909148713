import {all} from 'redux-saga/effects'
import {getProcurementSagas} from './procurement/sagas'
import {generateSaga} from './generate-table/sagas'

export default function *rootSaga(): Generator {
  yield all([
    //searchSaga(),
    generateSaga(),
    ...getProcurementSagas(),
  ])
}
