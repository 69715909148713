import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'

const selectGenerate = (state: RootState) => state.generate

export const selectPdfLink = createSelector(selectGenerate, state => state.pdfLink)
export const selectDocLink = createSelector(selectGenerate, state => state.docLink)
export const selectOrderValue = createSelector(selectGenerate, state => state.orderValue)
export const selectDisabledOrderForm = createSelector(selectGenerate, state => state.disabledOrderForm)
export const selectLinkToTable = createSelector(selectGenerate, state => state.linkToTable)
