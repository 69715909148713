import {isNullable} from './check'
import {Logger} from './Logger'
import {getTime} from 'date-fns'

export function isNumber(value: string|number|undefined|null): value is number|string {
  if (value === undefined || value === null) {
    return false
  }
  if (typeof value === 'number') {
    return true
  }
  // @ts-ignore
  return !isNaN(parseFloat(value)) && !isNaN(value - 0)
}


export function formatMoney(money: number|string): string|false {
  if (!isNumber(money)) {
    Logger.error(`It's not number. Value: ${money}`)
    return false
  }

  const num = typeof money === 'number' ? money : Number(money)
  const str = num.toFixed(2).toString()
  const [firstPart, secondPart] = str.split('.')
  if (firstPart.length <= 3) {
    return `${firstPart},${secondPart}`
  }

  const remains = firstPart.length % 3
  let formattedFirstPart = firstPart.substring(0, remains)
  for (let i = remains; i < firstPart.length; i++) {
    formattedFirstPart += (i - remains) % 3 === 0 ? ` ${firstPart[i]}` : firstPart[i]
  }
  return `${formattedFirstPart},${secondPart}`
}

export function numberInputParser(x: string|undefined): string {
  return x ? x.replace(/\s*/g, '') : ''
}

export function numberInputFormatter(x: string|undefined): string {
  return x && !Number.isNaN(parseFloat(x)) ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

export function nullableSum(...x: Array<number|null>): number|null {
  let allIsNull = true
  const sum = x.reduce((acc: number, num) => {
    if (!isNullable(num)) {
      allIsNull = false
    }
    return acc + (num || 0)
  }, 0)
  return allIsNull ? null : sum
}

export function presiceNumber(value: number, precision: number): number {
  return Number(value.toFixed(precision))
}

/** @description Input: '2+2,5+3.5' Output: 8 */
export function calcSumExpression(formula: string): number {
  return formula.replaceAll(/,/g, '.').split('+').reduce((acc, item) => acc + Number(item), 0)
}