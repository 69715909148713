export class Logger {
  static error(message?: string) {
    console.error(message || 'Logger catch error')
  }

  static assert(condition: unknown, message = 'Assertion failed') {
    !condition && Logger.error(message)
  }
}

export function verify<T>(condition: (T|null|undefined), message?: string): T {
  Logger.assert(condition, message)
  return condition as T
}

export function verifyObject<K extends PropertyKey, V>(obj: Record<K, Nullable<V>>, message: string): Record<K, V> {
  const getMessage = (key: K, value: V) => `${message}. "${key}: ${value}"`
  Object.entries(obj).forEach(([key, value]) => {
    verify(value, getMessage(key as K, value as V))
  })
  return obj as Record<K, V>
}