import {format} from 'date-fns'
import {isEmpty, keys} from 'ramda'
import {SagaIterator} from 'redux-saga'
import {call, put, select, takeEvery} from 'redux-saga/effects'
import {ARType, getApi} from '../../api/getApi'
import {CreateOrderRequestItemData, CreateOrderRequestOrderFields} from '../../api/procurement/procurement'
import {isNonNullable} from '../../utils/check'
import {defaultError} from '../../utils/errors'
import {verify} from '../../utils/Logger'
import {cleanObject, filterObject} from '../../utils/object'
import {
  CreateOrderPopupState,
  loadCreateOrderPopupData,
  saveCreateOrderPopupData,
  setCreateOrderPopupItems,
  setCreateOrderPopupLoadingState,
  setCreateOrderPopupStaticData,
} from './create-order-popup/create-order-popup-reducer'
import {
  CreateOrderPopupErrorsState,
  CreateOrderPopupRequiredItemField,
  CreateOrderPopupValidationOrderField,
  getCreateOrderPopupRequiredItemFields,
  getCreateOrderPopupValidationOrderFields,
  resetCreateOrderPopupErrors, setCreateOrderPopupErrors,
} from './create-order-popup/errors-reducer'
import {remapProcurementItemToCreateOrderPopupItem} from './create-order-popup/remap-create-order-popup-data'
import {
  CreateOrderPopupItem,
  CreateOrderPopupOrderFields,
  MutableCreateOrderPopupData,
} from './create-order-popup/types'
import {
  ProcurementItem,
  loadProcurementTableData,
  setProcurementTableData,
  setProcurementTableLoading,
  setProcurementTableStaticData,
} from './reducer'
import {
  selectCreateOrderPopupData, selectCreateOrderPopupErrorsState,
  selectProcurementTableState,
} from './selectors'

function *getProcurementDataSaga(): SagaIterator {
  yield takeEvery(loadProcurementTableData, getProcurementDataWorker)
}

function *getProcurementDataWorker(): SagaIterator {
  try {
    const getFn = () => Promise.all([
      getApi().procurement.getTableData(),
      getApi().procurement.getCountries(),
      getApi().procurement.getStores(),
      getApi().procurement.getResponsibilities('procurement'),
    ])
    yield put(setProcurementTableLoading(true))
    const [tableData, countries, stores, procurements]: ARType<typeof getFn> = yield call(getFn)
    yield put(setProcurementTableData(tableData))
    yield put(setProcurementTableStaticData({
      countries,
      stores,
      procurements,
    }))
    yield put(setProcurementTableLoading(false))
  }
  catch (e) {
    defaultError({
      errorObj: e,
      consoleMessage: 'Не удалось получить данные для ProcurementTable',
    })
  }
}


function *getCreateOrderPopupDataSaga(): SagaIterator {
  yield takeEvery(loadCreateOrderPopupData, getCreateOrderPopupDataWorker)
}

function *getCreateOrderPopupDataWorker(): SagaIterator {
  try {
    yield put(setCreateOrderPopupLoadingState('loading'))
    const selectedKeysSet: Set<string> = new Set(yield select(selectProcurementTableState('selectedRowKeys')))
    const tableData: Array<ProcurementItem> = yield select(selectProcurementTableState('data'))
    const selectedRows = tableData.filter(x => selectedKeysSet.has(x.id))

    const api = getApi().procurement
    const getFn = () => Promise.all([
      api.getStores(),
      api.getCountries(),
      api.getProviders(),
      api.getCurrencies(),
      api.getPaymentMethods(),
      api.getBrands(),
      api.getResponsibilities('scd'),
      api.getResponsibilities('procurement'),
      api.getOrderStatuses(),
    ])
    const [stores, countries, providers, currencies, paymentMethods, brands,
      responsibleSCDs, responsibleProcurements, orderStatuses]: ARType<typeof getFn> = yield call(getFn)

    const brandIdsSet = new Set(brands.map(x => x.id))
    yield put(setCreateOrderPopupItems(selectedRows.map(x => remapProcurementItemToCreateOrderPopupItem(x, brandIdsSet))))

    yield put(setCreateOrderPopupStaticData({
      stores,
      countries,
      providers,
      currencies,
      paymentMethods,
      brands,
      responsibleSCDs,
      responsibleProcurements,
      orderStatuses,
    }))
    yield put(setCreateOrderPopupLoadingState('normal'))
  }
  catch (e) {
    defaultError({
      errorObj: e,
      consoleMessage: 'Не удалось получить данные для ProcurementTable',
    })
  }
}


function *saveCreateOrderPopupDataSaga(): SagaIterator {
  yield takeEvery(saveCreateOrderPopupData, saveCreateOrderPopupDataWorker)
}

function *saveCreateOrderPopupDataWorker(): SagaIterator {
  try {
    yield put(setCreateOrderPopupLoadingState('saving'))
    const {mutableData}: CreateOrderPopupState = yield select(selectCreateOrderPopupData)
    const validationEnabled: boolean = yield select(selectCreateOrderPopupErrorsState('validationEnabled'))
    const errors = validationEnabled ? validateOrderPopupData(mutableData) : null
    if (errors !== null) {
      yield put(setCreateOrderPopupErrors(errors))
      yield put(setCreateOrderPopupLoadingState('normal'))
      return
    }

    yield put(resetCreateOrderPopupErrors())
    const {orderFields, items} = mutableData
    yield call(() => getApi().procurement.createOrder({
      ...verifiedMapOrderFields(orderFields, validationEnabled),
      items: items.map(verifiedMapItemFields),
    }))
    yield put(setCreateOrderPopupLoadingState('normal'))
  }
  catch (e) {
    defaultError({
      errorObj: e,
      consoleMessage: 'Не удалось создать заказ',
    })
    yield put(setCreateOrderPopupLoadingState('normal'))
  }
}

function verifiedMapOrderFields(orderFields: CreateOrderPopupOrderFields, validationEnabled: boolean): CreateOrderRequestOrderFields {
  if (validationEnabled) {
    const getMessage = (field: keyof CreateOrderPopupOrderFields) => `CreateOrderPopup order fields verification failed. Field: ${field}`
    const obj: CreateOrderRequestOrderFields = {
      store: verify(orderFields.storeId, getMessage('storeId')),
      ordering_date: format(verify(orderFields.orderTimestamp, getMessage('orderTimestamp')), 'yyyy-MM-dd'),
      status_order: orderFields.orderStatusId,
      country: verify(orderFields.countryId, getMessage('countryId')),
      currency: verify(orderFields.currencyId, getMessage('currencyId')),
      provider: verify(orderFields.providerId, getMessage('providerId')),
      payment_type: verify(orderFields.paymentMethodId, getMessage('paymentMethodId')),
      responsible_scd: verify(orderFields.responsibleSCDId, getMessage('responsibleSCDId')),
      order_invoice: orderFields.orderInvoice || undefined,
      po_number: orderFields.numberPO || undefined,
      tracking_number: orderFields.tracking || undefined,
      store_contacts: orderFields.managerContact || undefined,
      sum_other_cost_formula: orderFields.summaryOtherCost || undefined,
      sum_tax: orderFields.summaryTax === null ? undefined : orderFields.summaryTax,
    }
    return cleanObject(obj)
  }

  const obj: NullableObj<CreateOrderRequestOrderFields> = {
    store: orderFields.storeId,
    ordering_date: orderFields.orderTimestamp === null ? null : format(orderFields.orderTimestamp, 'yyyy-MM-dd'),
    status_order: orderFields.orderStatusId,
    country: orderFields.countryId,
    provider: orderFields.providerId,
    payment_type: orderFields.paymentMethodId,
    responsible_scd: orderFields.responsibleSCDId,
    order_invoice: orderFields.orderInvoice,
    po_number: orderFields.numberPO || undefined,
    tracking_number: orderFields.tracking || undefined,
    store_contacts: orderFields.managerContact || undefined,
    sum_other_cost_formula: orderFields.summaryOtherCost || undefined,
    sum_tax: orderFields.summaryTax === null ? null : orderFields.summaryTax,
  }
  return cleanObject(obj)
}

function verifiedMapItemFields(item: CreateOrderPopupItem): CreateOrderRequestItemData {
  const getMessage = (field: keyof CreateOrderPopupItem) => `CreateOrderPopup item fields verification failed. Field: ${field}`
  return {
    id: verify(item.id, getMessage('id')),
    brand: verify(item.brand, getMessage('brand')),
    catalogue_id: verify(item.catalogue, getMessage('catalogue')),
    original_name: verify(item.originalName, getMessage('originalName')),
    cost: verify(item.cost, getMessage('cost')),
    tax: item.tax?.value,
    quantity: verify(item.quantity, getMessage('quantity')),
    other_cost: item.otherCost.value === null ? undefined : item.otherCost.value,
    shipment_deadline: format(
      verify(item.shipmentTimestamp, getMessage('shipmentTimestamp')),
      'yyyy-MM-dd',
    ),
  }
}

function validateOrderPopupData({orderFields, items}: MutableCreateOrderPopupData): Omit<CreateOrderPopupErrorsState, 'validationEnabled'>|null {
  const validationOrderFields: Set<keyof CreateOrderPopupOrderFields> = new Set(getCreateOrderPopupValidationOrderFields())
  const validationItemFields: Set<keyof CreateOrderPopupItem> = new Set(getCreateOrderPopupRequiredItemFields())
  const invalidFields = filterObject(orderFields, (v, k) => validationOrderFields.has(k) && !isNonNullable(v))

  const itemsErrors: TypedObject<string, Array<CreateOrderPopupRequiredItemField>> = {}
  items.forEach(item => {
    const obj = filterObject(item, (v, k) => validationItemFields.has(k) && !isNonNullable(v))
    if (!isEmpty(obj)) {
      itemsErrors[item.id] = keys(obj) as Array<CreateOrderPopupRequiredItemField>
    }
  })
  if (isEmpty(invalidFields) && isEmpty(itemsErrors)) {
    return null
  }

  return {
    orderFields: keys(invalidFields) as Array<CreateOrderPopupValidationOrderField>,
    items: itemsErrors,
  }
}

export function getProcurementSagas() {
  return [
    getProcurementDataSaga(),
    getCreateOrderPopupDataSaga(),
    saveCreateOrderPopupDataSaga(),
  ]
}