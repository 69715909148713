import React, {useState} from 'react'
import {SettingOutlined} from '@ant-design/icons'
import {Layout, Menu} from 'antd'
import {isProdHostname} from '../../api/apiCreator'
import {ThemeConfigurator} from '../configurator'
import {Drawer} from '@mantine/core'

export function DefaultAppHeader() {
  const [opened, setOpened] = useState<boolean>(false)
  return (
    <Layout.Header className='default-app-header'>
      {!isProdHostname() && <span className='dev-text'>DEV</span>}
      <Menu mode='horizontal'>
        <Menu.Item key='panel' onClick={() => setOpened(true)}>
          <span><SettingOutlined className='nav-icon mr-0' /></span>
        </Menu.Item>
      </Menu>
      <Drawer
        title='Configuration'
        onClose={() => setOpened(false)}
        opened={opened}
        position='right'
        padding='xl'
      >
        <ThemeConfigurator/>
      </Drawer>
    </Layout.Header>
  )
}
