import {AnyType} from '../../../types'
import apiCreator from '../apiCreator'

export const auth = {
  login(userData: AnyType): Promise<AnyType> { //TODO
    return apiCreator.post('https://api.appscience.ru/auth/login', {...userData}, {headers: {'public-request': 'true'}}).then(res => res)
  },
  check(): Promise<AnyType> {
    return apiCreator.get('https://api.appscience.ru/auth/check').then(res => res)
  },
}
