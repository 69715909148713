import {CreateOrderPopupItem} from '../../../../../../store/procurement/create-order-popup/types'

export type CreateOrderPopupColumnId = Exclude<keyof CreateOrderPopupItem, 'id'>

export interface CreateOrderPopupColumnInfo {
  id: CreateOrderPopupColumnId,
  width: number,
  title: string,
}

export const GRID_TEMPLATE_COLUMNS = getCreateOrderPopupColumnsInfo()
  .reduce((className, x) => className + `${x.width}fr `, '')
  .concat('20px')

export function getCreateOrderPopupColumnsInfo(): Array<CreateOrderPopupColumnInfo> {
  //Width указана в fr, для пропорционального размещения.
  return [
    {
      id: 'brand',
      width: 14,
      title: 'brand',
    },
    {
      id: 'catalogue',
      width: 7,
      title: 'cat',
    },
    {
      id: 'originalName',
      width: 14,
      title: 'Название в invoice',
    },
    {
      id: 'cost',
      width: 8,
      title: 'Cost',
    },
    {
      id: 'tax',
      width: 8,
      title: 'Tax',
    },
    {
      id: 'otherCost',
      width: 9,
      title: 'Other cost',
    },
    {
      id: 'quantity',
      width: 6,
      title: 'Quantity',
    },
    {
      id: 'invoiceName',
      width: 14,
      title: 'Название в счете',
    },
    {
      id: 'shipmentTimestamp',
      width: 12,
      title: 'Примерная дата отгрузки',
    },
    {
      id: 'clientPrice',
      width: 10,
      title: 'Цена за штуку для клиента, ₽',
    },
  ]
}