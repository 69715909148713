import {ProcurementItem} from '../reducer'
import {CreateOrderPopupItem} from './types'

export function remapProcurementItemToCreateOrderPopupItem(data: ProcurementItem, brandIdsSet: Set<string>): CreateOrderPopupItem {
  return {
    id: data.id,
    brand: data.brand && brandIdsSet.has(data.brand) ? data.brand : null,
    catalogue: data.catalogueId || null,
    originalName: data.originalName || null,
    cost: null,
    tax: null,
    otherCost: {
      value: null,
      acceptForTax: false,
    },
    quantity: data.quantity,
    invoiceName: data.invoiceName,
    shipmentTimestamp: data.purchaseTimestamp,
    clientPrice: data.clientPrice,
    amoId: data.amoId,
  }
}