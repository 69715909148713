import React, {useState} from 'react'
import {ThemeSwitcherProvider} from 'react-css-theme-switcher'
import {THEME_CONFIG} from './config/AppConfig'
import {getButtonStylesConfig} from './config/theme/components/button'
import {getCheckboxStylesConfig} from './config/theme/components/checkbox'
import {getMultiSelectStylesConfig} from './config/theme/components/multi-select'
import {getSelectStylesConfig} from './config/theme/components/select'
import {DefaultGlobalStyles} from './config/theme/foundations/default-global-styles'
import {Views} from './views'
import {ColorScheme, ColorSchemeProvider, MantineProvider} from '@mantine/core'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light')
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <div className='App'>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint='styles-insertion-point'>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            theme={{
              fontFamily: 'Inter, Roboto, sans-serif',
              colorScheme,
              black: '93c5fd',
              primaryColor: 'blue',
              colors: {
                dark: ['#f1f5f9', '#e2e8f0', '#cbd5e1', '#94a3b8', '#64748b', '#475569', '#334155', '#1e293b', '#0f172a', '#0c0d21'],
              }}}
            styles={{
              Button: getButtonStylesConfig,
              Select: getSelectStylesConfig,
              Checkbox: getCheckboxStylesConfig,
              MultiSelect: getMultiSelectStylesConfig,
            }}
          >
            <DefaultGlobalStyles />
            <Views />
          </MantineProvider>
        </ColorSchemeProvider>
      </ThemeSwitcherProvider>
    </div>
  )
}

export default App
