import {Tooltip} from '@mantine/core'
import React, {useState} from 'react'
import {MultilineEllipsis} from '../react-snip/components/MultilineEllipsis'

const ELLIPSIS_CHAR = '...'

interface LinesEllipsisWithTooltipProps {
  text: string,
  maxLines?: number,
}

export function LinesEllipsisWithTooltip({
  text,
  maxLines = 1,
}: LinesEllipsisWithTooltipProps) {
  const [disabledTip, setDisabledTip] = useState(true)

  return (
    <Tooltip
      label={text}
      withArrow
      disabled={disabledTip}
      wrapLines
      allowPointerEvents
    >
      <MultilineEllipsis lines={maxLines + 1} method='js' ellipsis={ELLIPSIS_CHAR} callbacks={{onJsClamp: clamped => setDisabledTip(!clamped)}}>
        {text}
      </MultilineEllipsis>
    </Tooltip>
  )
}