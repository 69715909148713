import React, {
  FormEvent,
  useEffect,
  useState,
} from 'react'
import {getApi} from '../../api/getApi'
import {HandleInputProductType} from '../../store/search/reducer'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'

//Select option
const {Option} = Select
type HandleInputFormType = {

	//methods
	onFinishHandler?:(object: HandleInputProductType)=> void
	onChangeHandler?:(name: string, value: string) => void

	//props
	initialValues: HandleInputProductType //TODO
	showFinishBtn?: boolean
}

export const HandleInputForm: React.FC<HandleInputFormType> = props => {
  const {
    onFinishHandler,
    onChangeHandler,
    showFinishBtn,
    initialValues,
  } = props

  const {
    name,
    catalogue_id,
    cas_id,
    price,
    shipping_conditions,
    package_size,
    vendor,
    partner_url,
    count,
    info_from_client,
  } = initialValues

  const [form] = Form.useForm()
  const [price_currency, setCurrencyPrice] = useState<string>('USD')
  const [vendorsList, setVendorsList] = useState<string[]>([])

  useEffect(() => {
    let isMounted = true
    getApi().vendors.get().then(({data}) => {
      const {vendors} = data
      if (isMounted) {
        setVendorsList(vendors)
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  //On Finish Handler
  const onFinish = (object: HandleInputProductType) => {
    onFinishHandler && onFinishHandler({...object, input_by_handle: true})
    form.resetFields()
  }

  //On Change Handler
  const onChange = (e: FormEvent<HTMLFormElement>) => {
    const el = e.nativeEvent.target as HTMLInputElement
    onChangeHandler && onChangeHandler(el.id, el.value)
  }

  // On Select Handler
  const onSelect = (_name: string, value: string) => {
    onChangeHandler && onChangeHandler(_name, value)
  }

  //Price After
  const priceAfter = (
    <Select
      defaultValue={price_currency}
      style={{width: 70}}
      onSelect={(value: string) => onSelect('price_currency', value)}
      onChange={value => setCurrencyPrice(value)}
    >
      <Option value='USD'>USD</Option>
      <Option value='EUR'>EUR</Option>
      <Option value='GBP'>GBP</Option>
      <Option value='CHF'>CHF</Option>
      <Option value='RUB'>RUB</Option>
    </Select>
  )

  return (
    <Card bordered={true}>
      <div className='handle-input-basket-section'>
        <Form
          form={form}
          onChange={onChange}
          onFinish={onFinish}
          initialValues={{
            name,
            catalogue_id,
            cas_id,
            price,
            price_currency,
            shipping_conditions,
            package_size,
            vendor,
            partner_url,
            count,
            info_from_client,
          }}>

          {/*Название Позиции*/}
          <Row>
            <Col span={24} className='handle-input-basket-input'>
              <Form.Item label='Название позиции:' name='name' rules={[{
                message: 'Введите название',
              }]}>
                <Input placeholder='Введите название' />
              </Form.Item>
            </Col>
          </Row>

          {/*form item line*/}
          <div className='form-item-line'>
            <Row gutter={20}>
              {/*Catalogue Id*/}
              <Col span={24} md={8} lg={8} className='handle-input-basket-input handle-input-basket-input-desktop'>
                <Form.Item label='Catalogue id' name='catalogue_id' rules={[{
                  message: 'Введите id',
                }]}>
                  <Input placeholder='Введите id'/>
                </Form.Item>
              </Col>

              {/*Cas*/}
              <Col span={24} md={8} lg={8} className='handle-input-basket-input'>
                <Form.Item label='CAS' name='cas_id' rules={[{
                  message: 'Введите cas',
                }]}>
                  <Input placeholder='Введите cas'/>
                </Form.Item>
              </Col>

              {/*Цена*/}
              <Col span={24} md={8} lg={8} className='handle-input-basket-input'>
                <Form.Item label='Цена' name='price' rules={[{
                  message: 'Введите цену',
                }]}>
                  <Input placeholder='Введите цену' addonAfter={priceAfter} type='number'/>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/*form item line*/}
          <div className='form-item-line'>
            <Row gutter={20}>

              {/*Темп. режим*/}
              <Col span={24} md={12} className='handle-input-basket-input handle-input-basket-input-desktop'>
                <Form.Item label='Темп. режим' name='shipping_conditions' rules={[{
                  message: 'Введите температуру',
                }]}>
                  <Select onSelect={(value: string) => onSelect('shipping_conditions', value)}>
                    <Option key={1} value={'ROOM'}>ROOM</Option>
                    <Option key={2} value={'DRYICE'}>DRYICE</Option>
                    <Option key={3} value={'REFR'}>REFR</Option>
                    <Option key={4} value={'FREEZER'}>FREEZER</Option>
                  </Select>
                </Form.Item>
              </Col>

              {/**/}
              <Col span={24} md={12} className='handle-input-basket-input handle-input-basket-input-desktop'>
                <Form.Item label='Фасовка:' name='package_size' rules={[{
                  message: 'Введите фасовку',
                }]}>
                  <Input placeholder='Введите фасовку'/>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/*form item line*/}
          <div className='form-item-line'>
            <Row gutter={20}>

              {/**/}
              <Col span={24} md={12} className='handle-input-basket-input handle-input-basket-input-desktop'>
                <Form.Item label='Вендор:' name='vendor' rules={[{
                  message: 'Введите вендор',
                }]}>
                  <Select showSearch onSelect={(value: string) => onSelect('vendor', value)}>
                    {vendorsList.map((item, key) => <Option key={key} value={item}>{item}</Option>)}
                  </Select>
                </Form.Item>
              </Col>

              {/**/}
              <Col span={24} md={12} className='handle-input-basket-input handle-input-basket-input-desktop'>
                <Form.Item label='Ссылка:' name='partner_url' rules={[{
                  message: 'Введите ссылку',
                }]}>
                  <Input placeholder='Введите ссылку'/>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/*form item line*/}
          <div className='form-item-line'>
            <Row>
              <Col span={24} className='handle-input-basket-input'>
                <Form.Item label='Информация от клиента:' name='info_from_client'>
                  <Input.TextArea placeholder='Введите информацию'/>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/*form item line*/}
          <div className='form-item-line'>
            <Row gutter={30} >
              {/*Цена*/}
              <Col span={24} md={8} className='handle-input-basket-input'>
                <Form.Item label='Количество' name='count' rules={[{
                  message: 'Введите цены',
                }]}>
                  <Input type='number'/>
                </Form.Item>
              </Col>
              {showFinishBtn && <Col span={24} md={5} className='d-flex justify-content-end justify-content-md-start'>
                <Button type='primary' htmlType='submit'>Добавить позицию в корзину</Button>
              </Col>}
            </Row>
          </div>
        </Form>
      </div>
    </Card>
  )
}
