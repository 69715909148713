import React from 'react'
import {useCheckAuth} from '../../hooks/useCheckAuth'
import {ProcurementHeader} from './procurement-header'
import {ProcurementLeftNav} from './procurement-left-nav'
import {SIDE_NAV_COLLAPSED_WIDTH} from '../../constants/ThemeConstant'

export function withProcurementLayout(children: React.ReactNode) {
  return <ProcurementLayout>{children}</ProcurementLayout>
}

const ProcurementLayout: React.FC = props => {
  useCheckAuth()

  return (
    <div className='flex h-full'>
      <ProcurementLeftNav />
      <div style={{width: `calc(100vw - ${SIDE_NAV_COLLAPSED_WIDTH}px)`}}>
        <ProcurementHeader />
        {props.children}
      </div>
    </div>
  )
}