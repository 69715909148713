import {CSSObject, MantineTheme, SelectProps} from '@mantine/core'
import {CustomSizes} from '../foundations/sizes'

export function getSelectStylesConfig(theme: MantineTheme, props: Record<string, any>): Record<string, CSSObject> {
  const params = props as SelectProps
  return {
    input: {
      height: CustomSizes[params.size || 'sm'],
      minHeight: CustomSizes[params.size || 'sm'],
    }
  }
}