import {CSSObject, MantineTheme, Text, createStyles} from '@mantine/core'
import {UseFormReturnType} from '@mantine/form/lib/use-form'
import React from 'react'
import {checkNever} from '../../../../../../utils/check'
import {joinStrings} from '../../../../../../utils/string'
import {mode} from '../../../../../../utils/theme'
import {EditItemPopupField, EditItemPopupFormState} from '../index'

export interface CommonFieldProps {
  form: UseFormReturnType<EditItemPopupFormState>
}

export function getProcurementEditItemPopupInputSxStyles({
  colors,
  colorScheme,
}: MantineTheme): CSSObject {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& input, & textarea': {
      width: '334px',
    },
    '& input:disabled': {
      backgroundColor: mode(colors.gray[1], colors.dark[6])(colorScheme),
      color: mode(colors.dark[9], colors.gray[2])(colorScheme),
    },
  }
}


interface FormLabelProps {
  label: string,
  required?: boolean
}

const useStyles = createStyles(({ colors, colorScheme }) => ({
  asterisk: {
    lineHeight: 0,
    color: mode(colors.red[7], colors.red[5])(colorScheme),
  },
}))

export function ProcurementEditItemPopupFormLabel({ label, required }: FormLabelProps) {
  const { classes } = useStyles()
  return <Text className='font-medium text-[13px]' sx={({colors, colorScheme}) => ({
    color: mode(colors.gray[5], colors.dark[8])(colorScheme),
  })}>
    <Text component='span' className={joinStrings(
      classes.asterisk,
      `text-[14px] mr-1`,
      !required && 'invisible',
    )}>
      {' *'}
    </Text>
    {label}
  </Text>
}


export function getProcurementTableColumnTitle(filed: EditItemPopupField): string {
  switch (filed) {
    case 'brand':
      return 'Производитель'
    case 'country':
      return 'Предполагаемая страна'
    case 'invoiceTimestamp':
      return 'Дата счёта'
    case 'clientName':
      return 'Клиент'
    case 'amoId':
      return 'AMO'
    case 'invoiceName':
      return 'Наименование в счете'
    case 'quantity':
      return 'Количество'
    case 'catalogueId':
      return 'CAT'
    case 'purchaseTimestamp':
      return 'Дата закупки'
    case 'supposedStore':
      return 'Предполагаемый магазин'
    case 'deadlineTimestamp':
      return 'Deadline'
    case 'clientPrice':
      return 'Цена за штуку для клиента, ₽'
    case 'procurementComment':
      return 'Комментарий от Procurement'
    case 'responsibleProcurement':
      return 'Ответственный Procurement'
    default:
      checkNever(filed, `Unknown procurement table id. Value: ${filed}`)
      return ''
  }
}