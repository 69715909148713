import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'
import {InitialStateType, ProductBasketType} from './types'

const initialState: InitialStateType = {
  basketProducts: [],
  editedItem: undefined,
  openHandleEditor: false,
  addToCartBtnDisable: false,
}

const basketSlice = createSlice({
  name: 'search',
  initialState: {...initialState},
  reducers: {
    setInitialStateAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = action.payload
    },

    setProductsListAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = [...action.payload]
    },

    setEditedItemIndex(state, action: ActionType<number>) {
      state.editedItem = action.payload
    },

    setOpenHandleEditor(state, action: ActionType<boolean>) {
      state.openHandleEditor = action.payload
    },

    setAddToBtnDisable(state, action: ActionType<boolean>) {
      state.addToCartBtnDisable = action.payload
    },
  },
})

export const basketReducer = basketSlice.reducer
export const {
  setInitialStateAction,
  setProductsListAction,
  setEditedItemIndex,
  setOpenHandleEditor,
  setAddToBtnDisable,
} = basketSlice.actions
