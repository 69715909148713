import {Button, Checkbox} from '@mantine/core'
import React, {useState} from 'react'
import {joinStrings} from '../../utils/string'
import {mode} from '../../utils/theme'

export interface SelectListItem<ID extends string> {
  id: ID,
  label: string,
}

interface SaveButtonProps<ID extends string> {
  text?: string,
  onClick: (selectedIds: Array<ID>) => void,
}

interface OnChangeFnParams<ID extends string> {
  selectedIds: Array<ID>,
  id: ID,
  checked: boolean,
}

type CanToggleFnParams<ID extends string> = OnChangeFnParams<ID>

export interface SelectListPopoverProps<ID extends string> {
  selectedIds: Array<ID>,
  data: Array<SelectListItem<ID>>,
  onChange?: (v: OnChangeFnParams<ID>) => void,
  saveButton?: SaveButtonProps<ID>,
  canToggle?: (v: CanToggleFnParams<ID>) => boolean,
  listClassName?: string,
}

export function MultiSelectPopover<ID extends string>({
  selectedIds,
  data,
  onChange,
  saveButton,
  canToggle,
  listClassName,
}: SelectListPopoverProps<ID>) {
  const [selectedValues, setSelectedValues] = useState(selectedIds)
  const selectedSet = new Set(selectedValues)

  const onChangeFn = (event: React.ChangeEvent<HTMLInputElement>, id: ID) => {
    const newValues = event.currentTarget.checked
      ? [...selectedValues, id]
      : selectedValues.filter(x => x !== id)
    const fnParams = {
      id,
      checked: event.currentTarget.checked,
      selectedIds: newValues,
    }

    if (canToggle && !canToggle(fnParams)) {
      return
    }
    onChange && onChange(fnParams)
    setSelectedValues(newValues)
  }

  return (
    <div className='flex flex-col cursor-pointer shadow-inner'>
      <div className={joinStrings('overflow-y-auto', listClassName)}>
        {data.map(({id, label}) => (
          <Checkbox
            key={id}
            label={label}
            checked={selectedSet.has(id)}
            onChange={event => onChangeFn(event, id)}
            sx={({colors, colorScheme}) => ({
              '&:hover': {
                backgroundColor: mode(colors.blue[1], colors.blue[9])(colorScheme),
              },
            })}
            className='px-3 py-2 duration-200'
          />
        ))}
      </div>
      {!!saveButton && <Footer
        onSave={() => saveButton.onClick(selectedValues)}
        saveText={saveButton.text}
      />}
    </div>
  )
}

interface FooterProps {
  onSave: () => void,
  saveText?: string,
}

function Footer({
  onSave,
  saveText,
}: FooterProps) {
  return (
    <div className='flex py-1 px-2'>
      <Button onClick={onSave} className='ml-auto'>
        {saveText || 'Применить'}
      </Button>
    </div>
  )
}