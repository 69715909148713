import {PencilIcon} from '@heroicons/react/outline'
import {SxFn} from '../../../../commonTypes'
import {TransparentActionIcon} from '../../../../components/transparent-action-icon'
import {useAppDispatch} from '../../../../hooks/storeHooks'
import {ProcurementItem, setProcurementEditableItem} from '../../../../store/procurement/reducer'
import {mode} from '../../../../utils/theme'

interface ActionsCellProps {
  item: ProcurementItem,
}

export function ActionsCell({
  item,
}: ActionsCellProps) {
  const dispatch = useAppDispatch()
  return (
    <>
      <TransparentActionIcon sx={getSxStyles} onClick={() => dispatch(setProcurementEditableItem(item))}>
        <PencilIcon className='w-5 h-5' />
      </TransparentActionIcon>
    </>
  )
}

const getSxStyles: SxFn = ({colors, colorScheme}) => ({
  color: mode(colors.blue[5], colors.blue[8])(colorScheme),
  '&:hover': {
    color: mode(colors.blue[4], colors.blue[7])(colorScheme),
  },
})