import {AnyType} from '../../../types'
import apiCreator from '../apiCreator'

type GetProposalDataType = {
	id: string
}

export const proposal = {
  post(proposalhData: AnyType): Promise<AnyType> {
    return apiCreator.post('https://api.appscience.ru/proposals/proposal', {...proposalhData}).then(res => res)
  },

  getProposalData(proposalRequestData: GetProposalDataType): Promise<AnyType> {
    return apiCreator.get('https://api.appscience.ru/proposals/get', {params:{...proposalRequestData}}).then(res => res)
  },
}
