import {Paper} from '@mantine/core'
import {OrderFieldsSectionFirstLine} from './first-line'
import {OrderFieldsSectionSecondLine} from './second-line'
import {OrderFieldsSectionThirdLine} from './third-line'
import {mode} from '../../../../../../utils/theme'

export function OrderFieldsSection() {
  return (
    <Paper
      sx={({colorScheme, colors}) => ({
        backgroundColor: mode(colors.gray[0], colors.dark[6])(colorScheme),
      })}
      className='p-4 space-y-4 rounded-2xl border mb-4'
    >
      <OrderFieldsSectionFirstLine />
      <OrderFieldsSectionSecondLine />
      <OrderFieldsSectionThirdLine />
    </Paper>
  )
}