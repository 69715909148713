import React, {useEffect} from 'react'
import {AutoComplete, Card, Col, Input, Pagination, Row, Select} from 'antd'
import {ArrowDownOutlined, ArrowUpOutlined, LoadingOutlined, SearchOutlined} from '@ant-design/icons'
import {ProductCard} from '../../components/product-card'
import {useSearchContentHook} from './search.hook'
import {getApi} from '../../api/getApi'
import {selectAddToCartBtnDisable} from '../../store/basket/selectors'
import {useSelector} from 'react-redux'

const {Search} = Input
const {Option} = Select

export const SearchContent: React.FC = () => {
  const {
    //props
    searchLoading,
    filteredWord,
    searched,
    paginationCurrent,
    totalSize,
    visibleDropdown,
    searchOptions,
    requestSearchWord,
    analogs,
    analogsCasId,
    analogsLoading,
    currentShowAnalogsItems,
    paginationAnalogsCurrent,
    vendorsList,
    searchVendorsList,
    setIsReverseSorting,
    setSortingProp,

    //methods
    //setSearchProp,
    setFilteredWord,
    setPaginationCurrent,
    onSearchHandler,
    filteredArray,
    addToBasketHandler,
    inputOnSearchHandler,
    _setVisibleDropdown,
    onChooseItem,
    setCurrentShowAnalogsItems,
    setPaginatioAnalogsCurrent,
    filteredAnalogsArray,
    totalAnalogsPagination,
    setVendorsList,
    setSearchVendorsList,
    sortingAnalogs,
    isReverseSorting,
    sortingProp,
  } = useSearchContentHook()
  //
  useEffect(() => {
    let isMounted = true
    getApi().vendors.get().then(({data}) => {
      const { vendors } = data
      if (isMounted) {
        setVendorsList(vendors)
      }
    })
    return () => {
      isMounted = false
    }
  }, [])
  //
  //Key Down Handler
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    (!!requestSearchWord.trim() && !visibleDropdown && (e.keyCode === 40)) && inputOnSearchHandler(requestSearchWord)
  }
  const addToCartBtnDisable = useSelector(selectAddToCartBtnDisable)
  //
  return (
    <>
      <Card bordered={true}>
        <div className='search-section mb-3'>
          <Row gutter={16} className='flex-row-reverse'>
            <Col span={24} className='gutter-row mb-2' md={16}>
              <AutoComplete
                options={searchOptions}
                onSelect={onChooseItem}
                value={requestSearchWord}
                style={{width: '100%'}}
                open={visibleDropdown}
                listHeight={400}
                autoFocus={false}
                placement='bottomLeft'
                className='auto-complete-search'
              >
                <Search
                  style={{ position: 'relative'}}
                  placeholder='Введите любое слово..'
                  enterButton='Найти'
                  disabled={searchLoading}
                  className='search-form'
                  autoFocus={false}
                  onFocus={() => (requestSearchWord.trim() ? inputOnSearchHandler(requestSearchWord) : null)}
                  onBlur={() => _setVisibleDropdown(false)}
                  onSearch={() => onSearchHandler()}
                  onKeyDown={e => onKeyDownHandler(e)}
                  onChange={e => inputOnSearchHandler(e.target.value)}
                />
              </AutoComplete>
            </Col>
            <Col span={24} md={8}>
              <Select
                mode='multiple'
                allowClear
                onChange={(value: string[]) => setSearchVendorsList(value) }
                style={{ width: '100%' }}
                placeholder='Выберите вендоры'>
                {vendorsList.map((item, key) => <Option key={key}>{item}</Option>)}
              </Select>
            </Col>
          </Row>
        </div>
        {searched && <div className='filter-section mb-4'>
          <Row align='middle'>
            <Col span={6}>{totalSize ? `Найдено ${totalSize} позиций` : 'Ничего не найдено'}</Col>
            <Col span={18}>
              <Input
                placeholder='Фильтр по любому полю...'
                value={filteredWord}
                prefix={<SearchOutlined className='mr-0' />}
                onChange={e => {
                  setFilteredWord(e.target.value)
                  setPaginationCurrent(1)
                }}
              />
            </Col>
          </Row>
        </div>}
        <div className='product-list-section'>
          <Row>
            <Col span={24}>
              {!searchLoading && filteredArray.map((item, key) => <ProductCard isDisabledBtn={addToCartBtnDisable} key={key} {...item} addToCart={() => addToBasketHandler(item)}/>)}
              {searchLoading && <div className='d-flex justify-content-center align-items-center'>
                <LoadingOutlined style={{fontSize: 24}}/>
              </div>}
            </Col>
            {(totalSize > 10) && !searchLoading ? <Col span={24} className='d-flex justify-content-center pt-4 pb-4'>
              <Pagination
                total={totalSize}
                current={paginationCurrent}
                onChange={value => setPaginationCurrent(value)}
                showSizeChanger={false}/>
            </Col> : ''}
          </Row>
        </div>
      </Card>

      {/*Прелоадер*/}
      {analogsLoading && <div className='d-flex justify-content-center align-items-center'>
        <LoadingOutlined style={{fontSize: 24}}/>
      </div>}

      {/*Блок с аналогами*/}
      {totalAnalogsPagination && !analogsLoading ? <>
        <div className='d-flex justify-content-between pt-3 pb-3'>
          <h4>Аналоги (CAS {analogsCasId})</h4>
          <div className='analogs-sorting-block d-flex justify-content-between align-items-center'>
            {sortingProp && <div className='icon-block pr-4'>
              <ArrowDownOutlined
                className={`${isReverseSorting ? '' : 'text-white p-2 bg-primary rounded-1 mr-2'}`}
                style={{cursor: 'pointer', borderRadius: '.3rem'}}
                onClick={() => setIsReverseSorting(false)}/>
              <ArrowUpOutlined
                className={`${isReverseSorting ? 'text-white p-2 bg-primary rnd-1  ml-2' : ''}`}
                style={{cursor: 'pointer', borderRadius: '.3rem'}}
                onClick={() => setIsReverseSorting(true)}/>
            </div>}
            {/**/}
            <div className='sorting-props-list'>
              <Select defaultValue='' value={sortingProp} style={{ width: 180 }} onChange={value => setSortingProp(value)}>
                <Option value=''> </Option>
                <Option value='package_size'>package_size</Option>
                <Option value='vendor'>brand</Option>
                <Option value='partnership_type'>partnership_type</Option>
              </Select>
            </div>
          </div>
        </div>
        <Card bordered={true}>
          {filteredAnalogsArray().map((item, key) => <ProductCard isDisabledBtn={addToCartBtnDisable} key={key} {...item} addToCart={() => addToBasketHandler(item)}/>)}
          {(currentShowAnalogsItems < totalAnalogsPagination) && <div className='d-flex justify-content-center pt-4 pb-4'>
            <Pagination
              total={totalAnalogsPagination}
              current={paginationAnalogsCurrent}
              pageSize={currentShowAnalogsItems}
              onChange={value => {
                setPaginatioAnalogsCurrent(value)
              }}
              showSizeChanger={false}/>
          </div>}
        </Card>
      </> : ''}
      {/**/}
    </>
  )
}
