import {Pagination, Select} from '@mantine/core'
import React from 'react'

interface PaginationProps {
  pageIndex: number,
  size: number,
  totalRecordsCount: number,
  sizesList?: Array<number>,
  onPageIndexChange: (page: number) => void,
  onSizeChange: (size: number) => void,
}

function DataTablePagination({
  pageIndex,
  size,
  sizesList,
  totalRecordsCount,
  onPageIndexChange,
  onSizeChange,
}: PaginationProps) {
  const total = Math.ceil(totalRecordsCount / size)
  return (
    <div className='flex justify-end py-2.5 px-2'>
      <Pagination page={pageIndex + 1} onChange={x => onPageIndexChange(x - 1)} total={total} />
      {sizesList && <Select
        value={String(size)}
        data={sizesList.map(String)}
        onChange={x => onSizeChange(Number(x))}
        className='ml-3 w-[80px]'
        size='sm'
      />}
    </div>
  )
}

export default React.memo(DataTablePagination)