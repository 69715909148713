import {TextInput} from '@mantine/core'
import React from 'react'
import {useSelector} from 'react-redux'
import {selectCreateOrderPopupField} from '../../../../../../store/procurement/selectors'
import {CreateOrderPopupFieldLabel} from '../common/label'
import {useCreateOrderPopupContext} from '../context'
import {getCommonFieldClassName} from '../common/common'

export function OrderFieldsSectionThirdLine() {
  return (
    <div className='flex space-x-4'>
      <PoNumberInput />
      <TrackingInput />
      <ManagerContactInput />
    </div>
  )
}

function PoNumberInput() {
  const {updateOrderFiled} = useCreateOrderPopupContext()
  const value = useSelector(selectCreateOrderPopupField('numberPO'))

  return (
    <TextInput
      className={getCommonFieldClassName()}
      label={<CreateOrderPopupFieldLabel label='Номер PO' />}
      value={value}
      onChange={event => updateOrderFiled('numberPO', event.currentTarget.value)}
    />
  )
}


function TrackingInput() {
  const {updateOrderFiled} = useCreateOrderPopupContext()
  const value = useSelector(selectCreateOrderPopupField('tracking'))

  return (
    <TextInput
      className={getCommonFieldClassName()}
      label={<CreateOrderPopupFieldLabel label='Tracking' />}
      value={value}
      onChange={event => updateOrderFiled('tracking', event.currentTarget.value)}
    />
  )
}

function ManagerContactInput() {
  const {updateOrderFiled} = useCreateOrderPopupContext()
  const value = useSelector(selectCreateOrderPopupField('managerContact'))

  return (
    <TextInput
      className={getCommonFieldClassName()}
      label={<CreateOrderPopupFieldLabel label='Контакт менеджера от производителя' />}
      value={value}
      onChange={event => updateOrderFiled('managerContact', event.currentTarget.value)}
    />
  )
}