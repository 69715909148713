import {MultiSelect} from '@mantine/core'
import {SelectItem} from '@mantine/core/lib/components/Select/types'
import React from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../hooks/storeHooks'
import {GlobalFiltersData, setProcurementTableGlobalFilter} from '../../../../store/procurement/reducer'
import {selectProcurementTableGlobalFilter, selectProcurementTableState} from '../../../../store/procurement/selectors'
import {mapToSelectItem} from '../popups/create-order-popup/common/common'

export function ProcurementTableGlobalFiltersSection() {
  const {countries, stores, procurements} = useSelector(selectProcurementTableState('staticData'))
  return (
    <div className='flex space-x-2 ml-3 mr-2'>
      <GlobalMultiSelect
        type='countryIds'
        label={<Label text='Страна' />}
        data={countries.map(mapToSelectItem)}
      />
      <GlobalMultiSelect
        type='storeIds'
        label={<Label text='Магазин' />}
        data={stores.map(mapToSelectItem)}
      />
      <GlobalMultiSelect
        type='procurementIds'
        label={<Label text='Procurement' />}
        data={procurements.map(mapToSelectItem)}
      />
    </div>
  )
}

interface GlobalMultiSelectProps extends React.ComponentPropsWithRef<typeof MultiSelect> {
  type: keyof GlobalFiltersData,
  data: Array<SelectItem>,
}

function GlobalMultiSelect({
  type,
  data,
  ...props
}: GlobalMultiSelectProps) {
  const dispatch = useAppDispatch()
  const selectedValues = useSelector(selectProcurementTableGlobalFilter(type))

  return (
    <MultiSelect
      className='w-[275px]'
      value={selectedValues}
      data={data}
      onChange={items => dispatch(setProcurementTableGlobalFilter({ field: type, value: items }))}
      searchable
      sx={() => ({ label: { marginBottom: '1px' }})}
      {...props}
    />
  )
}

interface LabelProps {
  text: string,
}

function Label({ text }: LabelProps) {
  return <label className='text-xs'>
    {text}
  </label>
}