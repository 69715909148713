import {Sort as SortData} from '@table-library/react-table-library/sort'
import React, {useRef} from 'react'

interface SortStateData<ColumnID extends string> {
  column: ColumnID|'NONE',
  reverse: boolean,
}

interface PrevValuesRefData<ColumnID extends string> {
  sort: SortStateData<ColumnID>,
}

export function useDataTablePrevValuesRef<ColumnID extends string>(): React.MutableRefObject<PrevValuesRefData<ColumnID>> {
  return useRef<PrevValuesRefData<ColumnID>>({
    sort: {
      column: 'NONE',
      reverse: false,
    },
  })
}

export function getDataTableStateCheckers() {
  return {
    checkSortChanged,
  }
}

function checkSortChanged<ColumnID extends string>(prevValues: PrevValuesRefData<ColumnID>, sort: SortData): boolean {
  if (prevValues.sort.column !== sort.state.sortKey || prevValues.sort.reverse !== sort.state.reverse) {
    prevValues.sort.column = sort.state.sortKey
    prevValues.sort.reverse = sort.state.reverse
    return true
  }
  return false
}