import React from 'react'
import {CreateProcurementOrderPopup} from './popups/create-order-popup/index'
import {ProcurementActionsSection} from './actions-section/procurement-actions-section'
import {ProcurementEditItemPopup} from './popups/edit-item-popup/index'
import {ProcurementTable} from './procurement-table'

export function ProcurementView() {
  return (
    <div className='p-6 pt-2.5'>
      <ProcurementActionsSection />
      <ProcurementTable />
      <CreateProcurementOrderPopup />
      <ProcurementEditItemPopup />
    </div>
  )
}