import {Checkbox} from '@mantine/core'
import {Header, HeaderCell, HeaderRow, TableNode} from '@table-library/react-table-library'
import {Sort as SortData} from '@table-library/react-table-library/types/sort'
import React from 'react'
import {DataTableColumnInfo, DataTableFilterDropdowns, DataTableSelectData} from './data-table'
import {DefaultHeaderCell, DefaultHeaderCellSortInfo} from './default-header-cell'

interface DefaultTableHeaderProps<ColumnID extends string, ITEM extends TableNode> {
  columnsInfo: Array<DataTableColumnInfo<ColumnID, ITEM>>
  filterDropdowns?: DataTableFilterDropdowns<ColumnID>
  select?: DataTableSelectData,
  sort?: SortData,
  getShortcutActions?: (item: ITEM) => JSX.Element,
}

export function DefaultTableHeader<ColumnID extends string, ITEM extends TableNode>({
  columnsInfo,
  select,
  sort,
  filterDropdowns,
  getShortcutActions,
}: DefaultTableHeaderProps<ColumnID, ITEM>) {
  //TODO:ka-table мемоизировать логичные вещи
  //TODO:ka-table Доставать из контекста все необходимые пропсы или ниже
  return (
    <Header>
      <HeaderRow>
        {select && <HeaderCell pinLeft={select.pinLeft} className='selectCell'>
          <Checkbox
            checked={select.state.all}
            indeterminate={!select.state.all && !select.state.none}
            onChange={select.fns.onToggleAll}
          />
        </HeaderCell>}
        {columnsInfo.map(column => (
          <DefaultHeaderCell
            key={column.id}
            hideKey={column.id}
            className={column.id}
            title={column.title}
            sortInfo={column.sort && sort && getSortInfo(sort, column.id)}
            filterDropdown={filterDropdowns && filterDropdowns[column.id]}
          />
        ))}
        {!!getShortcutActions && <HeaderCell className='rowActions h-fit' pinRight>{getShortcutActions({} as ITEM) /* Костыль для ширины*/}</HeaderCell>}
      </HeaderRow>
    </Header>
  )
}

function getSortInfo<ColumnID extends string>(sort: SortData, columnId: ColumnID): DefaultHeaderCellSortInfo {
  return {
    state: sort.state.sortKey === columnId
      ? sort.state.reverse ? 'asc' : 'desc'
      : 'normal',
    onToggleSort: () => sort.fns.onToggleSort({
      sortKey: columnId,
    }),
  }
}