import {createContext, useContext} from 'react'
import {
  CreateOrderPopupItem,
  CreateOrderPopupOrderFields,
  OrderStatusId,
} from '../../../../../store/procurement/create-order-popup/types'

type UpdateItemFieldFn = <K extends keyof CreateOrderPopupItem>(id: string, field: K, value: CreateOrderPopupItem[K]) => void
type UpdateOrderFiledFn = <K extends keyof CreateOrderPopupOrderFields>(field: K, value: CreateOrderPopupOrderFields[K]) => void

interface ContextType {
  updateOrderFiled: UpdateOrderFiledFn,
  updateItemField: UpdateItemFieldFn,
}

export const CreateProcurementOrderPopupContext = createContext<ContextType>({
  updateOrderFiled: () => {},
  updateItemField: () => {},
})

export function useCreateOrderPopupContext(): ContextType {
  return useContext(CreateProcurementOrderPopupContext)
}

export function isUnderTrackingStatus(status: OrderStatusId): boolean {
  return status === 'under_tracking'
}