import {TableNode} from '@table-library/react-table-library'
import {Sort as SortData, SortFn, useSort} from '@table-library/react-table-library/sort'
import {verify} from '../../../utils/Logger'
import {DataTableColumnInfo} from '../data-table'

interface UseDataTableSortParams<ColumnID extends string, ITEM extends TableNode> {
  columns: Array<DataTableColumnInfo<ColumnID, ITEM>>,
  tableData: { nodes: Array<ITEM> },
}

export function useDataTableSort<ColumnID extends string, ITEM extends TableNode>({
  tableData,
  columns,
}: UseDataTableSortParams<ColumnID, ITEM>): SortData {
  const sortFns = {} as TypedObject<ColumnID, (rows: Array<ITEM>) => Array<TableNode>>
  columns.forEach(column => {
    if (column.sort) {
      sortFns[column.id] = rows => sortFn(rows, verify(column.sort))
    }
  })

  function sortFn(rows: Array<ITEM>, cb: (a: ITEM, b: ITEM) => number): Array<ITEM> {
    return rows.sort(cb)
  }

  return useSort(tableData, {}, {
    sortFns: sortFns as Record<string, SortFn>,
  })
}