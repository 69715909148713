import {CheckCircleIcon} from '@heroicons/react/outline'
import {ActionIcon, NumberInput, Tooltip} from '@mantine/core'
import React from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../../../hooks/storeHooks'
import {
  toggleAcceptItemOtherCostForTax,
} from '../../../../../../../store/procurement/create-order-popup/create-order-popup-reducer'
import {
  selectCreateOrderPopupItemField,
} from '../../../../../../../store/procurement/selectors'
import {numberInputFormatter, numberInputParser} from '../../../../../../../utils/number'
import {mode} from '../../../../../../../utils/theme'
import {CREATE_ORDER_POPUP_PRECISION} from '../../common/common'
import {useCreateOrderPopupContext} from '../../context'
import {CreateOrderPopupItemFieldProps} from './common'

export function OtherCostInputField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field = 'otherCost'
  const dispatch = useAppDispatch()
  const selected = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const {updateItemField} = useCreateOrderPopupContext()

  return <NumberInput
    value={selected.value || undefined}
    onChange={num => updateItemField(itemId, field, {
      value: num === undefined ? null : num,
      acceptForTax: selected.acceptForTax,
    })}
    parser={numberInputParser}
    formatter={numberInputFormatter}
    hideControls
    precision={CREATE_ORDER_POPUP_PRECISION}
    placeholder='0.00'
    rightSection={<RightSection
      enabled={selected?.acceptForTax || false}
      onClick={() => dispatch(toggleAcceptItemOtherCostForTax(itemId))}
    />}
  />
}


interface RightSectionProps {
  enabled: boolean,
  onClick: () => void,
}

function RightSection({
  enabled,
  onClick,
}: RightSectionProps) {

  return (
    <Tooltip label='Учитывать при расчёте tax'>
      <ActionIcon className='transparent hover:bg-transparent duration-150' onClick={onClick} sx={({colors, colorScheme}) => ({
        color: mode(
          enabled ? colors.green[5] : colors.gray[5],
          enabled ? colors.green[7] : colors.gray[6],
        )(colorScheme),
        '&:hover': {
          color: mode(
            enabled ? colors.green[7] : colors.gray[6],
            enabled ? colors.green[6] : colors.gray[5],
          )(colorScheme),
        },
      })}
      >
        <CheckCircleIcon className='w-4 h-4 mr-1' />
      </ActionIcon>
    </Tooltip>
  )
}
