import {Box} from '@mantine/core'
import {DatePicker} from '@mantine/dates'
import React from 'react'
import {
  getEditItemPopupRequiredFieldsSet,
} from '../index'
import {
  CommonFieldProps,
  ProcurementEditItemPopupFormLabel,
  getProcurementEditItemPopupInputSxStyles,
  getProcurementTableColumnTitle,
} from './common'

interface Props extends CommonFieldProps {
  field: 'purchaseTimestamp',
}

export function EditItemPopupDatePicker({
  field,
  form,
}: Props) {
  return <Box sx={getProcurementEditItemPopupInputSxStyles}>
    <ProcurementEditItemPopupFormLabel
      label={getProcurementTableColumnTitle(field)}
      required={getEditItemPopupRequiredFieldsSet().has(field)}
    />
    <DatePicker
      clearable={false}
      {...form.getInputProps(field)}
    />
  </Box>
}