import {Global} from '@mantine/core'
import {mode} from '../../../utils/theme'

export function DefaultGlobalStyles() {
  return  <Global
    styles={({colors, colorScheme, fn}) => ({
      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        scrollbarGutter: 'stable',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: mode(colors.gray[2], colors.dark[7])(colorScheme),
        borderRadius: '11px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: mode(colors.gray[4], fn.rgba(colors.blue[9], 0.8))(colorScheme),
        borderRadius: '11px',
        transition: 'background-color 200ms ease-out',
      },
      '::-webkit-scrollbar-corner': {
        background: 'transparent'
      },
    })}
  />
}