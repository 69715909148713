import {SelectTypes} from '@table-library/react-table-library/select'
import React, {memo, useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../hooks/storeHooks'
import {useLocalStorage} from '../../../hooks/useLocalStorage/useLocalStorage'
import {
  ProcurementItem,
  loadProcurementTableData,
  setProcurementTableSelectedRowIds,
} from '../../../store/procurement/reducer'
import {selectProcurementTableState} from '../../../store/procurement/selectors'
import {isNumber} from '../../../utils/number'
import {DataTable} from '../../../uikit/data-table/data-table'
import {ActionsCell} from './cells/actions-cell'
import {useProcurementTableColumnsHook} from './hooks/use-procurement-table-columns.hook'
import {useProcurementTableTheme} from './table-theme'
import {useProcurementTableColumnFilters} from './hooks/use-procurement-table-column-filters'

export const ProcurementTable = memo(() => {
  const {setToLocalStorage} = useLocalStorage('procurement')
  const dispatch = useAppDispatch()
  const theme = useProcurementTableTheme()
  const columns = useProcurementTableColumnsHook()
  const data = useSelector(selectProcurementTableState('data'))
  const {
    filterDropdowns,
    visibleRowIds,
  } = useProcurementTableColumnFilters()
  const visibleColumnIds = useSelector(selectProcurementTableState('visibleColumns'))
  const initData = useInitialData()
  const searchValue = useSelector(selectProcurementTableState('searchValue'))

  const filteredByColumnsData = visibleRowIds === null
    ? data
    : data.filter(x => visibleRowIds.has(x.id))
  const filteredData = useGlobalFiltersData(filteredByColumnsData)

  useEffect(() => {
    dispatch(loadProcurementTableData())
  }, [dispatch])

  return <DataTable
    data={filteredData}
    columns={columns}
    theme={theme}
    pagination={{
      initSize: initData.paginationSize,
      initPageIndex: initData.paginationPage,
      sizesList: [25, 50],
      onPageIndexChange: index => setToLocalStorage('pagination-page', index),
      onSizeChange: size => setToLocalStorage('pagination-size', size),
    }}
    select={{
      options: {
        rowSelect: SelectTypes.MultiSelect,
        pinLeft: true,
      },
      onChange: ids => dispatch(setProcurementTableSelectedRowIds(ids)),
    }}
    layout={{horizontalScroll: true}}
    height='calc(100vh - 240px)'
    filterDropdowns={filterDropdowns}
    visibleColumnIds={visibleColumnIds}
    searchValue={searchValue}
    getShortcutActions={item => <ActionsCell item={item}/>}
  />
})

function useGlobalFiltersData(data: Array<ProcurementItem>): Array<ProcurementItem> {
  const filters = useSelector(selectProcurementTableState('globalFilters'))

  return useMemo(() => {
    const countryIdsSet = filters.countryIds.length > 0 ? new Set(filters.countryIds) : null
    const procurementIdsSet = filters.procurementIds.length > 0 ? new Set(filters.procurementIds) : null
    const storeIdsSet = filters.storeIds.length > 0 ? new Set(filters.storeIds) : null

    return data.filter(x => {
      const v1 = countryIdsSet === null ? true : countryIdsSet.has(x.country)
      const v2 = procurementIdsSet === null ? true : procurementIdsSet.has(x.country)
      const v3 = storeIdsSet === null ? true : storeIdsSet.has(x.country)
      return v1 && v2 && v3
    })
  }, [data, filters.countryIds, filters.procurementIds, filters.storeIds])
}

function useInitialData() {
  const {getFromLocalStorage} = useLocalStorage('procurement')
  return useMemo(() => {
    const rawPaginationPage = getFromLocalStorage('pagination-page')
    const rawPaginationSize = getFromLocalStorage('pagination-size')
    return {
      paginationPage: rawPaginationPage && isNumber(rawPaginationPage) ? Number(rawPaginationPage) : 0,
      paginationSize: rawPaginationSize && isNumber(rawPaginationSize) ? Number(rawPaginationSize) : 25,
    }
  }, [getFromLocalStorage])
}