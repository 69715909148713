import {Textarea} from '@mantine/core'
import React from 'react'
import {
  CommonFieldProps,
  ProcurementEditItemPopupFormLabel,
  getProcurementEditItemPopupInputSxStyles, getProcurementTableColumnTitle,
} from './common'

interface Props extends CommonFieldProps {
  field: 'procurementComment',
}

export function EditItemPopupTextArea({
  field,
  form,
}: Props) {
  return <Textarea
    sx={getProcurementEditItemPopupInputSxStyles}
    label={<ProcurementEditItemPopupFormLabel label={getProcurementTableColumnTitle(field)} />}
    minRows={2}
    maxRows={2}
    {...form.getInputProps(field)}
  />
}