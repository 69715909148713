import React from 'react'
import Highlighter from 'react-highlight-words'
import parse from 'html-react-parser'
import {useSelector} from 'react-redux'
import {AnyType} from '../../../types'
import {selectTheme} from '../../store/theme/selectors'

type SearchResultComponentType = {
	requestData: AnyType
	onClick?: ()=> void
}

//Title Style
const titleStyle = {
  maxWidth: 700,
  overflow: 'hidden',
  width: '80%',
  textOverflow: 'ellipsis',
  fontWeight: 600,
}

//Prop Item Style
const propsItemStyle = {
  display: 'inline-block',
  width: '20%',
  minWidth: 100,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 400,
}

//Is Available Style
const isAvaliableStyle = {
  display: 'inline-block',
  fontWeight: 600,
}

export const SearchResultComponent: React.FC<SearchResultComponentType> = props => {
  const {requestData:{
    data,
    text,
  }, onClick } = props
  const theme = useSelector(selectTheme)

  const highlightTextArray = (value: AnyType[], fieldName?: string) => {

    if (!value && fieldName === 'name') {
      return parse(`<span>${data[fieldName as keyof typeof data]}</span>>`)
    }

    if (!value) {
      return data[fieldName as keyof typeof data]
    }

    const jsxArray = value.map((item, key) => {
      if (item.hl === true) {
        return <span key={key}>
          <Highlighter
            activeClassName='bgDarg'
            searchWords={[item.text]}
            autoEscape={true}
            textToHighlight={item.text}
          />
        </span>
      }
      else {
        return <span key={key}>{parse(item.text)}</span>
      }
    })

    return jsxArray
  }

  return (
    <>
      <div className='search-result-dropdown-item pb-3 ' style={{position: 'relative'}} onClick={() => onClick && onClick()}>
        <div className='title-holder'>
          <h5 className='title pt-1' style={titleStyle}>{data.field === 'name' ? highlightTextArray(text, 'name') : parse(`<span class="name-wrapper">${data.name}</span>`)}</h5>
        </div>

        {/**/}
        <div className='props-holder justify-content-between text-center d-none d-sm-flex'>
          <span className={` ${theme === 'light' ? 'bg-gray-lighter text-dark' : 'bg-gray'} pt-1 pb-1 pl-1 pr-1 rounded font-weight-normal`} style={propsItemStyle}>
            {data.field === 'vendor' ? highlightTextArray(text, 'vendor') : `${data.vendor}`}
          </span>
          <span className={` ${theme === 'light' ? 'bg-gray-lighter text-dark' : 'bg-gray'} pt-1 pb-1 pl-1 pr-1 rounded font-weight-normal`} style={propsItemStyle}>
            {data.field === 'catalogue_id' ? highlightTextArray(text, 'catalogue_id') : `${data.catalogue_id}`}
          </span>
          <span className={` ${theme === 'light' ? 'bg-gray-lighter text-dark' : 'bg-gray'} pt-1 pb-1 pl-1 pr-1 rounded font-weight-normal`} style={propsItemStyle}>
            {data.field === 'cas_number' ? highlightTextArray(text, 'cas_id') : `${data.cas_id}`}
          </span>
          <span className={`${data.availability === 'Available' ? 'text-success' : 'text-danger'} pt-1 pb-1 pr-2 rounded`} style={isAvaliableStyle}>{data.availability}</span>
        </div>

        {/**/}
        <div className='props-holder d-flex justify-content-start text-center d-sm-none' style={{width: '80%'}}>
          <span className={` ${theme === 'light' ? 'bg-gray-lighter text-dark' : 'bg-gray'} pt-1 pb-1 pl-1 pr-1 rounded font-weight-normal`} style={propsItemStyle}>
            {data.field === 'vendor' ? highlightTextArray(text, 'vendor') : `${data.vendor}`}
          </span>
          <span className={` ${theme === 'light' ? 'bg-gray-lighter text-dark' : 'bg-gray'} pt-1 pb-1 pl-1 pr-1 ml-3 rounded font-weight-normal`} style={propsItemStyle}>
            {data.field === 'catalogue_id' ? highlightTextArray(text, 'catalogue_id') : `${data.catalogue_id}`}
          </span>
          <span className={`${data.availability === 'Available' ? 'text-success' : 'text-danger'} pt-1 pb-1 pl-3 rounded`} style={isAvaliableStyle}>{data.availability}</span>
        </div>
      </div>
    </>
  )
}
