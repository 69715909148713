import {Autocomplete} from '@mantine/core'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {
  selectCreateOrderPopupItemField,
  selectCreateOrderPopupStaticDataItem,
} from '../../../../../../../store/procurement/selectors'
import {getCommonFieldClassName} from '../../common/common'
import {useCreateOrderPopupContext} from '../../context'
import {CreateOrderPopupItemFieldProps} from './common'

export function BrandSelectField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field = 'brand'
  const selectedBrand = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const brands = useSelector(selectCreateOrderPopupStaticDataItem('brands'))
  const data = useMemo(() => brands.map(x => x.id), [brands])
  const {updateItemField} = useCreateOrderPopupContext()

  return <Autocomplete
    value={selectedBrand || ''}
    onChange={value => updateItemField(itemId, field, value)}
    onItemSubmit={item => updateItemField(itemId, field, item.value)}
    data={data}
    className={getCommonFieldClassName()}
  />
}