import {ActionIcon, Paper, Text, useMantineTheme} from '@mantine/core'
import {SearchCircleIcon} from '@heroicons/react/outline'
import {TwinLogo} from '../../components/icons/twin-logo'
import {mode} from '../../utils/theme'
import {SIDE_NAV_COLLAPSED_WIDTH} from '../../constants/ThemeConstant'

export function ProcurementLeftNav() {
  const {colors, colorScheme} = useMantineTheme()
  return (
    <Paper
      radius={0}
      sx={() => ({
        backgroundColor: mode(colors.blue[5], colors.dark[7])(colorScheme),
        width: SIDE_NAV_COLLAPSED_WIDTH,
      })}
      className='flex flex-col items-center shrink-0'
    >
      <TwinLogo style={{margin: '20px', color: 'white'}}/>
      <MenuItem text='Procurement' icon={<SearchCircleIcon className='w-7 h-7 shrink-0' />} />
    </Paper >
  )
}

interface MenuItemProps {
  text: string,
  icon: JSX.Element,
}

function MenuItem({text, icon}: MenuItemProps) {
  return (
    <ActionIcon radius={0} className='flex flex-col w-full py-7 px-1 duration-200'
      sx={({white, fn}) => ({
        color: white,
        '&:hover': {
          backgroundColor: fn.rgba(white, 0.10),
        },
      })}
    >
      {icon}
      <Text className='text-[10px] font-semibold mt-1'>{text}</Text>
    </ActionIcon>
  )
}