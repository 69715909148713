import {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectProcurementTableState} from '../../../../store/procurement/selectors'
import {DataTableFilterDropdowns} from '../../../../uikit/data-table/data-table'
import {DateRangePopover} from '../../../../uikit/date-range-popover/date-range-popover'
import {ProcurementTableColumnId} from './use-procurement-table-columns.hook'
import {values} from 'ramda'
import {cleanArray, intersectionSets} from '../../../../utils/byDataTypes/array'
import {isNullable} from '../../../../utils/check'
import {dateInRange} from '../../../../utils/date'
import {startOfDay} from 'date-fns'

interface ReturnData {
  filterDropdowns: DataTableFilterDropdowns<ProcurementTableColumnId>,
  visibleRowIds: null|Set<string>,
}

interface FiltersValues {
  invoiceTimestamp: [Date|null, Date|null],
  deadlineTimestamp: [Date|null, Date|null],
  purchaseTimestamp: [Date|null, Date|null],
}

type ProcurementDateFilterColumnId = Extract<ProcurementTableColumnId, 'invoiceTimestamp'|'deadlineTimestamp'|'purchaseTimestamp'>

//TODO:procurement Реализовать сохранение фильтров в localStorage
export function useProcurementTableColumnFilters(): ReturnData {
  const data = useSelector(selectProcurementTableState('data'))
  const [filtersValues, setFiltersValues] = useState<FiltersValues>({
    invoiceTimestamp: [null, null],
    deadlineTimestamp: [null, null],
    purchaseTimestamp: [null, null],
  })

  const [filteredColumnIds, setFilteredColumnIds] = useState<TypedObject<ProcurementTableColumnId, Set<string>|null>>({})

  return useMemo(() => {
    const updateFilterValue = <K extends keyof FiltersValues>(column: K, value: FiltersValues[K]) => {
      setFiltersValues(prev => ({...prev, [column]: value}))
    }
    const updateFilteredColumnIds = (column: ProcurementTableColumnId, value: null|Set<string>) => {
      setFilteredColumnIds(prev => ({...prev, [column]: value}))
    }

    function confirmDateFilter(column: ProcurementDateFilterColumnId, range: [Date, Date]|null) {
      if (range === null) {
        updateFilteredColumnIds(column, null)
        updateFilterValue(column, [null, null])
        return
      }
      const selectedIds = data
        .filter(x => {
          const timestamp = x[column]
          return isNullable(timestamp) ? null : dateInRange(startOfDay(timestamp), range)
        })
        .map(x => x.id)
      updateFilteredColumnIds(column, new Set(selectedIds))
      updateFilterValue(column, range)
    }

    const filterDropdowns: DataTableFilterDropdowns<ProcurementTableColumnId> = {
      invoiceTimestamp: props => <DateRangePopover
        value={filtersValues['invoiceTimestamp']}
        confirmValue={range => {
          confirmDateFilter('invoiceTimestamp', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
      deadlineTimestamp: props => <DateRangePopover
        value={filtersValues['deadlineTimestamp']}
        confirmValue={range => {
          confirmDateFilter('deadlineTimestamp', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
      purchaseTimestamp: props => <DateRangePopover
        value={filtersValues['purchaseTimestamp']}
        confirmValue={range => {
          confirmDateFilter('purchaseTimestamp', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
    }
    const idsSets = cleanArray(values(filteredColumnIds))
    const visibleRowIds = idsSets.length > 0 ? intersectionSets(...idsSets) : null
    return {
      filterDropdowns,
      visibleRowIds,
    }
  }, [data, filteredColumnIds, filtersValues])
}