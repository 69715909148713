import {Paper} from '@mantine/core'
import {useSelector} from 'react-redux'
import {selectProcurementCreateOrderPopupItems} from '../../../../../../store/procurement/selectors'
import {CREATE_ORDER_POPUP_PRECISION} from '../common/common'
import {GRID_TEMPLATE_COLUMNS} from './columns'
import {mode} from '../../../../../../utils/theme'
import {numberInputFormatter} from '../../../../../../utils/number'
import {TextWithEllipsis} from '../../../../../../uikit/TextWithEllipsis'

interface SummaryData {
  uniqueCatalogues: number,
  uniqueBrands: number,
  cost: number,
  tax: number,
  otherCost: number,
  quantity: number,
  clientPrice: number,
}

export function SummarySection() {
  const items = useSelector(selectProcurementCreateOrderPopupItems)
  const cataloguesSet: Set<string> = new Set()
  const brandsSet: Set<string> = new Set()
  const summaryData = items.reduce((acc: SummaryData, item) => {
    item.catalogue && cataloguesSet.add(item.catalogue)
    item.brand && brandsSet.add(item.brand)
    acc.cost += item.cost || 0
    acc.tax += item.tax?.value || 0
    acc.otherCost += item.otherCost?.value || 0
    acc.quantity += item.quantity
    acc.clientPrice += item.clientPrice
    return acc
  }, { uniqueCatalogues: 0, uniqueBrands: 0, cost: 0, tax: 0, otherCost: 0, quantity: 0, clientPrice: 0 })
  summaryData.uniqueCatalogues = cataloguesSet.size
  summaryData.uniqueBrands = brandsSet.size

  return (
    <Paper
      className='grid gap-x-4 items-center mt-3 py-2.5 rounded-xl sticky bottom-0'
      sx={({colorScheme, colors}) => ({
        backgroundColor: mode(colors.gray[0], colors.dark[6])(colorScheme),
        gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
      })}
    >
      <div className='flex justify-between px-2'>
        <span>Итого</span>
        <span>{summaryData.uniqueBrands}</span>
      </div>
      <TextWithEllipsis
        className='col-span-2 px-2'
        text={numberInputFormatter(summaryData.uniqueCatalogues.toString())}
      />
      <TextWithEllipsis
        className='px-2'
        text={numberInputFormatter(summaryData.cost.toFixed(CREATE_ORDER_POPUP_PRECISION))}
      />
      <TextWithEllipsis
        className='px-2'
        text={numberInputFormatter(summaryData.tax.toFixed(CREATE_ORDER_POPUP_PRECISION))}
      />
      <TextWithEllipsis
        className='px-2'
        text={numberInputFormatter(summaryData.otherCost.toFixed(CREATE_ORDER_POPUP_PRECISION))}
      />
      <TextWithEllipsis
        className='col-span-3'
        text={numberInputFormatter(summaryData.quantity.toString())}
      />
      <TextWithEllipsis
        text={numberInputFormatter(summaryData.clientPrice.toFixed(CREATE_ORDER_POPUP_PRECISION))}
      />
    </Paper>
  )
}