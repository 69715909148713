import {useForm} from '@mantine/form'
import {format, getYear, toDate} from 'date-fns'
import {equals} from 'ramda'
import React, {useMemo} from 'react'
import {ProcurementItem} from '../../../../../store/procurement/reducer'
import {getPopupDefaultButtons} from '../../../../../uikit/PopupDefault'
import {isNonNullable} from '../../../../../utils/check'
import {numberInputFormatter} from '../../../../../utils/number'
import {mapToAutocompleteItem} from '../create-order-popup/common/common'
import {EditItemPopupAutocomplete} from './fields/autocomplete-field'
import {EditItemPopupDatePicker} from './fields/datepicker-field'
import {EditItemPopupNumberInput} from './fields/number-input-field'
import {EditItemPopupTextInput} from './fields/text-input-field'
import {EditItemPopupTextArea} from './fields/textarea-field'
import {EditItemPopupFormState, ProcurementEditItemPopupStaticData, useProcurementEditItemPopupContext} from './index'

interface EditItemPopupContentProps {
  item: ProcurementItem,
}

export function EditItemPopupContent({
  item,
}: EditItemPopupContentProps) {
  const {
    staticData,
    initValuesRef,
    closeFn,
  } = useProcurementEditItemPopupContext()
  const countries = useMemo(() => staticData.countries.map(mapToAutocompleteItem), [staticData.countries])
  const stores = useMemo(() => staticData.stores.map(mapToAutocompleteItem), [staticData.stores])
  const brands = useMemo(() => staticData.brands.map(mapToAutocompleteItem), [staticData.brands])

  function initValues() {
    const initData = getInitFormData({
      item,
      staticData,
    })
    initValuesRef.current = initData
    return initData
  }

  const form = useForm<EditItemPopupFormState>({
    initialValues: initValues(),
    validate: {
      supposedStore: validateDefault,
      brand: validateDefault,
      purchaseTimestamp: validateDefault,
      country: validateDefault,
      catalogueId: validateDefault,
      quantity: validateDefault,
    },
  })

  const onSave = () => {
    if (form.validate().hasErrors) {
      return
    }
    //TODO:WMLN-84 API сохранение значений
    closeFn()
  }

  return (
    <form className='space-y-2'>
      <EditItemPopupTextInput form={form} disabled field='invoiceTimestamp' />
      <EditItemPopupTextInput form={form} disabled field='deadlineTimestamp' />
      <EditItemPopupTextInput form={form} disabled field='clientName' />
      <EditItemPopupTextInput form={form} disabled field='responsibleProcurement' />
      <EditItemPopupTextInput form={form} disabled field='amoId' />
      <EditItemPopupTextInput form={form} disabled field='clientPrice' />
      <EditItemPopupTextInput form={form} disabled field='invoiceName' />
      <EditItemPopupAutocomplete form={form} field='supposedStore' data={stores} />
      <EditItemPopupAutocomplete form={form} field='brand' data={brands} />
      <EditItemPopupDatePicker form={form} field='purchaseTimestamp' />
      <EditItemPopupAutocomplete form={form} field='country' data={countries} />
      <EditItemPopupTextInput form={form} field='catalogueId' />
      <EditItemPopupNumberInput form={form} field='quantity'/>
      <EditItemPopupTextArea form={form} field='procurementComment' />
      <div className='flex justify-end mt-4 space-x-2'>
        {getPopupDefaultButtons({
          success: {
            onClick: onSave,
            disabled: equals(form.values, initValuesRef.current),
          },
          cancel: { onClick: closeFn },
        })}
      </div>
    </form>
  )
}

interface getInitFormDataParams {
  staticData: ProcurementEditItemPopupStaticData,
  item: ProcurementItem,
}

function getInitFormData({
  staticData,
  item,
}: getInitFormDataParams) {
  return {
    invoiceTimestamp: item.invoiceTimestamp ? formatTimestamp(item.invoiceTimestamp) : '',
    deadlineTimestamp: item.deadlineTimestamp ? formatTimestamp(item.deadlineTimestamp) : '',
    clientName: item.clientName,
    responsibleProcurement: item.responsibleProcurement,
    amoId: item.amoId,
    clientPrice: numberInputFormatter(item.clientPrice.toString()),
    invoiceName: item.invoiceName,
    supposedStore: staticData.stores.find(x => x.id === item.supposedStore)?.title,
    brand: staticData.brands.find(x => x.id === item.brand)?.title,
    country: staticData.countries.find(x => x.id === item.country)?.title,
    purchaseTimestamp: isNonNullable(item.purchaseTimestamp) ? toDate(item.purchaseTimestamp) : null,
    catalogueId: item.catalogueId,
    quantity: item.quantity,
    procurementComment: '', //TODO:WMLN-84 API добавить procurementComment
  }
}

function formatTimestamp(timestamp: number): string {
  const currentYear = new Date().getFullYear()
  return format(timestamp, getYear(timestamp) === currentYear ? 'dd.MM' : 'dd.MM.yyyy')
}

function validateDefault(x: unknown): null|true {
  return x ? null : true
}