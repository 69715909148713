import {ActionIcon} from '@mantine/core'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {selectProcurementTableState} from '../../../../store/procurement/selectors'
import {SelectListItem} from '../../../../uikit/select-list/multi-select-popover'
import {ProcurementTableColumnId, getProcurementTableColumnsInfo} from '../hooks/use-procurement-table-columns.hook'
import {useAppDispatch} from '../../../../hooks/storeHooks'
import {AdjustmentsIcon} from '@heroicons/react/outline'
import {mode} from '../../../../utils/theme'
import {MultiSelectList} from '../../../../uikit/select-list/multi-select-list'
import {setProcurementTableVisibilityColumns} from '../../../../store/procurement/reducer'

export function VisibleColumnsButton() {
  const dispatch = useAppDispatch()
  const visibleColumns = useSelector(selectProcurementTableState('visibleColumns'))
  const allColumns = useMemo(() => {
    const array = Object.entries(getProcurementTableColumnsInfo())
      .map(([key, value]) => ({id: key, label: value}))
    return array as Array<SelectListItem<ProcurementTableColumnId>>
  }, [])

  return (
    <MultiSelectList
      selectedIds={visibleColumns}
      data={allColumns}
      button={<Button />}
      saveButton={{
        onClick: ids => dispatch(setProcurementTableVisibilityColumns(ids)),
      }}
      canToggle={({checked, selectedIds}) => checked || selectedIds.length > 0}
      listClassName='max-h-[325px]'
    />
  )
}

function Button() {
  return (
    <ActionIcon
      className='mb-1'
      sx={({colors, colorScheme}) => ({
        color: mode(colors.gray[7], colors.gray[2])(colorScheme),
      })}
    >
      <AdjustmentsIcon />
    </ActionIcon>
  )
}