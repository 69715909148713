import {Autocomplete, AutocompleteItem} from '@mantine/core'
import React from 'react'
import {getEditItemPopupRequiredFieldsSet} from '../index'
import {
  CommonFieldProps,
  ProcurementEditItemPopupFormLabel,
  getProcurementEditItemPopupInputSxStyles, getProcurementTableColumnTitle,
} from './common'

interface EditItemPopupAutocompleteProps extends CommonFieldProps {
  field: 'brand'|'supposedStore'|'country',
  data: Array<AutocompleteItem>,
}

export function EditItemPopupAutocomplete({
  field,
  data,
  form,
}: EditItemPopupAutocompleteProps) {
  return <Autocomplete
    sx={getProcurementEditItemPopupInputSxStyles}
    label={<ProcurementEditItemPopupFormLabel
      label={getProcurementTableColumnTitle(field)}
      required={getEditItemPopupRequiredFieldsSet().has(field)}
    />}
    data={data}
    onFocus={() => form.clearFieldError(field)}
    {...form.getInputProps(field)}
  />
}