import {createSelector} from '@reduxjs/toolkit'
import {verify} from '../../utils/Logger'
import {RootState} from '../types'
import {
  CreateOrderPopupErrorsState,
  CreateOrderPopupRequiredItemField,
  CreateOrderPopupValidationOrderField,
} from './create-order-popup/errors-reducer'
import {
  CreateOrderPopupItem,
  CreateOrderPopupOrderFields,
  MutableCreateOrderPopupData,
  StaticCreateOrderPopupData,
} from './create-order-popup/types'
import {GlobalFiltersData, ProcurementTableState} from './reducer'

export const selectProcurementTable = (state: RootState) => state.procurement.table
export const selectCreateOrderPopupData = (state: RootState) => state.procurement.createOrderPopup.main
export const selectCreateOrderPopupErrors = (state: RootState) => state.procurement.createOrderPopup.errors

export const selectProcurementTableState = <T extends keyof ProcurementTableState>(field: T) => createSelector(
  selectProcurementTable, state => state[field],
)

export const selectCreateOrderPopupField = <T extends keyof CreateOrderPopupOrderFields>(field: T) => createSelector(
  selectCreateOrderPopupData,
  state => state.mutableData.orderFields[field],
)
export const selectProcurementCreateOrderPopupItems = createSelector(selectCreateOrderPopupData, state => state.mutableData.items)
export const selectCreateOrderPopupStaticDataItem = <Key extends keyof StaticCreateOrderPopupData>(key: Key) => createSelector(
  selectCreateOrderPopupData,
  state => state.staticData[key],
)
export const selectCreateOrderPopupMutableDataItem = <Key extends keyof MutableCreateOrderPopupData>(key: Key) => createSelector(
  selectCreateOrderPopupData,
  state => state.mutableData[key],
)
export const selectCreateOrderPopupItemField = <T extends keyof CreateOrderPopupItem>(id: string, field: T) => createSelector(
  selectCreateOrderPopupData,
  state => {
    const item = verify(state.mutableData.items.find(x => x.id === id))
    return item[field]
  },
)

export const selectCreateOrderPopupFieldError = (field: CreateOrderPopupValidationOrderField) => createSelector(
  selectCreateOrderPopupErrors,
  state => state.orderFields.includes(field),
)
export const selectCreateOrderPopupItemFieldError = (id: string, field: CreateOrderPopupRequiredItemField) => createSelector(
  selectCreateOrderPopupErrors,
  state => state.items[id]?.includes(field),
)
export const selectCreateOrderPopupErrorsState = <T extends keyof CreateOrderPopupErrorsState>(field: T) => createSelector(
  selectCreateOrderPopupErrors, state => state[field],
)
export const selectProcurementTableGlobalFilter = <T extends keyof GlobalFiltersData>(field: T) => createSelector(
  selectProcurementTable, state => state.globalFilters[field],
)