import {MutableRefObject, useLayoutEffect, useRef} from 'react'
import {ReactSnipCallbacks} from '../components/MultilineEllipsis'
import {snipText} from '../method'
import {addObserver, defaultOptions, destroyObserver, getOptions} from '../utils'

export type SnipOptions = {
  method: 'css' | 'js'
  lines: number
  midWord: boolean
  ellipsis: string
}

type ElementState = {
  fullText?: string
  observer?: ResizeObserver
  prevWidth?: number
  prevHeight?: number
}

export type SnipState = {
  options: SnipOptions
  element: ElementState
}

export const useSnip = (ref: MutableRefObject<HTMLElement>, userOptions: Partial<SnipOptions>, callbacks?: ReactSnipCallbacks): void => {
  const state = useRef<SnipState>({
    options: defaultOptions,
    element: {},
  })

  useLayoutEffect(() => {
    state.current.options = getOptions(userOptions)
  }, [userOptions])

  useLayoutEffect(() => {
    state.current.element = { fullText: ref.current?.textContent || undefined }
  }, [ref])

  useLayoutEffect(() => {
    const isObserverAvailable = typeof ResizeObserver !== 'undefined'
    const needsObserver = state.current.options.method === 'js'

    const isGettingObserverAdded = isObserverAvailable && needsObserver && !hasObserver(state)
    const isGettingObserverDestroyed = isObserverAvailable && !needsObserver && hasObserver(state)
    isGettingObserverDestroyed && destroyObserver(state)

    isGettingObserverAdded && addObserver(ref, state, callbacks)
    isGettingObserverAdded || snipText(ref, state, callbacks)

    return () => {
      hasObserver(state) && destroyObserver(state)
    }
  }, [callbacks, ref, state.current.options])
}

function hasObserver(state: MutableRefObject<SnipState>) {
  return !!state.current.element.observer
}