import React, { useState} from 'react'
import {Form} from 'antd'
import {selectHandleInputProduct} from '../../store/search/selectors'
import {useDispatch, useSelector} from 'react-redux'
import {setProductsListAction} from '../../store/basket/reducer'
import {HandleInputProductType} from '../../store/search/reducer'
import {HandleInputForm} from '../../components/handle-input-form'

export const HandleInputBasketContent: React.FC = () => {
  const handleInputProduct = useSelector(selectHandleInputProduct)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [price_currency] = useState<string>('USD')
  const onFinish = (object: HandleInputProductType) => {
    const arrayData = localStorage.getItem('basketAppscience')

    if (arrayData) {
      const _resultArray = JSON.parse(arrayData)
      _resultArray.push({...object, price_currency})
      localStorage.setItem('basketAppscience', JSON.stringify(_resultArray))
      //@ts-ignore
      dispatch(setProductsListAction([..._resultArray]))
    }
    else {
      localStorage.setItem('basketAppscience', JSON.stringify([{...object, price_currency}]))
      //@ts-ignore
      dispatch(setProductsListAction([{...object, price_currency}]))
    }
    form.resetFields()
  }

  return (
    <HandleInputForm
      initialValues={handleInputProduct}
      onFinishHandler={onFinish}
      showFinishBtn={true}/>
  )
}
