//Параметры поиска
import React from 'react'
import axios from 'axios'
import {ProductType} from '../../store/search/types'
import {ProductBasketType} from '../../store/basket/types'

export type SearchParamsType = {
  any?: string | number
  catalogue_id?: string | number
  cas_id?: string | number
  name?: string
  vendor?: string
}

//Axios Props
export const CancelToken = axios.CancelToken
export const source = CancelToken.source()

//Axios Suggest Request Data
export const axiosSuggestRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}

//Axios Search Request Data
export const axiosSearchRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}

//Search Type
type ResponseDataType = {
  data: ProductType
  text: Array<{h1: boolean, text: string}>
}

//Search Array Type
export type ResponseDataArrayType = ResponseDataType[]

//Search Label type
export type SearchLabelType = {
  idx: number
  // eslint-disable-next-line
  searchArray: any[]
  theme: string
  addToBasketFromSearchResult: (e: React.MouseEvent<HTMLElement>, product: ProductType, basketProducts: ProductBasketType[]) => void
}
