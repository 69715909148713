//Search Label
import React from 'react'
import {SearchLabelType} from '../search.type'
import {SearchResultComponent} from '../../../components/search-result'
import {Button} from 'antd'
import {ShoppingCartOutlined} from '@ant-design/icons'
import {selectBasketProducts, selectAddToCartBtnDisable} from '../../../store/basket/selectors'
import {useSelector} from 'react-redux'

export const SearchLabel: React.FC<SearchLabelType> = props => {
  const {
    idx,
    searchArray,
    theme,
    addToBasketFromSearchResult,
  } = props

  const basketProducts = useSelector(selectBasketProducts)
  const addToCartBtnDisable = useSelector(selectAddToCartBtnDisable)

  return (
    <div key={`${idx}`} className='search-result-dropdown-wrapper'>
      <div className='search-result-dropdown-top-holder'>
        <SearchResultComponent requestData={searchArray[idx]}/>
      </div>
      <div className='search-result-basket-block'>
        <Button type='primary' disabled={addToCartBtnDisable} className='search-result-dropdown-item-add-to-cart-btn' size='small' onClick={e => addToBasketFromSearchResult(e, searchArray[idx].data, basketProducts)} >
          <ShoppingCartOutlined className='cart-outline' style={{fontSize: 17}}/>
        </Button>
      </div>
      <div className={` ${theme === 'light' ? 'bg-gray-lighter' : 'bg-gray'} `} style={{height: 1, width: '100%', position: 'absolute', bottom: 0, left: 0}}> </div>
    </div>
  )
}
