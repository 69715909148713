import {useMantineTheme} from '@mantine/core'
import {Theme} from '@table-library/react-table-library/types/theme'
import {mode} from '../../../utils/theme'

export function useProcurementTableTheme(): Theme {
  const {colors, colorScheme} = useMantineTheme()
  const colorTheme: Theme = {
    HeaderCell: `
      padding: 3px 0;
      border-bottom: none;
      &:not(.stiff)>div {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
        white-space: normal;
      }
    `,
    HeaderRow: `
      color: ${mode(colors.gray[8], colors.gray[5])(colorScheme)};
      border-bottom: 1px solid ${mode(colors.gray[2], colors.gray[2])(colorScheme)};
      background-color: ${mode(colors.gray[0], colors.gray[1])(colorScheme)};
    `,
    BaseRow: `
      color: ${mode(colors.gray[7], colors.gray[5])(colorScheme)};
      font-weight: 500;
      font-size: 13px;
      border-bottom: 1px solid ${mode(colors.gray[2], colors.gray[2])(colorScheme)};
    `,
    BaseCell: `
      padding: 6px 0;
      border-right: none;
      &:not(.stiff)>div {
        white-space: normal;
      }
    `,
    Table: `
      border: 1px solid ${mode(colors.gray[2], colors.gray[2])(colorScheme)};
      border-radius: 16px;
    `,
    Row: `
      &.row-select-selected, &.row-select-single-selected {
        font-weight: inherit;
        background-color: ${mode(colors.blue[1], colors.blue[1])(colorScheme)};
      }
    `,
  }

  return colorTheme
}