import React, {useState} from 'react'
import {Popover} from '@mantine/core'
import {MultiSelectPopover, SelectListPopoverProps} from './multi-select-popover'

interface SelectListProps<ID extends string> extends SelectListPopoverProps<ID> {
  button: JSX.Element,
}

export function MultiSelectList<ID extends string>({
  button,
  saveButton,
  ...props
}: SelectListProps<ID>) {
  const [opened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!opened)

  return (
    <Popover
      opened={opened}
      target={<div onClick={toggleOpened}>{button}</div>}
      onClose={() => setOpened(false)}
      spacing={0}
      position='bottom'
      className='flex items-center'
    >
      <MultiSelectPopover
        {...props}
        saveButton={{
          ...saveButton,
          onClick: ids => {
            saveButton?.onClick(ids)
            setOpened(false)
          },
        }}
      />
    </Popover>
  )
}