import {Logger} from './Logger'

function checkNever(value: never, msg = `Unhandled value: ${JSON.stringify(value)}`): void {
  return Logger.error(msg)
}

//TODO:WMLN-84 заменить isNonNullable на !isNullable
export function isNonNullable<T>(v: Nullable<T>): v is T {
  return v != null
}

export function isNullable<T>(v: Nullable<T>): v is undefined|null {
  return v == null
}

export function isLoading(state: DefaultLoadingState): boolean {
  return state === 'loading'
}

export function isSaving(state: DefaultLoadingState): boolean {
  return state === 'saving'
}

export {
  checkNever,
}