import React from 'react'
import {Button} from '@mantine/core'
import {useAppDispatch} from '../../../hooks/storeHooks'
import {openProcurementCreateOrderPopup} from '../../../store/procurement/create-order-popup/create-order-popup-reducer'
import {PlusCircleIcon} from '@heroicons/react/outline'

export function CreateOrderButton() {
  const dispatch = useAppDispatch()

  return (
    <Button
      leftIcon={<PlusCircleIcon className='w-5 h-5' />}
      onClick={() => dispatch(openProcurementCreateOrderPopup())}
    >
      Создать заказ
    </Button>
  )
}