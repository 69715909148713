import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {toPairs, values} from 'ramda'
import {CreateOrderPopupItem, CreateOrderPopupOrderFields} from './types'

type CreateOrderPopupRequiredOrderField = Extract<keyof CreateOrderPopupOrderFields,
  'storeId'|'orderTimestamp'|'countryId'|'providerId'|'currencyId'|'paymentMethodId'|'responsibleSCDId'>

export type CreateOrderPopupRequiredItemField = Extract<keyof CreateOrderPopupItem,
  |'catalogue'|'originalName'|'cost'|'shipmentTimestamp'>

export type CreateOrderPopupValidationOrderField = CreateOrderPopupRequiredOrderField
  |Extract<keyof CreateOrderPopupOrderFields, 'summaryOtherCost'>

export interface CreateOrderPopupErrorsState {
  validationEnabled: boolean,
  orderFields: Array<CreateOrderPopupValidationOrderField>
  items: TypedObject<string, Array<CreateOrderPopupRequiredItemField>>,
}

const initialState: CreateOrderPopupErrorsState = {
  validationEnabled: false,
  orderFields: [],
  items: {},
}

const createOrderPopupErrorsSlice = createSlice({
  name: 'createOrderPopupErrors',
  initialState,
  reducers: {
    setCreateOrderPopupValidationEnabled(state, { payload }: PayloadAction<boolean>) {
      if (payload) {
        return {...state, validationEnabled: true}
      }
      return initialState
    },
    addOrderFieldError(state, { payload }: PayloadAction<CreateOrderPopupValidationOrderField>) {
      !state.orderFields.includes(payload) && state.orderFields.push(payload)
    },
    resetOrderFieldError(state, { payload }: PayloadAction<CreateOrderPopupValidationOrderField>) {
      state.orderFields = state.orderFields.filter(x => x !== payload)
    },
    addOrderItemError(state, { payload }: PayloadAction<{id: string, field: CreateOrderPopupRequiredItemField}>) {
      const itemFields = state.items[payload.id]
      if (itemFields) {
        !itemFields.includes(payload.field) && itemFields.push(payload.field)
      }
      else {
        state.items[payload.id] = [payload.field]
      }
    },
    resetOrderItemError(state, { payload }: PayloadAction<{id: string, field: CreateOrderPopupRequiredItemField}>) {
      const itemFields = state.items[payload.id]
      if (itemFields) {
        const newArray = itemFields.filter(x => x !== payload.field)
        state.items[payload.id] = newArray.length > 0 ? newArray : undefined
      }
    },
    resetAllOrderItemsError(state, { payload }: PayloadAction<CreateOrderPopupRequiredItemField>) {
      const items = toPairs(state.items)
      items.forEach(([id, errorFields]) => {
        state.items[id] = errorFields && errorFields.filter(x => x !== payload)
      })
    },
    setCreateOrderPopupErrors(state, { payload }: PayloadAction<Partial<CreateOrderPopupErrorsState>>) {
      return {...state, ...payload }
    },
    resetCreateOrderPopupErrors() {
      return initialState
    },
  },
})

export function createOrderPopupHasErrors(state: CreateOrderPopupErrorsState) {
  return state.orderFields.length > 0 || values(state.items).some(x => x && x.length > 0)
}

export function getCreateOrderPopupValidationOrderFields(): Array<CreateOrderPopupValidationOrderField> {
  return ['storeId', 'orderTimestamp', 'countryId', 'providerId', 'currencyId', 'paymentMethodId', 'responsibleSCDId', 'summaryOtherCost']
}

export function getCreateOrderPopupRequiredItemFields(): Array<CreateOrderPopupRequiredItemField> {
  return ['catalogue', 'originalName', 'cost', 'shipmentTimestamp']
}

export const createOrderPopupErrorsReducer = createOrderPopupErrorsSlice.reducer

export const {
  addOrderFieldError,
  resetOrderFieldError,
  addOrderItemError,
  resetOrderItemError,
  setCreateOrderPopupErrors,
  resetCreateOrderPopupErrors,
  resetAllOrderItemsError,
  setCreateOrderPopupValidationEnabled,
} = createOrderPopupErrorsSlice.actions