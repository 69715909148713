import {NumberInput, Tooltip} from '@mantine/core'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {selectCreateOrderPopupItemField} from '../../../../../../../store/procurement/selectors'
import {numberInputFormatter, numberInputParser} from '../../../../../../../utils/number'
import {mode} from '../../../../../../../utils/theme'
import {CREATE_ORDER_POPUP_PRECISION} from '../../common/common'
import {useCreateOrderPopupContext} from '../../context'
import {CreateOrderPopupItemFieldProps} from './common'

export function TaxInputField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field = 'tax'
  const taxInfo = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const {updateItemField} = useCreateOrderPopupContext()
  const [focused, setFocused] = useState(false)

  return (
    <Tooltip label='Поле изменено вручную' disabled={focused || !taxInfo?.manually}>
      <NumberInput
        value={taxInfo?.value}
        onChange={num => {
          const manually = taxInfo?.manually || taxInfo?.value !== num
          const value = num === undefined
            ? null
            : { value: num, manually }
          updateItemField(itemId, field, value)
        }}
        parser={numberInputParser}
        formatter={numberInputFormatter}
        hideControls
        precision={CREATE_ORDER_POPUP_PRECISION}
        placeholder='0.00'
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        sx={({colors, colorScheme}) => ({
          'input': {
            borderColor: taxInfo?.manually ? mode(colors.cyan[5], colors.cyan[8])(colorScheme) : undefined,
          },
        })}
      />
    </Tooltip>
  )
}
