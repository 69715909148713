import React, {useEffect} from 'react'
import {Col, Row, Tabs} from 'antd'
import {SearchContent} from '../../../content/search'
import {BasketContent} from '../../../content/basket'
import {GenerateTableContent} from '../../../content/generate-table'
import {setEmailAction} from '../../../store/auth/reducer'
import {useDispatch} from 'react-redux'
import {HandleInputBasketContent} from '../../../content/handle-input-basket'
import {SideHandleEditorContent} from '../../../content/side-handle-editor'
import StickyBox from 'react-sticky-box'

const { TabPane } = Tabs

export const ProposalView: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setEmailAction(localStorage.getItem('appSceienceEmail')!))
  }, [dispatch])

  return (
    <Row className='pt-3'>
      <Col span={24} className='pr-2 pl-2' xl={12}>
        <Tabs type='card' defaultActiveKey='1' >
          <TabPane tab='Поиск по каталогу' key='1'>
            <SearchContent/>
          </TabPane>
          <TabPane tab='Ручной ввод' key='2'>
            <HandleInputBasketContent/>
          </TabPane>
        </Tabs>
      </Col>
      <Col span={24} className='pr-2 pl-2 mt-4' xl={12}>
        <StickyBox offsetTop={20} offsetBottom={20}>
          <BasketContent/>
          <GenerateTableContent/>
        </StickyBox>
      </Col>
      <SideHandleEditorContent/>
    </Row>
  )
}
