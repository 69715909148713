import {TrashIcon} from '@heroicons/react/outline'
import React, {memo} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../../hooks/storeHooks'
import {
  removeCreateOrderPopupItem,
} from '../../../../../../store/procurement/create-order-popup/create-order-popup-reducer'
import {
  getCreateOrderPopupRequiredItemFields,
} from '../../../../../../store/procurement/create-order-popup/errors-reducer'
import {CreateOrderPopupItem} from '../../../../../../store/procurement/create-order-popup/types'
import {
  selectCreateOrderPopupErrorsState,
  selectProcurementCreateOrderPopupItems,
} from '../../../../../../store/procurement/selectors'
import {joinStrings} from '../../../../../../utils/string'
import {CreateOrderPopupFieldLabel} from '../common/label'
import {
  GRID_TEMPLATE_COLUMNS,
  getCreateOrderPopupColumnsInfo,
} from './columns'
import {
  ClientPriceField,
  CostInputField,
  InvoiceNameField,
  QuantityField,
  ShipmentDatePickerField,
  TextInputField,
} from './fields/common'
import {OtherCostInputField} from './fields/other-cost-input-field'
import {BrandSelectField} from './fields/brand-select-field'
import {TaxInputField} from './fields/tax-imput-field'

export function CreateOrderPopupItemsSection() {
  const items = useSelector(selectProcurementCreateOrderPopupItems)
  const columnsInfo = getCreateOrderPopupColumnsInfo()
  const requiredFields: Set<keyof CreateOrderPopupItem> = new Set(getCreateOrderPopupRequiredItemFields())
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))

  return (
    <div>
      <div className='grid gap-x-4 items-center' style={{ gridTemplateColumns: GRID_TEMPLATE_COLUMNS }}>
        {columnsInfo.map(x => <CreateOrderPopupFieldLabel
          key={x.id}
          label={x.title}
          required={validationEnabled && requiredFields.has(x.id)}
        />)}
      </div>
      {items.map(x => <Item key={x.id} id={x.id} />)}
    </div>
  )
}

interface ItemProps {
  id: string,
}

const Item = memo(({ id }: ItemProps) => {
  const dispatch = useAppDispatch()

  return (
    <div className='grid gap-x-4 py-3 border-b last:border-none' style={{ gridTemplateColumns: GRID_TEMPLATE_COLUMNS }}>
      <BrandSelectField itemId={id} />
      <TextInputField itemId={id} field='catalogue' />
      <TextInputField itemId={id} field='originalName' />
      <CostInputField itemId={id} />
      <TaxInputField itemId={id} />
      <OtherCostInputField itemId={id} />
      <QuantityField itemId={id} />
      <InvoiceNameField itemId={id} />
      <ShipmentDatePickerField itemId={id} />
      <ClientPriceField itemId={id} />
      <TrashIcon
        onClick={() => dispatch(removeCreateOrderPopupItem(id))}
        className={joinStrings(
          'w-5 h-5 text-gray-300 self-center',
          'hover:text-gray-400 duration-200 cursor-pointer',
        )}
      />
    </div>
  )
})
Item.displayName = 'Item'