import {NumberInput} from '@mantine/core'
import React from 'react'
import {getEditItemPopupRequiredFieldsSet} from '../index'
import {
  CommonFieldProps,
  ProcurementEditItemPopupFormLabel, getProcurementEditItemPopupInputSxStyles, getProcurementTableColumnTitle,
} from './common'

interface Props extends CommonFieldProps {
  field: 'quantity',
}

export function EditItemPopupNumberInput({
  field,
  form,
}: Props) {
  return <NumberInput
    sx={getProcurementEditItemPopupInputSxStyles}
    label={<ProcurementEditItemPopupFormLabel
      label={getProcurementTableColumnTitle(field)}
      required={getEditItemPopupRequiredFieldsSet().has(field)}
    />}
    hideControls
    {...form.getInputProps(field)}
  />
}