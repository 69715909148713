import {XIcon} from '@heroicons/react/outline'
import {Button, Paper} from '@mantine/core'
import React from 'react'
import {joinStrings} from '../utils/string'
import {ExternalLayer} from './layers/ExternalLayer'

interface ButtonProps {
  onClick: () => void,
  text?: string,
  disabled?: boolean,
}

interface DefaultButtonsProps {
  success: ButtonProps,
  cancel: ButtonProps,
}

interface PopupDefaultProps extends PopupDefaultContent {
  show: boolean,
  onOverlayClick?: () => void,
}

/**
 * @description Если модальное окно должно открывать ещё одно, то второе окно должно быть обязательно вложено в первое
 */
export const PopupDefault = React.memo(({
  show,
  onOverlayClick,
  ...contentProps
}: PopupDefaultProps) => (
  <ExternalLayer
    show={show}
    createContent={() => <PopupDefaultContent {...contentProps} />}
    onOverlayClick={onOverlayClick}
  />
))

type PopupDefaultContent = {
  title?: string,
  createContent: () => JSX.Element,
  buttonsInfo: DefaultButtonsProps | Array<JSX.Element>,
  className?: string,
  closeFn?: () => void,
}

function PopupDefaultContent({
  title,
  createContent,
  buttonsInfo,
  className,
  closeFn,
}: PopupDefaultContent) {
  const showHeader = !!title || !!closeFn
  const buttons = Array.isArray(buttonsInfo) ? buttonsInfo : getPopupDefaultButtons(buttonsInfo)

  return (
    <Paper className={joinStrings(
      'flex flex-col p-6 my-8 text-left align-middle transition-all transform shadow-xl rounded-xl',
      className,
    )}>
      {showHeader &&
        <div className='flex items-center mb-4'>
          {!!title && <h3 className='text-2xl font-bold leading-6 m-0'>{title}</h3>}
          {!!closeFn && <XIcon onClick={closeFn} className={joinStrings(
            'w-6 h-6 ml-auto mt-0.5',
            'duration-300 cursor-pointer',
          )}/>}
        </div>
      }
      {createContent()}
      {!!buttons.length && <div className='flex justify-end mt-4 space-x-2'>{buttons}</div>}
    </Paper>
  )
}

export function getPopupDefaultButtons({ success, cancel }: DefaultButtonsProps) {
  return [
    <Button key='cancel' className='font-semibold' variant='outline' onClick={cancel.onClick} disabled={cancel.disabled}>
      {cancel.text || 'Отменить'}
    </Button>,
    <Button key='success' className='font-semibold' variant='filled' onClick={success.onClick} disabled={success.disabled}>
      {success.text || 'Сохранить'}
    </Button>,
  ]
}