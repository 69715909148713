import {generateAMOUrl} from '../helpers'

interface AmoIdCellProps {
	id: string,
}

export function AmoIdCell({
  id,
}: AmoIdCellProps) {

  return <a href={generateAMOUrl(id)} target='_blank' className='amo-id-cell'>
    {id}
  </a>
}