import {OrderStatusId} from '../../store/procurement/create-order-popup/types'
import {ProcurementItem} from '../../store/procurement/reducer'
import {checkNever} from '../../utils/check'
import {parseStringDateToTimestamp} from '../../utils/date'
import {Logger} from '../../utils/Logger'
import {isNumber} from '../../utils/number'
import {OrderStatusesResponseData, ProcurementTableResponseData} from './procurement'

export function remapToProcurementItem(data: ProcurementTableResponseData): ProcurementItem {
  const quantityIsValid = isNumber(data.quantity)
  const quantity = quantityIsValid ? Number(data.quantity) : 0
  if (!quantityIsValid) {
    Logger.error(`Quantity is not a number. Value: ${data.quantity}`)
  }

  return {
    id: data.id,
    invoiceTimestamp: parseStringDateToTimestamp(data.date),
    deadlineTimestamp: parseStringDateToTimestamp(data.deadline_date),
    invoiceName: data.name,
    originalName: data.original_name,
    amoId: data.deal_id,
    clientName: data.client_name,
    responsibleProcurement: data.procurement,
    clientPrice: data.final_price,
    supposedStore: data.store,
    brand: data.brand,
    purchaseTimestamp: parseStringDateToTimestamp(data.proc_deadline_date),
    country: data.country,
    catalogueId: data.catalogue_id,
    quantity,
    proposalComment: data.proposal_comments,
  }
}

export function remapToTitleWithId<ID extends string>(data: TitleWithId): TitleWithId<ID> {
  return {
    id: data.id as ID,
    title: data.title,
  }
}

export function remapToOrderStatus(data: OrderStatusesResponseData): TitleWithId<OrderStatusId> {
  return {
    id: checkOrderStatusId(data.id),
    title: data.name,
  }
}

function checkOrderStatusId(id: OrderStatusId): OrderStatusId {
  switch (id) {
    case 'in_progress':
    case 'po_submitted':
    case 'store_handling':
    case 'under_tracking':
    case 'waiting_prepaid':
      return id
    default:
      checkNever(id, `Unknown order status. Value: ${id}`)
      throw Error
  }
}