import {NumberInput, TextInput} from '@mantine/core'
import {DatePicker} from '@mantine/dates'
import {getTime, toDate} from 'date-fns'
import React from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../../../hooks/storeHooks'
import {
  CreateOrderPopupRequiredItemField,
  addOrderItemError,
  resetOrderItemError,
} from '../../../../../../../store/procurement/create-order-popup/errors-reducer'
import {
  selectCreateOrderPopupErrorsState,
  selectCreateOrderPopupItemField,
  selectCreateOrderPopupItemFieldError,
} from '../../../../../../../store/procurement/selectors'
import {TextWithEllipsis} from '../../../../../../../uikit/TextWithEllipsis'
import {isNonNullable} from '../../../../../../../utils/check'
import {numberInputFormatter, numberInputParser} from '../../../../../../../utils/number'
import {generateAMOUrl} from '../../../../helpers'
import {CREATE_ORDER_POPUP_PRECISION, getCommonFieldClassName} from '../../common/common'
import {useCreateOrderPopupContext} from '../../context'

export interface CreateOrderPopupItemFieldProps {
  itemId: string,
}

interface TextInputFieldProps extends CreateOrderPopupItemFieldProps {
  field: 'catalogue'|'originalName'
}

export function TextInputField({
  itemId,
  field,
}: TextInputFieldProps) {
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupItemFieldError(itemId, field))
  const selected = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateItemField} = useCreateOrderPopupContext()

  return <TextInput
    value={selected || ''}
    onChange={event => {
      const value = event.currentTarget.value
      const obj = {id: itemId, field}
      validationEnabled && dispatch(value ? resetOrderItemError(obj) : addOrderItemError(obj))
      updateItemField(itemId, field, value)
    }}
    className={getCommonFieldClassName()}
    error={hasError}
  />
}


export function CostInputField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field: CreateOrderPopupRequiredItemField = 'cost'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupItemFieldError(itemId, field))
  const selected = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateItemField} = useCreateOrderPopupContext()

  return <NumberInput
    value={selected || undefined}
    onChange={num => {
      const obj = {id: itemId, field}
      validationEnabled && dispatch(isNonNullable(num) ? resetOrderItemError(obj) : addOrderItemError(obj))
      updateItemField(itemId, field, num === undefined ? null : num)
    }}
    parser={numberInputParser}
    formatter={numberInputFormatter}
    hideControls
    precision={CREATE_ORDER_POPUP_PRECISION}
    placeholder='0.00'
    error={hasError}
  />
}

export function QuantityField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const value = useSelector(selectCreateOrderPopupItemField(itemId, 'quantity'))
  return (
    <div className='flex items-center overflow-hidden'>
      <TextWithEllipsis text={numberInputFormatter(value.toString())} />
    </div>
  )
}

export function ClientPriceField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const value = useSelector(selectCreateOrderPopupItemField(itemId, 'clientPrice'))
  return (
    <div className='flex items-center overflow-hidden'>
      <TextWithEllipsis text={numberInputFormatter(value.toFixed(CREATE_ORDER_POPUP_PRECISION))} />
    </div>
  )
}

export function InvoiceNameField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field = 'invoiceName'
  const value = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const amoId = useSelector(selectCreateOrderPopupItemField(itemId, 'amoId'))

  return (
    <a className='flex items-center overflow-hidden underline underline-offset-1' href={generateAMOUrl(amoId)} target='_blank'>
      <TextWithEllipsis text={value} />
    </a>
  )
}


export function ShipmentDatePickerField({
  itemId,
}: CreateOrderPopupItemFieldProps) {
  const field: CreateOrderPopupRequiredItemField = 'shipmentTimestamp'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupItemFieldError(itemId, field))
  const selected = useSelector(selectCreateOrderPopupItemField(itemId, field))
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateItemField} = useCreateOrderPopupContext()

  return <DatePicker
    value={isNonNullable(selected) ? toDate(selected) : undefined}
    onChange={date => {
      const obj = {id: itemId, field}
      validationEnabled && dispatch(isNonNullable(date) ? resetOrderItemError(obj) : addOrderItemError(obj))
      updateItemField(itemId, 'shipmentTimestamp', date && getTime(date))
    }}
    inputFormat='DD.MM.YYYY'
    error={hasError}
  />
}