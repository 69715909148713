import {ActionIcon} from '@mantine/core'
import {ActionIconProps} from '@mantine/core/lib/components/ActionIcon/ActionIcon'
import {joinStrings} from '../utils/string'

export function TransparentActionIcon({
  radius,
  size,
  className,
  children,
  ...props
}: ActionIconProps<'button'>) {
  return <ActionIcon
    radius={radius || 0}
    size={size || 'lg'}
    className={joinStrings('duration-150 hover:bg-transparent', className)}
    {...props}
  >
    {children}
  </ActionIcon>
}