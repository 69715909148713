import React, {createContext, useContext, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {getApi} from '../../../../../api/getApi'
import {LoaderContainer} from '../../../../../components/loader-container'
import {useAppDispatch} from '../../../../../hooks/storeHooks'
import {setProcurementEditableItem} from '../../../../../store/procurement/reducer'
import {selectProcurementTableState} from '../../../../../store/procurement/selectors'
import {PopupDefault} from '../../../../../uikit/PopupDefault'
import {EditItemPopupContent} from './content'

export interface EditItemPopupFormState {
  invoiceTimestamp: string,
  deadlineTimestamp: string,
  clientName: string,
  responsibleProcurement: string,
  amoId: string,
  clientPrice: string,
  invoiceName: string,
  supposedStore?: string,
  brand?: string,
  purchaseTimestamp: Date | null,
  country?: string,
  catalogueId: string,
  quantity: number,
  procurementComment: string,
}

export type EditItemPopupField = Exclude<keyof EditItemPopupFormState, 'initialized'>

export interface ProcurementEditItemPopupStaticData {
  brands: Array<TitleWithId>,
  countries: Array<TitleWithId>,
  stores: Array<TitleWithId>,
}

interface ContextData {
  staticData: ProcurementEditItemPopupStaticData,
  initValuesRef: React.MutableRefObject<EditItemPopupFormState | undefined>,
  closeFn: () => void,
}

const ProcurementEditItemPopupContext = createContext<ContextData>({} as ContextData)

export function useProcurementEditItemPopupContext() {
  return useContext(ProcurementEditItemPopupContext)
}

export function ProcurementEditItemPopup() {
  const dispatch = useAppDispatch()
  const item = useSelector(selectProcurementTableState('editableItem'))
  const [staticData, setStaticData] = useState<null|ProcurementEditItemPopupStaticData>(null)
  const initValuesRef = useRef<EditItemPopupFormState>()

  useEffect(() => {
    if (item) {
      getStaticData().then(setStaticData)
    }
  }, [item])

  const closeFn = () => {
    dispatch(setProcurementEditableItem(null))
    setStaticData(null)
  }

  function getContent(): JSX.Element {
    if (staticData === null || item === null) {
      return <LoaderContainer />
    }
    return (
      <ProcurementEditItemPopupContext.Provider value={{closeFn, staticData, initValuesRef}}>
        <EditItemPopupContent item={item} />
      </ProcurementEditItemPopupContext.Provider>
    )
  }

  return <PopupDefault
    title='Information item'
    show={!!item}
    createContent={() => getContent()}
    buttonsInfo={[]}
    closeFn={closeFn}
    className='w-[656px] min-h-[735px]'
  />
}

export function getEditItemPopupRequiredFieldsSet(): Set<EditItemPopupField> {
  return new Set<EditItemPopupField>(['brand', 'supposedStore', 'purchaseTimestamp', 'country', 'quantity', 'catalogueId'])
}

async function getStaticData(): Promise<ProcurementEditItemPopupStaticData> {
  const api = getApi().procurement
  const [brands, countries, stores] = await Promise.all([
    api.getBrands(),
    api.getCountries(),
    api.getStores(),
  ])
  return {
    brands,
    countries,
    stores,
  }
}
