import {NumberInput, Select, TextInput, Tooltip} from '@mantine/core'
import {isEmpty} from 'ramda'
import React, {useCallback, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../../hooks/storeHooks'
import {
  addOrderFieldError,
  resetOrderFieldError,
} from '../../../../../../store/procurement/create-order-popup/errors-reducer'
import {
  selectCreateOrderPopupErrorsState,
  selectCreateOrderPopupField, selectCreateOrderPopupFieldError,
  selectCreateOrderPopupStaticDataItem,
} from '../../../../../../store/procurement/selectors'
import {calcSumExpression, numberInputFormatter, numberInputParser} from '../../../../../../utils/number'
import {joinStrings} from '../../../../../../utils/string'
import {CREATE_ORDER_POPUP_PRECISION, getCommonFieldClassName, mapToSelectItem} from '../common/common'
import {CreateOrderPopupFieldLabel} from '../common/label'
import {useCreateOrderPopupContext} from '../context'

export function OrderFieldsSectionSecondLine() {
  return (
    <div className='flex space-x-4'>
      <PaymentMethodSelect />
      <SummaryTaxInput />
      <SummaryOtherCostInput />
      <ResponsibleSCDSelect />
      <ResponsibleProcurementSelect />
      <OrderInvoiceInput />
    </div>
  )
}

function PaymentMethodSelect() {
  const field = 'paymentMethodId'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selected = useSelector(selectCreateOrderPopupField(field))
  const methods = useSelector(selectCreateOrderPopupStaticDataItem('paymentMethods'))
  const options = useMemo(() => methods.map(mapToSelectItem), [methods])
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Способ оплаты' />}
      placeholder='Способ оплаты'
      data={options}
      value={selected}
      onChange={id => {
        dispatch(id ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, id)
      }}
      required={validationEnabled}
      className={joinStrings(getCommonFieldClassName(), 'w-[294px] shrink-0')}
      error={hasError}
    />
  )
}

function SummaryTaxInput() {
  const field = 'summaryTax'
  const value = useSelector(selectCreateOrderPopupField(field))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <NumberInput
      label={<CreateOrderPopupFieldLabel label='Tax' />}
      value={value || undefined}
      onChange={num => updateOrderFiled(field, num === undefined ? null : num)}
      className={joinStrings(getCommonFieldClassName(), 'w-[100px] shrink-0')}
      parser={numberInputParser}
      formatter={numberInputFormatter}
      hideControls
      precision={CREATE_ORDER_POPUP_PRECISION}
    />
  )
}

function SummaryOtherCostInput() {
  const field = 'summaryOtherCost'
  const dispatch = useAppDispatch()
  const [focused, setFocused] = useState(false)
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const value = useSelector(selectCreateOrderPopupField(field))
  const visibleValue = focused || hasError || isEmpty(value) ? value : numberInputFormatter(calcSumExpression(value).toString())
  const {updateOrderFiled} = useCreateOrderPopupContext()

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const interimNum = '(?:[0-9]+[,.])?[0-9]*'
    const interimRegexp = new RegExp(`^${interimNum}(?:\\+${interimNum})*$`)

    const formula = event.currentTarget.value
    if (isEmpty(formula)) {
      updateOrderFiled(field, '')
    }
    if (interimRegexp.test(formula)) {
      updateOrderFiled(field, formula)
    }
  }, [updateOrderFiled])
  const onBlur = () => {
    setFocused(false)
    if (isEmpty(value)) {
      return
    }
    const floatNumRegexp = '(?:[0-9]+[,.])?[0-9]+'
    const resultRegexp = new RegExp(`^${floatNumRegexp}(?:\\+${floatNumRegexp})*$`)
    updateOrderFiled(field, value.replaceAll(/,/g, '.'))
    if (!resultRegexp.test(value)) {
      dispatch(addOrderFieldError(field))
    }
  }

  return (
    <Tooltip label={value.split('+').map(numberInputFormatter).join('+')} disabled={focused || hasError || isEmpty(value)} position='bottom'>
      <TextInput
        label={<CreateOrderPopupFieldLabel label='Other cost' />}
        value={visibleValue}
        onChange={onChange}
        className={joinStrings(getCommonFieldClassName(), 'w-[100px] shrink-0')}
        onFocus={() => {
          dispatch(resetOrderFieldError(field))
          setFocused(true)
        }}
        onBlur={onBlur}
        error={hasError}
      />
    </Tooltip>
  )
}

function ResponsibleSCDSelect() {
  const field = 'responsibleSCDId'
  const dispatch = useAppDispatch()
  const selected = useSelector(selectCreateOrderPopupField(field))
  const SCDs = useSelector(selectCreateOrderPopupStaticDataItem('responsibleSCDs'))
  const data = useMemo(() => SCDs.map(mapToSelectItem), [SCDs])
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Ответственный SCD' />}
      placeholder='SCD'
      onChange={id => {
        dispatch(id ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, id)
      }}
      value={selected}
      data={data}
      className={getCommonFieldClassName()}
      error={hasError}
      required={validationEnabled}
    />
  )
}

function ResponsibleProcurementSelect() {
  const field = 'responsibleProcurementId'
  const selected = useSelector(selectCreateOrderPopupField(field))
  const procurements = useSelector(selectCreateOrderPopupStaticDataItem('responsibleProcurements'))
  const data = useMemo(() => procurements.map(mapToSelectItem), [procurements])
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Ответственный procurement' />}
      placeholder='Procurement'
      onChange={id => {
        updateOrderFiled(field, id)
      }}
      value={selected}
      data={data}
      className={getCommonFieldClassName()}
    />
  )
}

function OrderInvoiceInput() {
  const {updateOrderFiled} = useCreateOrderPopupContext()
  const value = useSelector(selectCreateOrderPopupField('orderInvoice'))

  return (
    <TextInput
      label={<CreateOrderPopupFieldLabel label='ORDER/INVOICE' />}
      value={value}
      onChange={event => updateOrderFiled('orderInvoice', event.currentTarget.value)}
      className={getCommonFieldClassName()}
    />
  )
}