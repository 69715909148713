import {ButtonStylesParams, MantineTheme} from '@mantine/core'
import {CSSObject} from '@mantine/styles/lib/tss'

export function getButtonStylesConfig(theme: MantineTheme, props: Record<string, any>): Record<string, CSSObject> {
  const params = props as ButtonStylesParams
  
  return {
    filled: {
      backgroundColor: theme.colorScheme === 'light' ? theme.colors.blue[6] : theme.colors.blue[7],
    },
  }
}