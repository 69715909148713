import {LinesEllipsisWithTooltip} from '../../../../components/lines-ellipsis-with-tooltip/lines-ellipsis-with-tooltip'

interface InvoiceDateCellProps {
	text: string,
  maxLines?: number,
}

export function TextCell({
  text,
  maxLines = 1,
}: InvoiceDateCellProps) {
  return <LinesEllipsisWithTooltip text={text} maxLines={maxLines} />
}