import {CSSObject, MantineTheme, SelectProps} from '@mantine/core'

export function getMultiSelectStylesConfig(theme: MantineTheme, props: Record<string, any>): Record<string, CSSObject> {
  const params = props as SelectProps
  return {
    root: {
      input: {
        margin: '3px 5px',
      }
    }
  }
}