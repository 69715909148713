import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AnyType} from '../../../types'
import {getApi} from '../../api/getApi'
import {selectBasketProducts} from '../../store/basket/selectors'
import {useQuery} from '../../hooks/useQuery'
import {ProductBasketType} from '../../store/basket/types'
import {
  setEditedItemIndex,
  setInitialStateAction,
  setOpenHandleEditor,
  setProductsListAction,
} from '../../store/basket/reducer'
import {
  setDisabledOrderFormAction,
  setDocLinkAction,
  setLinkToTableAction,
  setOrderValueAction,
  setPdfLinkAction,
} from '../../store/generate-table/reducer'
import {notification} from 'antd'

export const useBasket = () => {
  const dispatch = useDispatch()
  const basketProducts = useSelector(selectBasketProducts)
  const [loadingBasket, setLoadingBasket] = useState<boolean>(true)
  const [isVisibleLoader, _setIsVisibleLoader] = useState<boolean>(true)
  const query = useQuery()

  //
  //Set is visible Loader
  const setIsVisibleLoader = () => {
    _setIsVisibleLoader(isVisibleLoader)
  }

  //
  //Initial Basket Status
  const setInitialBasketStatus = () => {
    setLoadingBasket(false)
    setIsVisibleLoader()
    const number = query.get('number') ? query.get('number') : ''
    if (number !== localStorage.getItem('orderNumber')) {
      dispatch(setInitialStateAction([]))
      localStorage.setItem('basketAppscience', '')
      localStorage.setItem('orderNumber', number!)
      localStorage.removeItem('linkToTable')
      localStorage.removeItem('pdfLink')
      localStorage.removeItem('docLink')
    }
    const data = localStorage.getItem('basketAppscience')
    if (data) {
      const info = JSON.parse(localStorage.getItem('basketAppscience')!)
      dispatch(setInitialStateAction(info))
    }
    else {
      dispatch(setInitialStateAction([]))
    }
  }

  //
  //Initial Generate Table State
  const initTableInfoState = () => {
    const urlNumber = query.get('number')
    if (urlNumber) {
      dispatch(setDisabledOrderFormAction(true))
      dispatch(setOrderValueAction(urlNumber))
    }
    const _linkToTable = localStorage.getItem('linkToTable')
    const _pdfLink = localStorage.getItem('pdfLink')
    const _docLink = localStorage.getItem('docLink')
    _linkToTable && dispatch(setLinkToTableAction(_linkToTable))
    _pdfLink && dispatch(setPdfLinkAction(_pdfLink))
    _docLink && dispatch(setDocLinkAction(_docLink))
  }

  //Init Generate And Basket State
  const initState = () => {
    const urlNumber = query.get('number')
    if (urlNumber) {
      dispatch(setOrderValueAction(urlNumber))
      dispatch(setDisabledOrderFormAction(true))
      setIsVisibleLoader()
      getApi().proposal
        .getProposalData({id: urlNumber})
        .then(({data}) => {
          setDataInStore(data)
        }).catch(e => {
          notification.error({
            message: e.response,
          })
        }).finally(() => {
          setLoadingBasket(false)
        })
    }
    else {
      setInitialBasketStatus()
      initTableInfoState()
    }
  }

  //Set Data in Store
  const setDataInStore = (data: AnyType) => {
    const {
      items,
      proposal_pdf_url,
      proposal_doc_url,
      spreadsheet_url,
    } = data

    localStorage.setItem('basketAppscience', JSON.stringify(items ? items : []))
    dispatch(setProductsListAction(items))
    dispatch(setDocLinkAction(proposal_doc_url || ''))
    dispatch(setPdfLinkAction(proposal_pdf_url || ''))
    dispatch(setLinkToTableAction(spreadsheet_url || ''))
  }

  //onCountHandler
  const onCountHandler = (value: number, itemIndex: number) => {
    if (value === 0) return
    const data: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
    if (value === -1) {
      data.splice(itemIndex, 1)
      localStorage.setItem('basketAppscience', JSON.stringify(data))
      dispatch(setProductsListAction([...data]))

    }
    else {
      data[itemIndex].count = value
      localStorage.setItem('basketAppscience', JSON.stringify(data))
      dispatch(setProductsListAction([...data]))
    }
  }

  const onStartEditHandler = (index: number) => {
    dispatch(setOpenHandleEditor(true))
    dispatch(setEditedItemIndex(index))
  }

  return {
    //props
    basketProducts,
    loadingBasket,
    isVisibleLoader,

    //methods
    setInitialBasketStatus,
    onCountHandler,
    initTableInfoState,
    initState,
    setIsVisibleLoader,
    onStartEditHandler,
  }
}
