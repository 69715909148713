import React, {useState} from 'react'
import {orderBy} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {getApi} from '../../api/getApi'
import {selectAnalogs, selectProducts} from '../../store/search/selectors'
import {setAnalogsList, setProductsList} from '../../store/search/reducer'
import {ProductType} from '../../store/search/types'
import {setProductsListAction, setAddToBtnDisable} from '../../store/basket/reducer'
import {ProductBasketType} from '../../store/basket/types'
import apiCreator from '../../api/apiCreator'
import {SelectProps} from 'antd/es/select'
import {SearchLabel} from './search-label'
import axios from 'axios'
import {selectTheme} from '../../store/theme/selectors'
import {
  ResponseDataArrayType,
  SearchParamsType,
  axiosSearchRequestData,
  axiosSuggestRequestData,
} from './search.type'
import {notificationCount, notificationError, notificationSuccess} from './search.notofications'
import {isFilteredWord} from './search.utils'
import {getProductById} from '../../api/get-product-by-id/get-product-by-id'
import {notification} from 'antd'

//Search Hook
export const useSearchContentHook = () => {
  //Disaptch
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  //Props
  const [searchProp, setSearchProp] = useState<string>('any')
  const [searchWord, _setSearchWord] = useState<string | number>('')
  const [vendor, setVendor] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [filteredWord, _setFilteredWord] = useState<string>('')
  const [searched, setSearched] = useState<boolean>(false)
  const [paginationCurrent, setPaginationCurrent] = useState<number>(1)
  const [vendorsList, setVendorsList] = useState<string[]>([])
  const [searchVendorsList, setSearchVendorsList] = useState<string[]>([])
  // @ts-ignore
  const [requestSearchResult, setRequestSearchResult] = useState<string[]>([])// Неправильный тип данных
  const [visibleLoader, setVisibleLoader] = useState<boolean>(false)
  const [loadingSearchByWord, setLoadingSearchByWord] = useState<boolean>(true)
  const [searchOptions, setSearchOptions] = useState<SelectProps<object>['options']>([])
  const [requestSearchWord, setRequestSearchWord] = useState<string>('')
  const [visibleDropdown, _setVisibleDropdown] = useState<boolean>(false)
  //Analogs Props
  const [analogsCasId, setAnalogsCasId] = useState<string>('')
  const [analogsLoading, setAnalogsLoading] = useState<boolean>(false)
  const [currentShowAnalogsItems, setCurrentShowAnalogsItems] = useState<number>(10)
  const [paginationAnalogsCurrent, setPaginatioAnalogsCurrent] = useState<number>(1)
  const [totalAnalogsPagination, setTotalAnalogsPagination] = useState<number>(0)
  const [sortingProp, setSortingProp] = useState<string>('')
  const [isReverseSorting, setIsReverseSorting] = useState<boolean>(false)
  //
  //Products
  const products = useSelector(selectProducts)
  const analogs = useSelector(selectAnalogs)
  //
  //Prepare Vendors Search param
  const searchVendorParam = () => {
    let param = ''
    searchVendorsList.length && searchVendorsList.forEach((item: string, key) => {
      key === 0 ? param = vendorsList[parseInt(item, 10)] : param = `${param},${vendorsList[parseInt(item, 10)]}`
    })
    return param
  }
  //
  //Search Word
  const setSearchWord = (value: string) => {
    if (!value.trim()) {
      return
    }
    _setSearchWord(value)
    setPaginatioAnalogsCurrent(1)
  }
  //
  const setFilteredWord = (value: string) => {
    const trimString: string = `${value}`.trim().toUpperCase()
    const _length = analogs.filter(item => isFilteredWord(item, trimString)).length
    setTotalAnalogsPagination(_length)
    setPaginatioAnalogsCurrent(1)
    _setFilteredWord(value)
  }
  //Search Handler
  const onSearchHandler = (value?: string) => {
    axiosSearchRequestData.source.cancel()
    //
    //Set Visible Dropdown
    _setVisibleDropdown(false)
    //
    //Axios Cancel Func
    axiosSearchRequestData.cancelToken = axios.CancelToken
    axiosSearchRequestData.source = axiosSuggestRequestData.cancelToken.source()
    //
    //Check Empty String
    if (!`${requestSearchWord}`.trim()) {
      return
    }
    //Revers sorting reset
    setSortingProp('')
    setIsReverseSorting(false)
    //Search Params
    const searchParams: SearchParamsType = {}
    const vendorParam = searchVendorParam()
    vendorParam && (searchParams.vendor = vendorParam)
    searchParams[searchProp as keyof typeof searchParams] = value ? value : requestSearchWord as string
    setSearchLoading(true)
    setFilteredWord('')
    setPaginationCurrent(1)
    setPaginatioAnalogsCurrent(1)
    setTotalAnalogsPagination(0)
    dispatch(setAnalogsList([]))

    //Request and Params
    apiCreator.get('https://api.appscience.ru/partner-products/request', {
      params: {...searchParams},
      cancelToken: axiosSearchRequestData.source.token,
    }).then(({data}) => {
      dispatch(setProductsList(data.partner_products))
      const cas_id = onFindCasId(data.partner_products)
      setAnalogsCasId(cas_id ? cas_id : '')
      onSearchAnalogsHandler(cas_id)
    }).catch(e => {
      notificationError('Ошибка загрузки')
      dispatch(setProductsList([]))
      dispatch(setAnalogsList([]))
    }).finally(() => {
      setSearchLoading(false)
      setSearched(true)
    })
  }

  //Searcn Analogs Handler
  const onSearchAnalogsHandler = (cas_id?: string) => {
    if (!cas_id || cas_id === 'n/a') {
      dispatch(setAnalogsList([]))
      return
    }
    setAnalogsLoading(true)
    //@ts-ignore
    getApi().casId.get(cas_id).then(({data}) => {
      const array = data.partner_products
      dispatch(setAnalogsList(array))
      setTotalAnalogsPagination(array.length)
    }).catch(e => {
      notificationError('Ошибка загрузки')
      dispatch(setAnalogsList([]))
    }).finally(() => {
      setAnalogsLoading(false)
    })
  }
  //
  //Find Cas_id
  const onFindCasId = (_products: ProductType[]): string | undefined => (_products.length ? _products[0].cas_id : undefined)
  //
  //Select Handler
  const onSelectHandler = (value: string) => {
    setVendor(value)
  }
  //
  //Set Object Local Storage
  const setObjectToLocalStorage = (object: ProductType) => {
    const data = localStorage.getItem('basketAppscience')
    // eslint-disable-next-line
    if (!data) {
      const dataArray = []
      dataArray.push({...object, count: 1})
      localStorage.setItem('basketAppscience', JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]))
    }
    else {
      const dataArray: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
      let alreadyObjectInLocal = false
      dataArray.forEach((item, index) => {
        if (object.id === item.id) {
          // eslint-disable-next-line
          dataArray[index].count = dataArray[index].count + 1
          alreadyObjectInLocal = true
        }
      })
      //
      if (alreadyObjectInLocal) {
        notificationCount(`+1 товар ${object.name}`)
      }
      else {
        dataArray.push({...object, count: 1})
        notificationSuccess(`${object.name} добавлен в корзину`)
      }
      localStorage.setItem('basketAppscience', JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]))
    }
  }

  let totalSize

  //Filtered Array
  const filteredArray = (() => {
    const startIndex: number = (paginationCurrent - 1) * 10
    const endIndex: number = paginationCurrent * 10
    //
    if (`${filteredWord}`.trim()) {
      const trimString: string = `${filteredWord}`.trim().toUpperCase()
      const _filteredArray = products.filter(item => isFilteredWord(item, trimString))
      totalSize = _filteredArray.length
      return _filteredArray.slice(startIndex, endIndex)
    }
    else {
      totalSize = products.length
      return products.slice(startIndex, endIndex)
    }
  })()
  //
  //Sorting Middleware
  const sortingAnalogs = () => {
    const result = new Array(...analogs)
    if (sortingProp && (sortingProp !== 'partnership_type')) {
      return isReverseSorting ?
        //@ts-ignore
        orderBy(result, [item => item[sortingProp as string].toLowerCase()], ['asc']).reverse() : orderBy(result, [item => item[sortingProp as string].toLowerCase()], ['asc'])
    }
    //Блок для сортировки аналогов с PartnershipType и  без PartnershipType
    else if (sortingProp === 'partnership_type') {
      let arrayWithPartnershipType = result.filter(item => item.partnership_type)
      arrayWithPartnershipType = orderBy(arrayWithPartnershipType, [item => item.partnership_type.toLowerCase()], ['asc'])
      const arrayWithoutPartnershipType = result.filter(item => !item.partnership_type)
      const calculateArray = [...arrayWithPartnershipType, ...arrayWithoutPartnershipType]
      return isReverseSorting ? calculateArray.reverse() : result
    }
    else {
      return result
    }
  }
  //
  //Filtered Analogs Array
  const filteredAnalogsArray = () => {
    const startIndex: number = (paginationAnalogsCurrent - 1) * currentShowAnalogsItems
    const endIndex: number = paginationAnalogsCurrent * currentShowAnalogsItems
    const filteredString = `${filteredWord}`.trim()
    const sortedArray = sortingAnalogs()
    //
    if (filteredString) {
      const trimString: string = filteredString.toUpperCase()
      const _filteredArray = sortedArray.filter(item => isFilteredWord(item, trimString))
      return _filteredArray.slice(startIndex, endIndex)
    }
    else {
      return sortedArray.slice(startIndex, endIndex)
    }
  }
  //
  //Обработчик добавления в корзину
  const addToBasketHandler = (item: ProductType) => {
    setObjectToLocalStorage(item)
  }
  //
  //On Choose Search Word
  const onChooseItem = (catalogueId: string) => {
    _setVisibleDropdown(false)
    setRequestSearchWord(catalogueId.slice(0, -1))
    onSearchHandler(catalogueId.slice(0, -1))
  }

  //Add to baskket From Search Suggest result
  const addToBasketFromSearchResult = (e: React.MouseEvent<HTMLElement>, product: ProductType, basketProducts: ProductBasketType[]) => {
    e.preventDefault()
    e.stopPropagation()

    //Get Id from product info
    const {id} = product
    const index = basketProducts.findIndex(item => item.id === id)

    //Get Id from product info
    if (index === -1) {
      dispatch(setAddToBtnDisable(true))
      getProductById.get(id).then(response => {
        const {data: { partner_products } } = response
        addToBasketHandler(partner_products[0])
      }).catch(() => {
        notificationError('Ошибка, повторите запрос')
      }).finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
    }
    else {
      addToBasketHandler(product)
    }
  }
  //
  //Search
  const searchResult = (query: string, searchArray: ResponseDataArrayType = []) => {
    _setVisibleDropdown(true)
    if (searchArray.length) {
      return searchArray
        .join('.')
        .split('.')
        .map((_, idx) => ({
          value: searchArray[idx].data.catalogue_id + idx,
          label: <SearchLabel theme={theme} addToBasketFromSearchResult={addToBasketFromSearchResult} idx={idx} searchArray={searchArray}/>,
        }))
    }
    else {
      return []
        .join('.')
        .split('.')
        .map(() => ({
          value: '',
          label: <div>Нет результатов...</div>,
        }))
    }
  }
  //
  //On Request Handler
  const requestHandler = (value: string) => {
    axiosSuggestRequestData.source.cancel()

    //String Trim
    if (!value.trim()) {
      setSearchOptions(searchResult('', []))
      return
    }

    //Axios Cancel Func
    axiosSuggestRequestData.cancelToken = axios.CancelToken
    axiosSuggestRequestData.source = axiosSuggestRequestData.cancelToken.source()

    //Api Creator Get
    apiCreator.get('https://api.appscience.ru/suggest', {
      params: { part: value },
      cancelToken: axiosSuggestRequestData.source.token,
    }).then(({ data }) => {
      setSearchOptions(searchResult(value, data))
      setRequestSearchResult(data)
    })
  }
  //
  //On search Handler
  const inputOnSearchHandler = (value: string) => {
    setRequestSearchWord(value)
    requestHandler(value)
  }
  //
  return {
    //props
    searchProp,
    searchWord,
    vendor,
    searchLoading,
    filteredWord,
    searched,
    paginationCurrent,
    products,
    totalSize,
    requestSearchResult,
    visibleDropdown,
    visibleLoader,
    loadingSearchByWord,
    searchOptions,
    requestSearchWord,
    analogs,
    analogsCasId,
    analogsLoading,
    currentShowAnalogsItems,
    paginationAnalogsCurrent,
    filteredAnalogsArray,
    totalAnalogsPagination,
    vendorsList,
    searchVendorsList,
    sortingAnalogs,
    isReverseSorting,
    sortingProp,

    //methods
    setSearchProp,
    _setSearchWord,
    setVendor,
    setSearchLoading,
    setFilteredWord,
    setSearched,
    setPaginationCurrent,
    setSearchWord,
    onSearchHandler,
    onSelectHandler,
    setObjectToLocalStorage,
    filteredArray,
    addToBasketHandler,
    inputOnSearchHandler,
    _setVisibleDropdown,
    setVisibleLoader,
    setLoadingSearchByWord,
    onChooseItem,
    setRequestSearchWord,
    setCurrentShowAnalogsItems,
    setPaginatioAnalogsCurrent,
    setVendorsList,
    setSearchVendorsList,
    setIsReverseSorting,
    setSortingProp,
  }
}