import {CurrencyData, OrderStatusId} from '../../store/procurement/create-order-popup/types'
import {ProcurementItem} from '../../store/procurement/reducer'
import apiCreator from '../apiCreator'
import {remapStringToTitleWithId} from '../common'
import {remapToOrderStatus, remapToProcurementItem} from './remapProcurementData'

export const procurement = {
  getTableData,
  createOrder,
  getStores,
  getResponsibilities,
  getCurrencies,
  getCountries,
  getPaymentMethods,
  getProviders,
  getBrands,
  getOrderStatuses,
}

export interface ProcurementTableResponseData {
  id: string,
  client_name: string,
  date: string,
  deadline_date: string,
  name: string,
  original_name: string,
  deal_id: string,
  final_price: number,
  brand: string,
  catalogue_id: string,
  country: string,
  proc_deadline_date: string,
  proposal_comments: string,
  quantity: string,
  store: string,
  procurement: string,
}

function getTableData(): Promise<Array<ProcurementItem>> {
  return apiCreator.get('https://api.appscience.ru/twin/get?status=procurement').then(res => {
    const rawItems: Array<ProcurementTableResponseData> = res.data.items
    return rawItems.map(remapToProcurementItem)
  })
}


export interface CreateOrderRequestItemData {
  id: string,
  brand: string,
  catalogue_id: string,
  original_name: string,
  cost: number,
  tax?: number,
  other_cost?: number,
  quantity: number,
  shipment_deadline: string,
}

export interface CreateOrderRequestOrderFields {
  store: string,
  ordering_date: string,
  status_order: OrderStatusId,
  order_invoice?: string,
  country: string,
  currency: string,
  provider: string,
  payment_type: string,
  po_number?: string,
  responsible_scd: string,
  tracking_number?: string,
  store_contacts?: string,
  sum_tax?: number,
  sum_other_cost_formula?: string,
}

interface CreateOrderRequestData extends CreateOrderRequestOrderFields {
  items: Array<CreateOrderRequestItemData>,
}

function createOrder(data: CreateOrderRequestData) {
  return apiCreator.post('https://api.appscience.ru/twin/create-order', data)
}

interface CurrencyResponseData {
  id: string,
  symbol: string,
}

interface PaymentMethodsResponseData {
  id: string,
  name: string,
}

export interface OrderStatusesResponseData {
  id: OrderStatusId,
  name: string,
}

function getBrands(): Promise<Array<TitleWithId>> {
  return apiCreator.get('https://api.appscience.ru/twin/brands').then(res => {
    const rawItems: Array<string> = res.data.brands
    return rawItems.map(remapStringToTitleWithId)
  })
}

function getCountries(): Promise<Array<TitleWithId>> {
  return apiCreator.get('https://api.appscience.ru/twin/countries').then(res => {
    const rawItems: Array<string> = res.data.countries
    return rawItems.map(remapStringToTitleWithId)
  })
}

function getCurrencies(): Promise<Array<CurrencyData>> {
  return apiCreator.get('https://api.appscience.ru/twin/currencies').then(res => {
    const rawItems: Array<CurrencyResponseData> = res.data.currencies
    return rawItems.map(x => ({
      id: x.id,
      title: `${x.id}, ${x.symbol}`,
      symbol: x.symbol,
    }))
  })
}

function getPaymentMethods(): Promise<Array<TitleWithId>> {
  return apiCreator.get('https://api.appscience.ru/twin/payment-methods').then(res => {
    const rawItems: Array<PaymentMethodsResponseData> = res.data['payment_methods']
    return rawItems.map(x => ({
      id: x.id,
      title: x.name,
    }))
  })
}

function getProviders(): Promise<Array<TitleWithId>> {
  return apiCreator.get('https://api.appscience.ru/twin/providers').then(res => {
    const rawItems: Array<string> = res.data.providers
    return rawItems.map(remapStringToTitleWithId)
  })
}

function getStores(): Promise<Array<TitleWithId>> {
  return apiCreator.get('https://api.appscience.ru/twin/stores').then(res => {
    const rawItems: Array<string> = res.data.stores
    return rawItems.map(remapStringToTitleWithId)
  })
}


type ResponsibleRole = 'scd'|'procurement'

function getResponsibilities(role: ResponsibleRole): Promise<Array<TitleWithId>> {
  return apiCreator.get(`https://api.appscience.ru/twin/responsible?role=${role}`).then(res => {
    const rawItems: Array<string> = res.data.responsible
    return rawItems.map(remapStringToTitleWithId)
  })
}

function getOrderStatuses(): Promise<Array<TitleWithId<OrderStatusId>>> {
  return apiCreator.get(`https://api.appscience.ru/twin/statuses-orders`).then(res => {
    const rawItems: Array<OrderStatusesResponseData> = res.data.statuses
    return rawItems.map(remapToOrderStatus)
  })
}