import {TextInput} from '@mantine/core'
import React from 'react'
import {EditItemPopupField, getEditItemPopupRequiredFieldsSet} from '../index'
import {
  CommonFieldProps,
  ProcurementEditItemPopupFormLabel,
  getProcurementEditItemPopupInputSxStyles, getProcurementTableColumnTitle,
} from './common'

interface Props extends CommonFieldProps {
  field: EditItemPopupField,
  disabled?: boolean,
}

export function EditItemPopupTextInput({
  field,
  disabled,
  form,
}: Props) {
  return <TextInput
    sx={getProcurementEditItemPopupInputSxStyles}
    label={<ProcurementEditItemPopupFormLabel
      label={getProcurementTableColumnTitle(field)}
      required={getEditItemPopupRequiredFieldsSet().has(field)}
    />}
    disabled={disabled}
    {...form.getInputProps(field)}
  />
}