import {PayloadAction, combineReducers, createSlice} from '@reduxjs/toolkit'
import {isEmpty} from 'ramda'
import {isNullable} from '../../../utils/check'
import {verify} from '../../../utils/Logger'
import {calcSumExpression, nullableSum, presiceNumber} from '../../../utils/number'
import {createOrderPopupErrorsReducer} from './errors-reducer'
import {
  CreateOrderPopupItem,
  CreateOrderPopupOrderFields,
  ItemOtherCostInfo,
  ItemTaxInfo,
  MutableCreateOrderPopupData,
  StaticCreateOrderPopupData,
} from './types'

export interface CreateOrderPopupState {
  staticData: StaticCreateOrderPopupData,
  mutableData: MutableCreateOrderPopupData,
}

const initialState: CreateOrderPopupState = {
  mutableData: {
    show: false,
    loadingState: 'normal',
    items: [],
    orderFields: {
      orderStatusId: 'in_progress',
      storeId: null,
      orderTimestamp: null,
      countryId: null,
      providerId: null,
      currencyId: null,
      paymentMethodId: null,
      responsibleSCDId: null,
      responsibleProcurementId: null,
      orderInvoice: '',
      numberPO: '',
      tracking: '',
      managerContact: '',
      summaryTax: null,
      summaryOtherCost: '',
    },
  },
  staticData: {
    stores: [],
    countries: [],
    providers: [],
    currencies: [],
    paymentMethods: [],
    brands: [],
    responsibleSCDs: [],
    responsibleProcurements: [],
    orderStatuses: [],
  },
}

interface UpdateItemFieldActionPayload<T extends keyof CreateOrderPopupItem> {
  itemId: string,
  field: T,
  value: CreateOrderPopupItem[T],
}

interface UpdateOrderFieldActionPayload<T extends keyof CreateOrderPopupOrderFields> {
  field: T,
  value: CreateOrderPopupOrderFields[T],
}

const createOrderPopupSlice = createSlice({
  name: 'createOrderPopup',
  initialState,
  reducers: {
    setCreateOrderPopupLoadingState(state, { payload }: PayloadAction<DefaultLoadingState>) {
      state.mutableData.loadingState = payload
    },
    loadCreateOrderPopupData() {},
    updateCreateOrderPopupOrderFields: <T extends keyof CreateOrderPopupOrderFields>(
      { mutableData }: CreateOrderPopupState,
      { payload }: PayloadAction<UpdateOrderFieldActionPayload<T>>,
    ) => {
      const {orderFields, items} = mutableData
      orderFields[payload.field] = payload.value

      if (payload.field === 'summaryTax') {
        if (isNullable(orderFields.summaryTax)) {
          items.forEach(x => (x.tax = isManuallyTax(x.tax) ? x.tax : null))
        }
        else {
          updateTaxValues(items, orderFields.summaryTax)
        }
      }

      if (payload.field === 'summaryOtherCost') {
        const sum = orderFields.summaryOtherCost === null ? null : calcSumExpression(orderFields.summaryOtherCost)
        const value = isNullable(sum) ? null : sum / items.length
        items.forEach(x => (x.otherCost = {
          ...x.otherCost,
          value,
        }))
      }
    },

    setCreateOrderPopupItems: (state, { payload }: PayloadAction<Array<CreateOrderPopupItem>>) => {
      state.mutableData.items = payload
    },
    updateCreateOrderPopupItemField: <T extends keyof CreateOrderPopupItem>(
      { mutableData: {orderFields, items} }: CreateOrderPopupState,
      { payload }: PayloadAction<UpdateItemFieldActionPayload<T>>,
    ) => {
      const item = verify(items.find(x => x.id === payload.itemId))
      const prevValue = item[payload.field]
      item[payload.field] = payload.value

      const summaryTax = orderFields.summaryTax
      if (payload.field === 'cost' && !isNullable(summaryTax)) {
        updateTaxValues(items, summaryTax)
      }

      if (payload.field === 'tax') {
        const prevTax = prevValue as ItemTaxInfo || null
        const newTax = payload.value as ItemTaxInfo || null
        orderFields.summaryTax = autoSum({
          sum: orderFields.summaryTax,
          prevValue: prevTax && prevTax.value,
          newValue: newTax && newTax.value,
        })

      }

      if (payload.field === 'otherCost') {
        const prevOtherCost = prevValue as ItemOtherCostInfo
        const newOtherCost = payload.value as ItemOtherCostInfo

        if (isEmpty(orderFields.summaryOtherCost)) {
          orderFields.summaryOtherCost = newOtherCost.value === null ? '' : newOtherCost.value.toString()
        }
        else {
          const summands = orderFields.summaryOtherCost.split('+').map(Number)
          const firstSummand = autoSum({
            sum: summands[0],
            prevValue: prevOtherCost.value,
            newValue: newOtherCost.value,
          })
          const nextSummands = summands.slice(1).join('+')
          orderFields.summaryOtherCost = nextSummands ? `${firstSummand}+${nextSummands}` : `${firstSummand}`
        }

        if (newOtherCost.acceptForTax && orderFields.summaryTax) {
          updateTaxValues(items, orderFields.summaryTax)
        }
      }
    },
    removeCreateOrderPopupItem: (state, { payload }: PayloadAction<string>) => {
      if (state.mutableData.items.length > 1) {
        state.mutableData.items = state.mutableData.items.filter(x => x.id !== payload)
      }
      else {
        state.mutableData.show = false
      }
    },
    toggleAcceptItemOtherCostForTax: (
      { mutableData: {orderFields, items} },
      { payload }: PayloadAction<string>,
    ) => {
      const { otherCost } = verify(items.find(x => x.id === payload))
      otherCost.acceptForTax = !otherCost.acceptForTax

      if (orderFields.summaryTax) {
        updateTaxValues(items, orderFields.summaryTax)
      }
    },

    saveCreateOrderPopupData() {},
    setCreateOrderPopupStaticData: (state, { payload }: PayloadAction<StaticCreateOrderPopupData>) => {
      state.staticData = payload
    },
    setCreateOrderPopupLoading(state, { payload }: PayloadAction<DefaultLoadingState>) {
      state.mutableData.loadingState = payload
    },
    openProcurementCreateOrderPopup(state) {
      state.mutableData.show = true
    },
    closeProcurementCreateOrderPopup(state) {
      state.mutableData.show = false
    },
    resetCreateOrderPopupData() {
      return initialState
    },
  },
})

interface AutoSumFnParams {
  sum: number|null,
  newValue: number|null,
  prevValue: number|null
}

function autoSum({
  newValue,
  sum,
  prevValue,
}: AutoSumFnParams): number|null {
  if (isNullable(sum)) {
    return newValue
  }
  const difference = newValue === null
    ? prevValue === null ? 0 : -prevValue
    : prevValue === null ? newValue : newValue - prevValue
  return presiceNumber(sum + difference, 2)
}

/** @description Грязная функция для обновления состояния */
function updateTaxValues(items: Array<CreateOrderPopupItem>, sumTax: number) {
  const [commonSumCost, sumManuallyTax] = items.reduce(([accCost, accManuallyTax], x) => {
    let newSumCost = accCost
    if (x.cost !== null && !isManuallyTax(x.tax)) {
      newSumCost += x.cost
      newSumCost += x.otherCost.acceptForTax && x.otherCost.value ? x.otherCost.value : 0
    }

    return [
      newSumCost,
      accManuallyTax + (x.tax?.manually ? x.tax.value : 0)]
  }, [0, 0])

  const commonSumTax = sumTax - sumManuallyTax
  items.forEach(x => {
    if (isManuallyTax(x.tax)) {
      return
    }
    const cost = x.otherCost.acceptForTax
      ? nullableSum(x.cost, x.otherCost.value)
      : x.cost

    x.tax = cost === null
      ? null
      : { value: presiceNumber(cost / commonSumCost * commonSumTax, 2) }
  })
}

function isManuallyTax(info: ItemTaxInfo|null) {
  return !!info?.manually
}

export const procurementCreateOrderPopupReducer = combineReducers({
  main: createOrderPopupSlice.reducer,
  errors: createOrderPopupErrorsReducer,
})

export const {
  setCreateOrderPopupLoadingState,
  openProcurementCreateOrderPopup,
  closeProcurementCreateOrderPopup,

  setCreateOrderPopupItems,
  updateCreateOrderPopupItemField,
  removeCreateOrderPopupItem,

  toggleAcceptItemOtherCostForTax,
  updateCreateOrderPopupOrderFields,
  loadCreateOrderPopupData,
  setCreateOrderPopupStaticData,
  resetCreateOrderPopupData,
  saveCreateOrderPopupData,
} = createOrderPopupSlice.actions