import {IdReducerFunctions} from '@table-library/react-table-library/types/common'
import {PaginationFunctions} from '@table-library/react-table-library/types/pagination'
import {SortFunctions} from '@table-library/react-table-library/types/sort'
import React, {useRef} from 'react'

interface CallbacksRefData {
  pagination: PaginationFunctions,
  select: IdReducerFunctions,
  sort: SortFunctions,
}

export function useDataTableCallbacksRef(): React.MutableRefObject<CallbacksRefData> {
  return useRef<CallbacksRefData>({
    pagination: {
      onSetPage: () => {},
      onSetSize: () => {},
    },
    select: {
      onAddById: () => {},
      onRemoveById: () => {},
      onToggleById: () => {},
      onAddByIdRecursively: () => {},
      onRemoveByIdRecursively: () => {},
      onToggleByIdRecursively: () => {},
      onAddByIdExclusively: () => {},
      onRemoveByIdExclusively: () => {},
      onToggleByIdExclusively: () => {},
      onToggleByIdShift: () => {},
      onAddAll: () => {},
      onRemoveAll: () => {},
      onToggleAll: () => {},
    },
    sort: {
      onToggleSort: () => {},
    },
  })
}