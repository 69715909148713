import {ArrowNarrowDownIcon, ArrowNarrowUpIcon, FilterIcon, SwitchVerticalIcon} from '@heroicons/react/solid'
import {Popover, useMantineTheme} from '@mantine/core'
import {HeaderCell} from '@table-library/react-table-library'
import {HeaderCellProps} from '@table-library/react-table-library/types/table'
import React, {useState} from 'react'
import {TransparentActionIcon} from '../../components/transparent-action-icon'
import {mode} from '../../utils/theme'
import {DataTableFilterDropdownProps} from './data-table'

type SortState = 'normal'|'asc'|'desc'

export interface DefaultHeaderCellSortInfo {
  state: SortState,
  onToggleSort: () => void,
}

interface DefaultHeaderCellProps extends HeaderCellProps {
  title: string,
  sortInfo?: DefaultHeaderCellSortInfo,
  filterDropdown?: (p: DataTableFilterDropdownProps) => JSX.Element,
}

export function DefaultHeaderCell({
  title,
  sortInfo,
  filterDropdown,
  ...props
}: DefaultHeaderCellProps) {
  const {colors, colorScheme} = useMantineTheme()
  const getArrowColor = () => mode(colors.blue[4], colors.blue[4])(colorScheme)

  function getSortIcon(state: SortState) {
    if (state === 'asc') {
      return <ArrowNarrowDownIcon className='w-4 h-4' color={getArrowColor()} />
    }
    if (state === 'desc') {
      return <ArrowNarrowUpIcon className='w-4 h-4' color={getArrowColor()}/>
    }
    return <SwitchVerticalIcon className='w-4 h-4' color={mode(colors.gray[4], colors.gray[4])(colorScheme)}/>
  }

  return (
    <HeaderCell {...props} >
      <div className='flex items-center' onClick={() => sortInfo && sortInfo.onToggleSort()}>
        {title}
        {sortInfo && <TransparentActionIcon className='min-w-min w-auto p-[1px] pr-0'>
          {getSortIcon(sortInfo.state)}
        </TransparentActionIcon>}
      </div>
      {filterDropdown && <Filter createContent={filterDropdown} />}
    </HeaderCell>
  )
}

interface FilterProps {
  createContent: (p: DataTableFilterDropdownProps) => JSX.Element,
}

function Filter({
  createContent,
}: FilterProps) {
  const {colors, colorScheme} = useMantineTheme()
  const [active, setActive] = useState(false)
  const [opened, setOpened] = useState(false)

  const getButtonColor = () => (opened || active
    ? mode(colors.blue[4], colors.blue[4])(colorScheme)
    : mode(colors.gray[4], colors.gray[4])(colorScheme))

  return <Popover
    opened={opened}
    onClose={() => setOpened(false)}
    target={
      <TransparentActionIcon className='min-w-min w-auto' onClick={() => setOpened(!opened)}>
        <FilterIcon color={getButtonColor()} className='w-3.5 h-3.5' />
      </TransparentActionIcon>
    }
    position='bottom'
    withArrow
    spacing={0}
  >
    {createContent({
      closeFn: () => setOpened(false),
      setActive,
    })}
  </Popover>
}