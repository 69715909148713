import { MutableRefObject } from 'react'
import {ReactSnipCallbacks} from '../components/MultilineEllipsis'
import {SnipState} from '../hooks/useSnip'
import {snipText} from '../method'

export const addObserver = (ref: MutableRefObject<HTMLElement>, state: MutableRefObject<SnipState>, callbacks?: ReactSnipCallbacks): void => {
  const { element } = state.current

  const observer = element.observer || new ResizeObserver(() => {
    if (ref.current.clientWidth !== element.prevWidth || ref.current.clientHeight !== element.prevHeight) {
      snipText(ref, state, callbacks)
    }
  })

  observer.observe(ref.current)
  element.observer = observer
}
