import {CheckboxStylesParams, CSSObject, MantineTheme} from '@mantine/core'

export function getCheckboxStylesConfig(theme: MantineTheme, props: Record<string, any>): Record<string, CSSObject> {
  const params = props as CheckboxStylesParams
  return {
    input: {
      cursor: 'pointer'
    },
    label: {
      cursor: 'pointer',
    }
  }
}