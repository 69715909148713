import React, {useEffect, useState} from 'react'
import {Alert, Button, Card, Col, Input, Modal, Row, Typography, notification} from 'antd'
import {ExclamationCircleOutlined, LoadingOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from 'react-redux'
import {AnyType} from '../../../types'
import {getApi} from '../../api/getApi'
import {getCitiesAction} from '../../store/search/reducer'
import {ProductBasketType} from '../../store/basket/types'
import {selectBasketProducts} from '../../store/basket/selectors'
import {
  selectDisabledOrderForm,
  selectDocLink,
  selectLinkToTable,
  selectOrderValue,
  selectPdfLink,
} from '../../store/generate-table/selectors'
import {
  setDocLinkAction,
  setLinkToTableAction,
  setOrderValueAction,
  setPdfLinkAction,
} from '../../store/generate-table/reducer'

const { Title } = Typography

export const GenerateTableContent: React.FC = () => {
  const dispatch = useDispatch()

  //From Store
  const pdfLink = useSelector(selectPdfLink)
  const docLink = useSelector(selectDocLink)
  const orderValue = useSelector(selectOrderValue)
  const isDisabledOrder = useSelector(selectDisabledOrderForm)
  const basketProducts = useSelector(selectBasketProducts)
  const linkToTable = useSelector(selectLinkToTable)

  const [showKpBlock, setShowKpBlock] = useState<boolean>(false)
  const [loadingTableBtns, setLoadingBtns] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getCitiesAction())
  }, [dispatch])

  const [warnings, setWarnings] = useState<JSX.Element[]>([])

  const setOrderValue = (value: string) => {
    const initialArrayFrom = Array.from(value.trim())
    // eslint-disable-next-line
    const resultValueArray = initialArrayFrom.filter(item => Number.isInteger(parseInt(item)))
    dispatch(setOrderValueAction(resultValueArray.join('')))
  }

  const getIdFromGoogleUrl = (url: string) => {
    const totalArray = url.split('/')
    //получаем необходимый код из ссылки
    return totalArray[5]
  }

  const onGenerateHandler = () => {
    !showKpBlock && setShowKpBlock(true)
    const products: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)

    const spreadsheetRequestParams = {
      id: orderValue,
      items: [] as AnyType[],
    }

    products.forEach(item => {
      const object = {
        ...item,
      }
      if (!object.price) {
        object.price = 0
      }
      if (!object.count) {
        object.count = 0
      }
      spreadsheetRequestParams.items.push(object)
    })

    setLoadingBtns(true)

    //request
    getApi().spreadsheet.post(spreadsheetRequestParams).then(({data}) => {
      dispatch(setLinkToTableAction(data))//setLinkToTable(data)
      if (basketProducts.length > 0) {
        onRegenerateHandler(data)
      }
      else {
        setLoadingBtns(false)
      }

      localStorage.setItem('linkToTable', data)
    }).then(() => {
      if (basketProducts.length > 0) {
        notificationHandler('Таблица успешно сгенерирована! Через несколько секунд будет сгенерировано коммерческое предложение.')
      }
      else {
        notificationHandler('Таблица успешно сгенерирована! Добавьте товары вручную и нажмите "Перегенировать КП".')
      }
    }).catch(() => {
      setLoadingBtns(false)
    })
  }

  //regenerate method
  const onRegenerateHandler = (linkToSpreadSheet: string) => {
    const proposalRequestParams: AnyType = {
      id: orderValue,
      spreadsheet_id: getIdFromGoogleUrl(linkToSpreadSheet),
    }
    setLoadingBtns(true)

    //request
    getApi().proposal.post(proposalRequestParams).then(({data}) => {
      dispatch(setPdfLinkAction(data.pdf))
      dispatch(setDocLinkAction(data.doc))
      localStorage.setItem('pdfLink', data.pdf)
      localStorage.setItem('docLink', data.doc)
      const newWarnings: JSX.Element[] = []
      if (data.warnings.length > 0) {
        data.warnings.forEach((warning: AnyType) => {
          newWarnings.push(<li>{warning.text}</li>)
        })
      }
      setWarnings(newWarnings)
      if (data.warnings && data.warnings.length > 0) {
        warningHandler('Коммерческое предложение сгенерировано, но не готово к отправке.')
      }
      else {
        notificationHandler('Коммерческое предложение успешно сгенерировано!')
      }
    }).finally(() => setLoadingBtns(false))
  }

  const addAndSubmitHandler = () => {
    setLoadingBtns(true)
    getApi().attach.post({
      id: orderValue,
      spreadsheet_url: linkToTable,
      proposal_pdf_url: pdfLink,
      proposal_doc_url: docLink,
    }).then(() => {
      notificationHandler('Коммерческое предложение успешно прикреплено к сделке!')
    }).finally(() => setLoadingBtns(false))
  }

  const confirm = () => {
    Modal.confirm({
      title: 'Предупреждение',
      icon: <ExclamationCircleOutlined />,
      content: 'Если перегенерировать исходную таблицу, то все изменения в текущей таблице могут быть потеряны. Вы уверены, что хотите перегенерировать таблицу заново?',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: () => {
        onGenerateHandler()
      },
    })
  }

  const notificationHandler = (notificationText: string) => {
    notification.success({
      message: `Успешно`,
      description: `${notificationText}`,
    })
  }

  const warningHandler = (notificationText: string) => {
    notification.warning({
      message: `Предупреждение`,
      description: `${notificationText}`,
    })
  }

  const numberOrderBeforeAddon = (
    <span>Номер сделки</span>
  )

  return (
    <>
      <Card>
        <Row className='pb-4'>
          <Col span={12} className='pr-2'>
            <Input
              addonBefore={numberOrderBeforeAddon}
              placeholder='Введите номер'
              disabled={isDisabledOrder}
              value={orderValue}
              onChange={e => setOrderValue(e.target.value)}/>
          </Col>
          <Col span={12} className='pl-2'>
            <Button type='primary' disabled={loadingTableBtns || (!orderValue.trim().length)} block onClick={() => (linkToTable ? confirm() : onGenerateHandler())}>
              {linkToTable ? 'Перегененировать таблицу-калькулятор' : 'Сгенерировать таблицу-калькулятор'}
            </Button>
          </Col>
        </Row>
      </Card>

      {linkToTable && <Card>
        <Row>
          {
            loadingTableBtns
              ? <Col span={24}>
                <div className='d-flex justify-content-center'>
                  <LoadingOutlined style={{fontSize: 24}}/>
                </div>
              </Col>
              : <>
                <Col span='12' className='pr-2'>
                  <Button block className='mb-4' disabled={loadingTableBtns}><a href={linkToTable} target='_blank'>Открыть таблицу-калькулятор</a></Button>
                </Col>
                <Col span='12' className='pl-2'>
                  <Button block className='mb-4' disabled={loadingTableBtns} onClick={() => onRegenerateHandler(linkToTable)}>Перегенерировать КП</Button>
                </Col>
                <Col span='12' className='pr-2'>
                  <Button block className='mb-4' disabled={!pdfLink}><a href={pdfLink} target='_blank'>Открыть КП(PDF)</a></Button>
                </Col>
                <Col span='12' className='pl-2'>
                  <Button block className='mb-4' disabled={!docLink}><a href={docLink} target='_blank'>Открыть КП(DOC)</a></Button>
                </Col>
                {warnings.length > 0 && (
                  <Col span={24} className='mb-4'>
                    <Alert message='Есть проблемы с КП' description={<ul>{warnings}</ul>} type='warning' showIcon/>
                  </Col>)}
                <Col span={24}>
                  <Button type='primary' block className='mb-3' disabled={loadingTableBtns} onClick={() => addAndSubmitHandler()}>Прикрепить КП</Button>
                </Col>
              </>}
        </Row>
      </Card>}
    </>
  )
}
