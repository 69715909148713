import {Text, createStyles} from '@mantine/core'
import {useColorScheme} from '@mantine/hooks'
import React from 'react'
import {joinStrings} from '../../../../../../utils/string'
import {mode} from '../../../../../../utils/theme'

interface CreateOrderPopupFieldLabelProps {
  label: string,
  required?: boolean,
}

const useStyles = createStyles(({ colors, colorScheme }) => ({
  asterisk: {
    lineHeight: 0,
    color: mode(colors.red[5], colors.red[5])(colorScheme),
  },
}))


export function CreateOrderPopupFieldLabel({
  label,
  required,
}: CreateOrderPopupFieldLabelProps): JSX.Element {
  const { classes } = useStyles()
  const colorScheme = useColorScheme()

  return <label className={joinStrings(
    'font-semibold uppercase text-[10px]',
    colorScheme === 'light' && 'text-gray-500',
  )}>
    {label}
    {required && <Text component='span' className={joinStrings(
      classes.asterisk, `text-[14px]`)
    }>
      {' *'}
    </Text>}
  </label>
}