import {ActionIcon, Drawer, Paper, Text} from '@mantine/core'
import {CogIcon} from '@heroicons/react/outline'
import React, {useState} from 'react'
import {isProdHostname} from '../../api/apiCreator'
import {ThemeConfigurator} from '../../components/configurator'
import {useSelector} from 'react-redux'
import {selectProcurementTableState} from '../../store/procurement/selectors'
import {mode} from '../../utils/theme'
import {CreateOrderButton} from '../../views/app-views/procurement-view/create-order-button'
import {HEADER_HEIGHT} from '../../constants/ThemeConstant'

export function ProcurementHeader() {
  const [opened, setOpened] = useState(false)
  const selectedRowKeys = useSelector(selectProcurementTableState('selectedRowKeys'))

  return (
    <Paper
      className='flex items-center pl-6 pr-3'
      radius={0}
      sx={({colors, colorScheme}) => ({
        height: HEADER_HEIGHT,
        borderBottomWidth: '1px',
        borderColor: mode(colors.gray[1], colors.gray[8])(colorScheme),
      })}
    >
      <Text className='font-semibold text-3xl mb-0 mr-auto' sx={({colors, colorScheme}) => ({
        color: mode(colors.dark[9], colors.gray[0])(colorScheme),
      })}>
        Procurement
      </Text>
      {selectedRowKeys.length > 0 &&
        <div className='flex items-center mr-3'>
          {selectedRowKeys.length > 0 && <CreateOrderButton /> }
        </div>
      }
      {!isProdHostname() && <span className='text-xl'>DEV</span>}
      <ActionIcon radius={0} size='xl' className='p-2 duration-150' onClick={() => setOpened(true)}
        sx={({colors, colorScheme}) => ({
          '&:hover': {
            backgroundColor: 'transparent',
            color: mode(colors.blue[5], colors.blue[8])(colorScheme),
          },
        })}
      >
        <CogIcon className='w-5 h-5' />
      </ActionIcon>
      <Drawer
        title='Configuration'
        onClose={() => setOpened(false)}
        opened ={opened}
        position='right'
        padding='xl'
      >
        <ThemeConfigurator />
      </Drawer>
    </Paper >
  )
}