import {AnyType} from '../../types'
import {isNonNullable} from './check'

/** @description Возвращает неглубокую копию объекта, отчищенную от null и undefined свойств */
export function cleanObject<T extends object, K extends keyof T>(obj: T): Record<K, AnyType> {
  const newObj = {} as Record<K, NonNullable<T[K]>>
  Object.entries(obj).forEach(([key, value]) => {
    if (isNonNullable(value)) {
      newObj[key as K] = value as NonNullable<T[K]>
    }
  })
  return newObj
}

export function filterObject<K extends PropertyKey, T>(obj: TypedObject<K, T>, cb: (v: T, k: K) => boolean): TypedObject<K, T> {
  const newObj = {} as TypedObject<K, T>
  Object.entries(obj).forEach(([key, value]) => {
    if (cb(value as T, key as K)) {
      newObj[key as K] = value as T
    }
  })
  return newObj
}