export interface MutableCreateOrderPopupData {
  show: boolean,
  loadingState: DefaultLoadingState,
  items: Array<CreateOrderPopupItem>,
  orderFields: CreateOrderPopupOrderFields,
}

export interface CreateOrderPopupOrderFields {
  storeId: string|null,
  orderStatusId: OrderStatusId,
  orderTimestamp: number|null,
  countryId: string|null,
  providerId: string|null,
  currencyId: string|null,
  paymentMethodId: string|null,
  responsibleSCDId: string|null,
  responsibleProcurementId: string|null,
  orderInvoice?: string,
  numberPO?: string,
  tracking?: string,
  managerContact?: string,
  summaryTax: number|null,
  summaryOtherCost: string, //строка суммы: 2+5+...+1
}

export interface CreateOrderPopupItem {
  id: string,
  brand: string|null,
  catalogue: string|null,
  originalName: string|null,
  cost: number|null,
  tax: ItemTaxInfo|null,
  otherCost: ItemOtherCostInfo,
  quantity: number,
  invoiceName: string,
  shipmentTimestamp: number|null,
  clientPrice: number,
  amoId: string,
}

export interface ItemTaxInfo {
  value: number,
  manually?: boolean,
}

export interface ItemOtherCostInfo {
  value: number|null,
  acceptForTax?: boolean,
}
//------------------------------------------------------------------------------------

export interface StaticCreateOrderPopupData {
  stores: Array<TitleWithId>,
  providers: Array<TitleWithId>,
  countries: Array<TitleWithId>,
  currencies: Array<CurrencyData>,
  paymentMethods: Array<TitleWithId>,
  brands: Array<TitleWithId>
  responsibleSCDs: Array<TitleWithId>
  responsibleProcurements: Array<TitleWithId>
  orderStatuses: Array<TitleWithId<OrderStatusId>>,
}

export interface CurrencyData {
  id: string,
  symbol: string,
  title: string,
}

export type OrderStatusId = 'in_progress'|'po_submitted'|'store_handling'|'waiting_prepaid'|'under_tracking'

export function getOrderStatusPosition(status: OrderStatusId): number {
  const positions: Record<OrderStatusId, number> = {
    in_progress: 1,
    po_submitted: 2,
    store_handling: 3,
    waiting_prepaid: 4,
    under_tracking: 5,
  }
  return positions[status]
}