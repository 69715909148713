import {filter} from 'ramda'

export function cleanArray<T>(array: Array<Nullable<T>>): Array<T> {
  return filter(Boolean, array) as Array<T>
}

export function intersectionSets<T>(...sets: Set<T>[]): Set<T> {
  let resultSet: Set<T> = new Set<T>([...sets[0]])
  for (let i = 1; i < sets.length; i++) {
    if (resultSet.size === 0) {
      break
    }
    // eslint-disable-next-line no-loop-func
    resultSet = new Set([...sets[i]].filter(x => resultSet.has(x)))
  }
  return resultSet
}

export function intersectionArrays<T>(...arrays: Array<T>[]): Array<T> {
  let resultSet: Set<T> = new Set<T>(arrays[0])
  for (let i = 1; i < arrays.length; i++) {
    if (resultSet.size === 0) {
      break
    }
    // eslint-disable-next-line no-loop-func
    resultSet = new Set(arrays[i].filter(x => resultSet.has(x)))
  }
  return [...resultSet]
}

export function differenceArrays<T>(first: Array<T>, second: Array<T>): Array<T> {
  const deductibleSet = new Set(second)
  return first.filter(x => !deductibleSet.has(x))
}