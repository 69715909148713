import React, {useState} from 'react'
import moment from 'moment'
import {Button, Card, Col, Row, Tooltip, Typography } from 'antd'
import { DownCircleOutlined, FieldTimeOutlined, ShoppingCartOutlined, UpCircleOutlined } from '@ant-design/icons'
import {ProductType} from '../../store/search/types'
import 'moment/locale/ru'
import parse from 'html-react-parser'

const {Title, Text} = Typography
type ProductCardType = ProductType & {addToCart: ()=> void, isDisabledBtn?: boolean}

const nonExpandStyle = {
  height: 10,
  marginTop: -20,
}

export const ProductCard: React.FC<ProductCardType> = props => {
  const [expandBottom, setExpandBottom] = useState<boolean>(false)
  const {
    name,
    catalogue_id,
    vendor,
    cas_id,
    un_id,
    partner_url,
    availability,
    shipping_conditions,
    shipping_group_id,
    parse_time,
    addToCart,
    price,
    price_currency,
    package_size,
    isDisabledBtn,
  } = props

  const dateTransformer = () => {
    moment.locale()
    const date = moment(parse_time)
    return date.fromNow()
  }

  const addToCartHandler = () => {
    addToCart()
  }

  return (
    <Card className='product-card'>
      <div className='card-holder'>
        <Row justify='space-between' className='mb-3'>

          <Col span={24} md={8} lg={11} style={{transform: 'translateY(5px)'}}>
            <Title level={4}>
              <a href={partner_url} target='_blank' className='text-dark'>{parse(`<span>${name}</span>`)}</a>
            </Title>
          </Col>
          <Col span={24} md={16} lg={12} className='justify-content-between justify-content-md-end d-flex align-items-center'>
            <Text className='text-info font-weight-bolder product-top-label'>{vendor}</Text>
            <Text className={`${availability === 'N/A' ? 'text-danger' : 'text-success'} font-weight-bolder rounded product-top-label ml-3`}>{availability}</Text>
            <Tooltip placement='bottomLeft' title={`Последнее обновление ${dateTransformer()}`} style={{minWidth: 400}} className='ml-3'>
              <div className='icon-item'><FieldTimeOutlined style={{fontSize: 21, transform: 'translateY(3px)'}}/></div>
            </Tooltip>
            <Button disabled={isDisabledBtn} type='primary' ghost className='ml-3' onClick={() => addToCartHandler()}>Добавить <ShoppingCartOutlined style={{fontSize: 17, transform: 'translateY(2px)'}}/></Button>
          </Col>
        </Row>

        <Row>

          {/*{COL}*/}
          <Col span={24} sm={12} md={10}>
            <Row>
              <Col span={24}>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'><span className='mr-2'>Catalogue id:</span><b className='text-dark'>{catalogue_id}</b></Text>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'><span className='mr-2'>Темп. реж:</span> <b className='text-dark'>{shipping_conditions}</b></Text>
              </Col>
            </Row>
          </Col>

          <Col span={24} sm={12} md={6}>
            <Row>
              <Col span={24} className='pl-0'>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-flex mb-2'><span className='mr-2 ml-0 ml-md-3'>CAS:</span><b className='text-dark'>{cas_id}</b></Text>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 mb-2'><span className='mr-2'>UN код:</span><b className='text-dark'>{un_id ? un_id : '---'}</b></Text>
              </Col>
            </Row>
          </Col>

          {/* Dektop  */}
          <Col span={24} md={8} className='text-left text-md-right d-none d-md-block'>
            <Row>
              <Col span={24}>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'><span className='mr-2 ml-0 ml-md-3'>Цена:</span><b className='text-dark'>{price} {price_currency}</b></Text>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'><span className='mr-2'>Фасовка:</span><b className='text-dark'>{package_size}</b></Text>
              </Col>
            </Row>
          </Col>


          {/* Mobile */}
          <Col span={24} className='d-md-none'>
            <Row>
              <Col span={24} sm={12}>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'>
                  <span className='mr-2 ml-0 ml-md-3'>Цена:</span><b className='text-dark'>{price} {price_currency}</b>
                </Text>
              </Col>
              <Col span={24} sm={12}>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-2 d-block mb-2'>
                  <span className='mr-2'>Фасовка:</span><b className='text-dark'>{package_size}</b>
                </Text>
              </Col>
            </Row>
          </Col>

        </Row>

        <Row style={expandBottom ? {} : nonExpandStyle}>
          <Col span={24} className='d-flex justify-content-between align-content-center'>
            <div className='prop-item mb-2'>
              {expandBottom && <>
                <Text type='secondary' style={{fontWeight: 400}} className='mr-3'>Класс дост-ки:</Text>
                <Text strong style={{fontWeight: 500}}>{shipping_group_id ? shipping_group_id : '---'}</Text></>
              }
            </div>
            {expandBottom
              ? <UpCircleOutlined style={{fontSize: 21, marginRight: -17, marginTop: 10, transform: 'translateY(16px)'}} onClick={() => setExpandBottom(false)}/>
              : <DownCircleOutlined style={{fontSize: 21, marginRight: -17, marginTop: 7}} onClick={() => setExpandBottom(true)}/>}
          </Col>
        </Row>
      </div>
    </Card>
  )
}
