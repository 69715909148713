import React, {useEffect, useRef} from 'react'
import {Col, Divider, InputNumber, Row, Typography} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {ProductBasketType} from '../../store/basket/types'
import parse from 'html-react-parser'

const {
  Title,
  Text,
} = Typography

type ProductBasketCardType = ProductBasketType & {
	onCount: (value: number, itemIndex: number) => void
	itemIndex: number
	onStartEditHandler?: (index: number) => void
	scrollTrigger?: ()=> void
  lastItem?: boolean
  isDisabledCount: boolean
}

export const BasketProductCard: React.FC<ProductBasketCardType> = props => {
  const {
    catalogue_id,
    availability,
    vendor,
    count,
    price,
    name,
    partner_url,
    onCount,
    price_currency,
    itemIndex,
    onStartEditHandler,
    lastItem,
    scrollTrigger,
    package_size,
    isDisabledCount,
  } = props

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    lastItem && scrollTrigger && scrollTrigger()
  }, [])

  //Count Handler
  const onCountHandler = (value: number) => {
    onCount(value, itemIndex)
  }

  //On Start Edit Handler
  const onStartEdit = (index: number) => {
    onStartEditHandler && onStartEditHandler(index)
  }

  return (
    <div className='basket-product-card mb-3' ref={ref}>
      <Row className='justify-content-between mb-3'>
        <Col span={12}>
          <Title level={4} className='mb-2 text-dark d-flex align-items-top' style={{wordBreak: 'break-all'}}>
            {partner_url
              ? <a href={partner_url} className='text-dark mr-2' target='_blank' style={{textDecoration: 'underline'}}>{parse(`<span>${name}</span>`)}</a>
              : <span className='text-dark mr-2'>{parse(`<span>${name}</span>`)}</span>}
            <EditOutlined style={{fontSize: 17, cursor: 'pointer'}} className='text-info' onClick={() => onStartEdit(itemIndex)}/>
          </Title>
          <div className='props-holder'>
            {vendor && <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>{vendor}</Text>}
            <Text className={`text-info font-weight-bolder rounded  pr-2 pt-2 pb-2 product-top-label text-white`}>{`${catalogue_id || ''}`}</Text>
            <Text className={`text-info font-weight-bolder rounded  pr-2 pt-2 pb-2 product-top-label text-white`}>{`${package_size || ''}`}</Text>
            <Text className={`${availability === 'N/A' ? 'text-danger' : 'text-success'} font-weight-bolder rounded pt-2 pb-2 product-top-label`}>{availability}</Text>
          </div>
        </Col>
        <Col className='d-flex align-items-center'>
          <span>
            <strong>{price ? (price * count).toFixed(2) : 'Н/Д'} {price && price_currency}</strong>
          </span>
          <InputNumber className='ml-3' value={count} onChange={value => onCountHandler(value)} disabled={isDisabledCount}/>
          <div style={{visibility: isDisabledCount ? 'hidden' : 'visible'}}>
            <DeleteOutlined style={{fontSize: 21, cursor: 'pointer'}} className='text-danger ml-3' onClick={() => onCountHandler(-1)}/>
          </div>
        </Col>
      </Row>
      <Divider/>
    </div>
  )
}
