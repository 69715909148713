import {Button} from '@mantine/core'
import React, {memo, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {LoaderContainer} from '../../../../../components/loader-container'
import {useAppDispatch} from '../../../../../hooks/storeHooks'
import {
  closeProcurementCreateOrderPopup,
  loadCreateOrderPopupData,
  saveCreateOrderPopupData,
} from '../../../../../store/procurement/create-order-popup/create-order-popup-reducer'
import {createOrderPopupHasErrors} from '../../../../../store/procurement/create-order-popup/errors-reducer'
import {
  selectCreateOrderPopupErrors,
  selectCreateOrderPopupMutableDataItem,
} from '../../../../../store/procurement/selectors'
import {PopupDefault} from '../../../../../uikit/PopupDefault'
import {isLoading, isSaving} from '../../../../../utils/check'
import {CreateProcurementOrderPopupContent} from './content'

export const CreateProcurementOrderPopup = memo(() => {
  const dispatch = useAppDispatch()
  const show = useSelector(selectCreateOrderPopupMutableDataItem('show'))
  const state = useSelector(selectCreateOrderPopupMutableDataItem('loadingState'))

  useEffect(() => {
    show && dispatch(loadCreateOrderPopupData())
  }, [dispatch, show])

  return <PopupDefault
    title='Создать заказ'
    show={show}
    createContent={() => (isLoading(state) ? <LoaderContainer /> : <CreateProcurementOrderPopupContent />)}
    buttonsInfo={[
      <Button key='cancel' className='font-semibold' variant='outline' onClick={() => dispatch(closeProcurementCreateOrderPopup())}>
        Отменить
      </Button>,
      <SuccessButton key='success' />,
    ]}
    closeFn={() => dispatch(closeProcurementCreateOrderPopup())}
    className='w-[1360px] min-h-[340px]'
  />
})

function SuccessButton() {
  const dispatch = useAppDispatch()
  const errorsState = useSelector(selectCreateOrderPopupErrors)
  const state = useSelector(selectCreateOrderPopupMutableDataItem('loadingState'))

  return (
    <Button key='cancel' className='font-semibold' disabled={createOrderPopupHasErrors(errorsState) || isSaving(state)} loading={isSaving(state)}
      onClick={() => dispatch(saveCreateOrderPopupData())}
    >
      Сохранить
    </Button>
  )
}