import {Table, TableNode} from '@table-library/react-table-library'
import {usePagination} from '@table-library/react-table-library/pagination'
import {useRowSelect} from '@table-library/react-table-library/select'
import {useTheme} from '@table-library/react-table-library/theme'
import {Layout as LayoutProps} from '@table-library/react-table-library/types/layout'
import {Select as SelectData, SelectOptions} from '@table-library/react-table-library/types/select'
import {Theme} from '@table-library/react-table-library/types/theme'
import React, {useEffect, useMemo} from 'react'
import {DataTableContent} from './default-content'
import {useDataTableCallbacksRef} from './hooks/use-callbacks-ref'
import {useDataTableSort} from './hooks/use-data-table-sort'
import {getDataTableStateCheckers, useDataTablePrevValuesRef} from './hooks/use-prev-values-ref'
import DataTablePagination from './pagination-section'
import {addDeps} from '../../utils/common'

export interface DataTableFilterDropdownProps {
  closeFn: () => void,
  setActive: (v: boolean) => void,
}

export interface DataTableSelectData extends SelectData {
  pinLeft?: boolean,
}

export interface DataTableSelectOptions extends SelectOptions {
  pinLeft?: boolean,
}

interface DataTableSelect {
  options?: DataTableSelectOptions,
  onChange?: (ids: Array<string>) => void,
}

export type DataTableFilterDropdowns<ColumnID extends string> = TypedObject<ColumnID, (p: DataTableFilterDropdownProps) => JSX.Element>

export interface DataTableColumnInfo<ColumnID extends string, ITEM extends TableNode> {
  id: ColumnID,
  title: string,
  content: (v: ITEM) => React.ReactNode,
  width: number, //fr
  minWidth?: string, //px
  sort?: (a: ITEM, b: ITEM) => number,
  searchFn?: (v: ITEM, search: string) => boolean,
}

interface DataTablePaginationProps {
  initSize: number,
  initPageIndex?: number,
  sizesList?: Array<number>,
  onPageIndexChange?: (page: number) => void,
  onSizeChange?: (size: number) => void,
}

export interface ShortcutActionData<ITEM extends TableNode> {
  onClick: (item: ITEM) => void,
  icon: JSX.Element,
}

interface DataTableProps<ColumnID extends string, ITEM extends TableNode> {
  data: Array<ITEM>
  pagination: DataTablePaginationProps,
  columns: Array<DataTableColumnInfo<ColumnID, ITEM>>,
  theme?: Theme,
  select?: DataTableSelect,
  layout?: LayoutProps,
  height?: string,
  filterDropdowns?: DataTableFilterDropdowns<ColumnID>,
  visibleColumnIds?: Array<ColumnID>,
  searchValue?: string,
  getShortcutActions?: (item: ITEM) => JSX.Element,
}

//TODO:procurement завести Q&A на управление состоянием select. Нужна возможность сбрасывать
export function DataTable<ColumnID extends string, ITEM extends TableNode>({
  height,
  data,
  pagination,
  columns: initialColumns,
  theme,
  select: selectData,
  layout,
  filterDropdowns,
  visibleColumnIds,
  searchValue,
  getShortcutActions,
}: DataTableProps<ColumnID, ITEM>) {
  const callbacksRef = useDataTableCallbacksRef()
  const prevValuesRef = useDataTablePrevValuesRef()
  const stateCheckerFns = getDataTableStateCheckers()
  const columns = useMemo(() => {
    const visibleColumnsSet = new Set(visibleColumnIds)
    return initialColumns.filter(x => visibleColumnsSet.has(x.id))
  }, [initialColumns, visibleColumnIds])
  const filteredData = useMemo(() => (searchValue
    ? data.filter(x => columns.some(column => column.searchFn && column.searchFn(x, searchValue)))
    : data), [columns, data, searchValue])
  const tableData = { nodes: filteredData }
  const dimensionsTheme = useDimensionsTheme(columns, height)
  const resultTheme = useTheme(theme ? [theme, dimensionsTheme] : dimensionsTheme)
  const select = useDataTableRowSelect(tableData, selectData)
  const paginationData = usePagination(tableData, {
    state: { size: pagination.initSize, page: pagination.initPageIndex || 0 },
  })

  const sort = useDataTableSort({
    columns,
    tableData,
  })
  callbacksRef.current = {
    pagination: paginationData.fns,
    select: select.fns,
    sort: sort.fns,
  }

  const sortWasChanged = stateCheckerFns.checkSortChanged(prevValuesRef.current, sort)
  if (sortWasChanged) {
    callbacksRef.current.select
  }

  useEffect(() => {
    addDeps(data)
    callbacksRef.current.pagination.onSetPage(0)
    callbacksRef.current.select.onRemoveAll()
  }, [data, callbacksRef])

  return (
    <div>
      <Table pagination={paginationData} data={tableData} theme={resultTheme} select={select} sort={sort} layout={{custom: true, ...layout}}>
        {tableList => {
          const rows = tableList as Array<ITEM>
          return <DataTableContent rows={rows} columns={columns} select={select} sort={sort} filterDropdowns={filterDropdowns} getShortcutActions={getShortcutActions} />
        }}
      </Table>
      <DataTablePagination
        pageIndex={paginationData.state.page}
        size={paginationData.state.size}
        totalRecordsCount={data.length}
        sizesList={pagination.sizesList}
        onPageIndexChange={index => {
          pagination.onPageIndexChange && pagination.onPageIndexChange(index)
          paginationData.fns.onSetPage(index)
        }}
        onSizeChange={size => {
          pagination.onSizeChange && pagination.onSizeChange(size)
          paginationData.fns.onSetSize(size)
        }}
      />
    </div>
  )
}

function useDataTableRowSelect<ITEM extends TableNode>(tableData: { nodes: Array<ITEM> }, selectData?: DataTableSelect): DataTableSelectData {
  const select = useRowSelect(tableData, {
    onChange: (action, state) => selectData?.onChange && selectData.onChange(state.ids),
  }, selectData?.options)
  return {
    ...select,
    pinLeft: selectData?.options?.pinLeft,
  }
}

function useDimensionsTheme<ColumnID extends string, ITEM extends TableNode>(columns: Array<DataTableColumnInfo<ColumnID, ITEM>>, height?: string): Theme {
  const totalWidth = columns.reduce((acc, column) => acc + column.width, 0)
  const baseCellStyles = columns.reduce((acc, column) => {
    let newValue = acc + `&.${column.id} {
      width: ${column.width / totalWidth * 100}%;
    `
    newValue += column.minWidth === undefined ? '' : `min-width: ${column.minWidth};`
    return newValue + '}'
  }, '')

  let tableStyles = `
    display: block;
    position: relative;
  `
  tableStyles += height ? `height: ${height};` : ''

  return {
    Table: tableStyles,
    HeaderCell: `
      &.rowActions {
        visibility: hidden;
      }
    `,
    BaseCell: `
      &.rowActions {
        margin-left: auto;
        display: flex;
        align-items: center;
        min-width: auto !important;
        width: auto !important;
        opacity: 0;
        right: 0;
        transition-duration: 200ms;
        background-color: transparent;
      }
      &.selectCell {
        min-width: 0;
        width: initial;
        padding: 0 3px;
        left: 0px;
      }
      ${baseCellStyles}
    `,
    BaseRow: `
      &:hover .rowActions {
        opacity: 100;
      }
    `,
  }
}