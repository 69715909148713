import {PayloadAction, combineReducers, createSlice} from '@reduxjs/toolkit'
import {procurementCreateOrderPopupReducer} from './create-order-popup/create-order-popup-reducer'
import {ProcurementTableColumnId} from '../../views/app-views/procurement-view/hooks/use-procurement-table-columns.hook'

//undefined - опциональность, null - неккорректный ввод пользователя
export interface ProcurementItem {
	id: string,
	invoiceTimestamp: number|null,
	deadlineTimestamp: number|null,
  clientName: string,
  amoId: string,
  clientPrice: number,
  invoiceName: string,
  proposalComment?: string,
  brand: string,
  purchaseTimestamp: number|null,
  catalogueId: string,
  quantity: number,
  supposedStore: string,
  country: string,
  responsibleProcurement: string,
  originalName: string,
}

interface ProcurementTableStaticData {
  countries: Array<TitleWithId>,
  procurements: Array<TitleWithId>,
  stores: Array<TitleWithId>,
}

export interface GlobalFiltersData {
  countryIds: Array<string>,
  storeIds: Array<string>,
  procurementIds: Array<string>,
}

export interface ProcurementTableState {
	loading: boolean,
	data: Array<ProcurementItem>,
	selectedRowKeys: Array<string>,
	searchValue: string,
  visibleColumns: Array<ProcurementTableColumnId>,
  staticData: ProcurementTableStaticData,
  editableItem: ProcurementItem|null,
  globalFilters: GlobalFiltersData,
}

const initialState: ProcurementTableState = {
  loading: false,
  data: [],
  selectedRowKeys: [],
  searchValue: '',
  visibleColumns: [
    'invoiceTimestamp',
    'deadlineTimestamp',
    'invoiceName',
    'amoId',
    'clientName',
    'clientPrice',
    'brand',
    'purchaseTimestamp',
    'catalogueId',
    'quantity',
    'proposalComment',
  ],
  staticData: {
    countries: [],
    procurements: [],
    stores: [],
  },
  globalFilters: {
    countryIds: [],
    storeIds: [],
    procurementIds: [],
  },
  editableItem: null,
}

interface UpdateGlobalFilterAction<T extends keyof GlobalFiltersData> {
  field: T,
  value: GlobalFiltersData[T],
}

const procurementTableSlice = createSlice({
  name: 'procurementTable',
  initialState,
  reducers: {
    setProcurementTableLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    loadProcurementTableData() {},
    setProcurementTableData: (state, { payload }: PayloadAction<Array<ProcurementItem>>) => {
      state.data = payload
    },
    setProcurementTableStaticData: (state, { payload }: PayloadAction<ProcurementTableStaticData>) => {
      state.staticData = payload
    },
    setProcurementTableSelectedRowIds: (state, { payload }: PayloadAction<Array<string>>) => {
      state.selectedRowKeys = payload
    },
    setProcurementTableSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchValue = payload
    },
    setProcurementTableVisibilityColumns: (state, { payload }: PayloadAction<Array<ProcurementTableColumnId>>) => {
      state.visibleColumns = payload
    },
    setProcurementTableGlobalFilter<T extends keyof GlobalFiltersData>(
      state: ProcurementTableState,
      { payload }: PayloadAction<UpdateGlobalFilterAction<T>>,
    ) {
      state.globalFilters[payload.field] = payload.value
    },
    setProcurementEditableItem(state, { payload }: PayloadAction<ProcurementItem|null>) {
      state.editableItem = payload
    },
  },
})

export const procurementReducer = combineReducers({
  createOrderPopup: procurementCreateOrderPopupReducer,
  table: procurementTableSlice.reducer,
})

export const {
  setProcurementEditableItem,
  loadProcurementTableData,
  setProcurementTableData,
  setProcurementTableStaticData,
  setProcurementTableSelectedRowIds,
  setProcurementTableLoading,
  setProcurementTableSearchValue,
  setProcurementTableVisibilityColumns,
  setProcurementTableGlobalFilter,
} = procurementTableSlice.actions