import {format, getYear} from 'date-fns'
import {useMemo} from 'react'
import {ProcurementItem} from '../../../../store/procurement/reducer'
import {caseInsensitiveSubstr} from '../../../../utils/string'
import {DataTableColumnInfo} from '../../../../uikit/data-table/data-table'
import {checkNever} from '../../../../utils/check'
import {caseInsensitiveCompare, defaultCompare} from '../../../../utils/compare'
import {AmoIdCell} from '../cells/amo-id-cell'
import {TextCell} from '../cells/text-cell'
import {numberInputFormatter} from '../../../../utils/number'

export type ProcurementTableColumnId = Exclude<keyof ProcurementItem,
  'id'|'originalName'
>

type ProcurementTableColumn = DataTableColumnInfo<ProcurementTableColumnId, ProcurementItem>

export function useProcurementTableColumnsHook(): Array<ProcurementTableColumn> {
  return useMemo(() => [
    {
      id: 'invoiceTimestamp',
      title: getProcurementTableColumnTitle('invoiceTimestamp'),
      content: item => <div>{formatProcurementDate(item.invoiceTimestamp)}</div>,
      width: 5,
      minWidth: '110px',
      sort: (a, b) => defaultCompare(a.invoiceTimestamp, b.invoiceTimestamp),
      searchFn: (item, search) => caseInsensitiveSubstr(formatProcurementDate(item.invoiceTimestamp), search),
    },
    {
      id: 'deadlineTimestamp',
      title: getProcurementTableColumnTitle('deadlineTimestamp'),
      content: item => <div>{formatProcurementDate(item.deadlineTimestamp)}</div>,
      width: 5,
      minWidth: '110px',
      sort: (a, b) => defaultCompare(a.invoiceTimestamp, b.invoiceTimestamp),
      searchFn: (item, search) => caseInsensitiveSubstr(formatProcurementDate(item.deadlineTimestamp), search),
    },
    {
      id: 'clientName',
      title: getProcurementTableColumnTitle('clientName'),
      content: item => <TextCell text={item.clientName} maxLines={2} />,
      width: 13,
      minWidth: '220px',
      sort: (a, b) => caseInsensitiveCompare(a.clientName, b.clientName),
      searchFn: (item, search) => caseInsensitiveSubstr(item.clientName, search),
    },
    {
      id: 'amoId',
      title: getProcurementTableColumnTitle('amoId'),
      content: item => <AmoIdCell id={item.amoId} />,
      width: 6,
      minWidth: '100px',
      sort: (a, b) => caseInsensitiveCompare(a.amoId, b.amoId),
      searchFn: (item, search) => caseInsensitiveSubstr(item.amoId, search),
    },
    {
      id: 'clientPrice',
      title: getProcurementTableColumnTitle('clientPrice'),
      content: item => `${numberInputFormatter(item.clientPrice.toString())} ₽`,
      width: 8,
      minWidth: '140px',
      sort: (a, b) => defaultCompare(a.clientPrice, b.clientPrice),
      searchFn: (item, search) => caseInsensitiveSubstr(item.clientPrice.toString(), search),
    },
    {
      id: 'invoiceName',
      title: getProcurementTableColumnTitle('invoiceName'),
      content: item => <TextCell text={item.invoiceName} maxLines={2} />,
      width: 11,
      minWidth: '220px',
      sort: (a, b) => caseInsensitiveCompare(a.invoiceName, b.invoiceName),
      searchFn: (item, search) => caseInsensitiveSubstr(item.invoiceName, search),
    },
    {
      id: 'brand',
      title: getProcurementTableColumnTitle('brand'),
      content: item => <TextCell text={item.brand} maxLines={2} />,
      width: 10,
      minWidth: '170px',
      sort: (a, b) => caseInsensitiveCompare(a.brand, b.brand),
      searchFn: (item, search) => caseInsensitiveSubstr(item.brand, search),
    },
    {
      id: 'purchaseTimestamp',
      title: getProcurementTableColumnTitle('purchaseTimestamp'),
      content: item => <div>{formatProcurementDate(item.purchaseTimestamp)}</div>,
      width: 5,
      minWidth: '110px',
      sort: (a, b) => defaultCompare(a.purchaseTimestamp, b.purchaseTimestamp),
      searchFn: (item, search) => caseInsensitiveSubstr(formatProcurementDate(item.purchaseTimestamp), search),
    },
    {
      id: 'catalogueId',
      title: getProcurementTableColumnTitle('catalogueId'),
      content: item => item.catalogueId,
      width: 9,
      minWidth: '150px',
      sort: (a, b) => caseInsensitiveCompare(a.catalogueId, b.catalogueId),
      searchFn: (item, search) => caseInsensitiveSubstr(item.catalogueId, search),
    },
    {
      id: 'quantity',
      title: getProcurementTableColumnTitle('quantity'),
      content: item => item.quantity.toString(),
      width: 5,
      minWidth: '110px',
      sort: (a, b) => defaultCompare(a.quantity, b.quantity),
      searchFn: (item, search) => caseInsensitiveSubstr(item.quantity.toString(), search),
    },
    {
      id: 'country',
      title: getProcurementTableColumnTitle('country'),
      content: item => item.country,
      width: 7,
      minWidth: '170px',
      searchFn: (item, search) => caseInsensitiveSubstr(item.country, search),
    },
    {
      id: 'supposedStore',
      title: getProcurementTableColumnTitle('supposedStore'),
      content: item => item.supposedStore,
      width: 7,
      minWidth: '180px',
      searchFn: (item, search) => caseInsensitiveSubstr(item.supposedStore, search),
    },
    {
      id: 'responsibleProcurement',
      title: getProcurementTableColumnTitle('responsibleProcurement'),
      content: item => item.responsibleProcurement,
      width: 7,
      minWidth: '150px',
      searchFn: (item, search) => caseInsensitiveSubstr(item.responsibleProcurement, search),
    },
    {
      id: 'proposalComment',
      title: getProcurementTableColumnTitle('proposalComment'),
      content: item => (item.proposalComment ? <TextCell text={item.proposalComment} maxLines={2} /> : ''),
      width: 12,
      minWidth: '200px',
      searchFn: (item, search) => !!item.proposalComment && caseInsensitiveSubstr(item.proposalComment, search),
    },
  ], [])
}

export function getProcurementTableColumnsInfo(): Record<ProcurementTableColumnId, string> {
  return {
    invoiceTimestamp: getProcurementTableColumnTitle('invoiceTimestamp'),
    deadlineTimestamp: getProcurementTableColumnTitle('deadlineTimestamp'),
    invoiceName: getProcurementTableColumnTitle('invoiceName'),
    amoId: getProcurementTableColumnTitle('amoId'),
    clientName: getProcurementTableColumnTitle('clientName'),
    clientPrice: getProcurementTableColumnTitle('clientPrice'),
    brand: getProcurementTableColumnTitle('brand'),
    purchaseTimestamp: getProcurementTableColumnTitle('purchaseTimestamp'),
    catalogueId: getProcurementTableColumnTitle('catalogueId'),
    quantity: getProcurementTableColumnTitle('quantity'),
    proposalComment: getProcurementTableColumnTitle('proposalComment'),
    country: getProcurementTableColumnTitle('country'),
    responsibleProcurement: getProcurementTableColumnTitle('responsibleProcurement'),
    supposedStore: getProcurementTableColumnTitle('supposedStore'),
  }
}

export function getProcurementTableColumnTitle(id: ProcurementTableColumnId): string {
  switch (id) {
    case 'brand':
      return 'Производитель'
    case 'invoiceTimestamp':
      return 'Дата счёта'
    case 'clientName':
      return 'Клиент'
    case 'amoId':
      return 'AMO'
    case 'invoiceName':
      return 'Наименование в счете'
    case 'quantity':
      return 'Количество'
    case 'proposalComment':
      return 'Комментарий от КП'
    case 'catalogueId':
      return 'Cat'
    case 'purchaseTimestamp':
      return 'Дата закупки'
    case 'deadlineTimestamp':
      return 'Deadline'
    case 'clientPrice':
      return 'Цена за штуку для клиента, ₽'
    case 'country':
      return 'Предполагаемая страна'
    case 'supposedStore':
      return 'Предполагаемый магазин'
    case 'responsibleProcurement':
      return 'Procurement'
    default:
      checkNever(id, `Unknown procurement table id. Value: ${id}`)
      return ''
  }
}

function formatProcurementDate(timestamp: null|number) {
  const currentYear = new Date().getFullYear()
  return timestamp === null
    ? ''
    : format(timestamp, getYear(timestamp) === currentYear ? 'dd.MM' : 'dd.MM.yyyy')
}