import {Autocomplete, Select} from '@mantine/core'
import {DatePicker} from '@mantine/dates'
import {getTime, toDate} from 'date-fns'
import * as R from 'ramda'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from '../../../../../../hooks/storeHooks'
import {
  addOrderFieldError,
  resetOrderFieldError,
  setCreateOrderPopupValidationEnabled,
} from '../../../../../../store/procurement/create-order-popup/errors-reducer'
import {OrderStatusId, getOrderStatusPosition} from '../../../../../../store/procurement/create-order-popup/types'
import {
  selectCreateOrderPopupErrorsState,
  selectCreateOrderPopupField,
  selectCreateOrderPopupFieldError,
  selectCreateOrderPopupStaticDataItem,
} from '../../../../../../store/procurement/selectors'
import {defaultCompare} from '../../../../../../utils/compare'
import {verify} from '../../../../../../utils/Logger'
import {joinStrings} from '../../../../../../utils/string'
import {getCommonFieldClassName, mapToAutocompleteItem, mapToSelectItem} from '../common/common'
import {CreateOrderPopupFieldLabel} from '../common/label'
import {isUnderTrackingStatus, useCreateOrderPopupContext} from '../context'

export function OrderFieldsSectionFirstLine() {
  return (
    <div className='flex space-x-4'>
      <StoreSelect />
      <OrderDateSelect />
      <OrderStatusSelect />
      <ProviderSelect />
      <CountryAutocomplete />
      <CurrencySelect />
    </div>
  )
}

function StoreSelect() {
  const field = 'storeId'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selected = useSelector(selectCreateOrderPopupField(field))
  const stores = useSelector(selectCreateOrderPopupStaticDataItem('stores'))
  const options = useMemo(() => stores.map(mapToSelectItem), [stores])
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Магазин' />}
      placeholder='Магазин'
      value={selected}
      onChange={id => {
        dispatch(id ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, id)
      }}
      data={options}
      required={validationEnabled}
      className={joinStrings(getCommonFieldClassName(), 'w-[410px] shrink-0')}
      error={hasError}
    />
  )
}

function OrderDateSelect() {
  const field = 'orderTimestamp'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selectedOrderTimestamp = useSelector(selectCreateOrderPopupField(field))
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <DatePicker
      label={<CreateOrderPopupFieldLabel label='Дата заказа' />}
      value={selectedOrderTimestamp ? toDate(selectedOrderTimestamp) : undefined}
      onChange={date => {
        dispatch(date ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, date && getTime(date))
      }}
      inputFormat='DD.MM.YYYY'
      required={validationEnabled}
      className={joinStrings(getCommonFieldClassName(), 'w-[100px] shrink-0')}
      error={hasError}
      clearable={false}
      size='sm'
    />
  )
}

function OrderStatusSelect() {
  const field = 'orderStatusId'
  const dispatch = useAppDispatch()
  const selected = useSelector(selectCreateOrderPopupField(field))
  const statuses = useSelector(selectCreateOrderPopupStaticDataItem('orderStatuses'))
  const options = useMemo(() => R.sort((a, b) => defaultCompare(getOrderStatusPosition(a.id), getOrderStatusPosition(b.id)), statuses)
    .map(mapToSelectItem),
  [statuses])
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Статус заказа' />}
      value={selected}
      onChange={(id: OrderStatusId) => {
        if (id === null) {
          return
        }
        dispatch(setCreateOrderPopupValidationEnabled(
          isUnderTrackingStatus(id),
        ))
        updateOrderFiled(field, id)
      }}
      data={options}
      required
      className={joinStrings(getCommonFieldClassName(), 'w-[229px] shrink-0')}
    />
  )
}

function CountryAutocomplete() {
  const field = 'countryId'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selectedId = useSelector(selectCreateOrderPopupField(field))
  const countries = useSelector(selectCreateOrderPopupStaticDataItem('countries'))
  const options = useMemo(() => countries.map(mapToAutocompleteItem), [countries])
  const selectedValue = selectedId === null ? null : verify(options.find(x => x.id === selectedId)).value
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Autocomplete
      label={<CreateOrderPopupFieldLabel label='Страна' />}
      onItemSubmit={(item: typeof options[number]) => {
        dispatch(resetOrderFieldError(field))
        updateOrderFiled(field, item.id)
      }}
      onBlur={event => {
        if (event.currentTarget.value !== selectedValue) {
          dispatch(addOrderFieldError(field))
          updateOrderFiled(field, null)
        }
      }}
      data={options}
      required={validationEnabled}
      className={getCommonFieldClassName()}
      error={hasError}
    />
  )
}


function ProviderSelect() {
  const field = 'providerId'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selected = useSelector(selectCreateOrderPopupField(field))
  const providers = useSelector(selectCreateOrderPopupStaticDataItem('providers'))
  const options = useMemo(() => providers.map(mapToSelectItem), [providers])
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Провайдер' />}
      placeholder='Провайдер'
      value={selected}
      onChange={id => {
        dispatch(id ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, id)
      }}
      required={validationEnabled}
      data={options}
      className={getCommonFieldClassName()}
      error={hasError}
    />
  )
}

function CurrencySelect() {
  const field = 'currencyId'
  const dispatch = useAppDispatch()
  const hasError = useSelector(selectCreateOrderPopupFieldError(field))
  const selected = useSelector(selectCreateOrderPopupField(field))
  const currencies = useSelector(selectCreateOrderPopupStaticDataItem('currencies'))
  const options = useMemo(() => currencies.map(mapToSelectItem), [currencies])
  const validationEnabled = useSelector(selectCreateOrderPopupErrorsState('validationEnabled'))
  const {updateOrderFiled} = useCreateOrderPopupContext()

  return (
    <Select
      label={<CreateOrderPopupFieldLabel label='Валюта' />}
      value={selected}
      onChange={id => {
        dispatch(id ? resetOrderFieldError(field) : addOrderFieldError(field))
        updateOrderFiled(field, id)
      }}
      data={options}
      required={validationEnabled}
      className={joinStrings(getCommonFieldClassName(), 'w-[100px] shrink-0')}
      error={hasError}
    />
  )
}